<template>
    <notifications position="bottom left" style="bottom: 50px; left:20px;" />
    <Popup />
    <router-view ></router-view>
</template>
<script>
import { useCookies } from "vue3-cookies";
import {useRoute, useRouter} from "vue-router";
import { useUserStore } from "./store/user.store";
import Popup from "./components/modals/Popup.vue";

export default {
    name: "App",
    components: {
        Popup
    },
    setup() {
        const { cookies } = useCookies();
        const { me } = useUserStore();
        return { cookies, me };
    },
    data() {
        return {
            isLoggedIn: false,
            caricamento:false,
        }
    },
    created() {
        if (window.Laravel.isLoggedin) {
            this.isLoggedIn = true
        }
        this.$gtm.enable(true);
    },
    mounted() {
        this.$router.isReady().then(() => {
            this.checkAndStoreAnalyticsSource();
            this.checkAndPingSocket();
            this.checkAndRefreshCustomer();
        })
    },
    methods: {
        checkAndStoreAnalyticsSource() {
            const utm_source = this.$route.query.utm_source;
            const utm_campaign = this.$route.query?.utm_campaign || null;
            const utm_medium = this.$route.query?.utm_medium || null;
            const referred = document.referrer;

            if (utm_campaign) {
                this.$cookies.set('utm_campaign', utm_campaign, '4d');
            }

            if (utm_source) {
                this.$cookies.set('utm_source', utm_source, '4d');

                if (!localStorage.getItem('ref_origin')) {
                    localStorage.setItem('ref_origin', utm_source);
                }
            }

            if (utm_medium) {
                this.$cookies.set('utm_medium', utm_medium, '4d');
            }

            if (!utm_source && referred && referred.includes('medium.com')) {
                this.$cookies.set('utm_source', 'medium', '4d');

                if (!localStorage.getItem('ref_origin')) {
                    localStorage.setItem('ref_origin', 'medium');
                }
            }

            const ref_origin = this.$cookies.get('ref_origin');
            if (referred) {
                const url = new URL(referred);
                if (!url.hostname.includes('client.')) {
                    if (!ref_origin) {
                        this.$cookies.set('ref_origin', url.hostname, '4d');
                    }

                    if (!utm_source) {
                        this.$cookies.set('utm_source', url.hostname, '4d');
                    }
                }
            }
        },
        checkAndPingSocket() {
            if (this.isLoggedIn || this.$cookies.get('client')) {
                setTimeout(() => {
                    window.$socket.emit('user-logged-in', { 
                        user_id: this.cookies.get('client')._id,
                        device: navigator.userAgent
                    });
                }, 3000);
            }
        },
        async checkAndRefreshCustomer() {
            const email = this.cookies.get('emailAccount');
            if (email) {
                this.me(email)
                    .then(user => this.$cookies.set('client', JSON.stringify(user), '4d'))
                    .catch(function (error) {
                        console.error(error);
                    });
            }
        },
        logout(e) {
            e.preventDefault()
            this.$axios.get('/sanctum/csrf-cookie').then(response => {
                this.$axios.post('/api/logout')
                    .then(response => {
                        if(response.data.success) {
                            window.location.href = "/"
                        } else {
                            console.log(response);
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            })
        }
    },
}


</script>
<style>
.btn-primary:hover{
    border-color: #fb9678!important;
}
.btn-primary:focus{
    box-shadow:none!important;
    color: white;
}
.btn-success:hover{
    color: #ffffff!important;
}
.btn-primary:active{
    border-color: #fb9678!important;
    background-color: #fb9678!important;
}
.btn-outline-primary:active{
    border-color: #fb9678!important;
    background-color: #fb9678!important;
}
.btn-outline-primary:hover{
    border-color: #fb9678!important;
}
.btn-outline-primary:active:hover {
    color: black!important ;

}
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track:vertical {
    background: #303030;
}

/* Handle */
::-webkit-scrollbar-thumb:vertical {
    background: #888;
    border-radius: 3px;
    height:50px ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:vertical:hover {
    background: #ffffff;
}
/* Track */
::-webkit-scrollbar-track:horizontal {
    background: #303030;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
    background: #888;
    border-radius: 3px;
    height:5px ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:horizontal:hover {
    background: #ffffff;
}

.topbar{
    background-color: #3c4452!important;
    background-image: url('/assets/images/sfondo.jpg')!important;
    background-color: #03081c!important;
}
.card{
    background-color: rgba(255, 255, 255, 0.15);
    /* border: 1px solid rgb(28, 29, 42); */
    border: none;
    border-radius: 8px;
}
.left-sidebar{
    background-color: #303641!important;
    padding-top: 65px!important;
    background-image: url('/assets/images/sfondo.jpg')!important;
    background-size: cover!important;
    background-attachment: fixed!important;
    background-color: #03081c!important;
}
.flexdisplay{
    display: flex!important;
}
.scroll-sidebar{
    height: 100%!important;
    background-color: rgba(255, 255, 255, 0.1)!important;
}
.page-wrapper{
    padding-bottom:15px!important;
    background-image: url('/assets/images/sfondo.jpg')!important;
    background-size: cover!important;
    background-attachment: fixed!important;
    background-color: #03081c!important;
}
#wrapper{
    background-image: url('/assets/images/sfondo.jpg')!important;
    background-size: cover!important;
    background-attachment: fixed!important;
    height: 100vh;
    background-color: #03081c!important;
}
.mini-sidebar .scroll-sidebar{
    background-color: rgba(255, 255, 255, 0)!important;
}
body.show-sidebar .scroll-sidebar{
    background-image: url('/assets/images/sfondo.jpg')!important;
    background-size: cover!important;
    background-attachment: fixed!important;
    background-color: none

}
.veriff-description {
        color: white!important;
    }

</style>
