<template>
  <div class="rounded-circle container">
    <img class="bundle-img" src="/assets/images/bundle-box.svg" />
    <p>
      Special Promotion<br/>
      <span>Buy One, Get One Free!</span>
    </p>
    <div class="counter">
      <div class="counter-container">
        <div>
          <span>{{ daysDiff[0] }}</span>
          <span>{{ daysDiff[1] }}</span>
        </div>
        <p>Days</p>
      </div>
      <div class="counter-container">
        <div>
          <span>{{ hoursDiff[0] }}</span>
          <span>{{ hoursDiff[1] }}</span>
        </div>
        <p>Hours</p>
      </div>
      <div class="counter-container">
        <div>
          <span>{{ minDiff[0] }}</span>
          <span>{{ minDiff[1] }}</span>
        </div>
        <p>Minutes</p>
      </div>
    </div>
    <button class="btn gradient-border-btn" @click="goToBundles()">
      Discover now
    </button>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from "vue-router";

const router = useRouter();

const untilDate = new Date('2024-10-14 23:59:59');
const daysDiff = ref('00');
const minDiff = ref('00');
const hoursDiff = ref('00');

const getTimeDiff = () => {
  const currentDate = new Date();
  let delta = Math.abs(untilDate.getTime() - currentDate.getTime()) / 1000;

  const days = Math.floor(delta / 86400).toString();
  daysDiff.value = days.length === 1 ? `0${days}` : days;
  delta -= Number(days) * 86400;
  const hours = (Math.floor(delta / 3600) % 24).toString();
  hoursDiff.value = hours.length === 1 ? `0${hours}` : hours;
  delta -= Number(hours) * 3600;
  const min = (Math.floor(delta / 60) % 60).toString();
  minDiff.value = min.length === 1 ? `0${min}` : min;
}

const goToBundles = () => {
  router.push('/bundles');
};

onMounted(() => {
  getTimeDiff();
  setInterval(getTimeDiff, 60000);
});

</script>
<style scoped lang="scss">
  .container {
    font-family: Heebo;
    background: linear-gradient(180deg, rgba(30, 30, 30, 0.7) 0%, rgba(35, 31, 144, 0.7) 180.31%);
    width: 266px;
    height: 266px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    transform: translateX(-33%);

    .bundle-img {
      position: absolute;
      transform: translateY(-110%);
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.56px;
      text-align: center;

      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 23.5px;
        text-align: center;
      }
    }

    .counter {
      display: flex;
      gap: 16px;
      
      .counter-container {
        display: flex;
        flex-direction: column;

        div {
          display: flex;
          gap: 4px;
        }

        span {
          padding: 1px 8px 1px 8px;
          border-radius: 4px;
          background: rgba(217, 217, 217, 0.1);
          backdrop-filter: blur(30px);
        }

        p {
          font-size: 11px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.1em;
          text-align: center;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }

    .gradient-border-btn {
      padding: 10px 20px;
      font-size: 16px;
      background-color: rgba(255, 255, 255, 0);
      border: none;
      border-radius: 8px;
      position: relative;
      cursor: pointer;
    }

    .gradient-border-btn::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      padding: 2px;
      background: linear-gradient(142.67deg, #FFB7A7 8.58%, #FB8469 25.6%, #FB8469 35.86%, #7D5FFC 66.99%, #FB8469 98.31%);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
    }

    .gradient-border-btn:hover {
      opacity: 0.9;
    }
  }
</style>