<template>
    <div v-if="showCreditCardModal">
        <!-- Aggiungi l'overlay per l'effetto opaco -->
        <div class="modal-backdrop" @click="closeModal"></div>
        <div
            class="modal fade"
            :class="{ show: showCreditCardModal, 'd-block': showCreditCardModal }"
            id="modalCreditCard"
            tabindex="-1"
            :aria-hidden="!showCreditCardModal"
            :style="{ display: showCreditCardModal ? 'block' : 'none' }"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div :class="`modal-body text-center gradient-border ${isPaymentResultShown ? 'result' : ''}`">
                        <button
                            type="button"
                            class="btn-close"
                            aria-label="Close"
                            @click="closeModal"
                        ></button>
                        <Payment
                            :merchant-data="merchantData"
                            :form-params="formParams"
                            @success="(response) => onSuccess(response, updateIsPaymentResultShown)"
                            @error="(error) => onError(error, updateIsPaymentResultShown)"
                            @fail="(error) => onError(error, updateIsPaymentResultShown)"
                            width="100%"
                            :styles="styleParams"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Payment from '@solidgate/vue-sdk';
import { useUserStore } from '../store/user.store';
export default {
    name: "ModalCreditCard",
    data() {
        return {
            isPaymentResultShown: false
        }
    },
    props: {
        showCreditCardModal: {
            type: Boolean,
            default: false
        },
        merchantData: Object,
    },
    methods: {
        closeModal() {
            this.isPaymentResultShown = false;
            this.$emit('update:showCreditCardModal', false);
            this.$emit('update:loadingPayment', false);
        },
        updateIsPaymentResultShown() {
            this.isPaymentResultShown = true;
        }
    },
    components: {
        Payment
    },
    setup() {
        const styleParams = {
            'two-columns': {
                'margin-top': '8px'
            },
            input_group: {
                'color': 'white',
            },
            card_view: { 'color': '#3D4251', 'background-color': 'red'},
            formBody: { 'color': 'white'},
            form_title: {
                display: 'none',
                width: '100%',
                'justify-content': 'center',
                'font-weight': '500',
                'font-size': '28px',
                color: 'black',
            },
            base: {
                fontSize: '16px',
                fontFamily: 'Arial',
                color: 'red',
            },
            invalid: {
                color: 'green',
                borderColor: 'green',
            },
            submit_button: {
                'background-color': 'rgba(255, 104, 68, 1)',
                width: '304px',
                margin: '0 auto',
                'margin-top': '42px',
                '.title': {
                    'font-size': '14px'
                },
                '.title-icon': {
                    display: 'none'
                }
            },
            card_number: {
                input: {
                    'background-color': 'transparent',
                    'border-color': 'rgba(187, 197, 241, 0.33)',
                    'border-radius': '8px',
                    'border-width': '2px',
                    color: 'rgba(255, 255, 255, 0.33)',
                    'font-size': '12px',
                    padding: '12px 16px',
                    'padding-right': '3.3em !important',
                    'margin-top': '12px',
                    '::placeholder': {
                        color: 'rgba(255, 255, 255, 0.5)'
                    },
                    ':focus': {
                        color: 'white',
                        'border-color': 'white'
                    }
                },
                '.label': {
                    'font-size': '14px',
                    color: 'white',
                    ':focus': {
                        color: 'white'
                    }
                },
                '.brand-icon': {
                    top: '3.25em',
                    right: '1.1em',
                    left: 'unset',
                },
                '.input-block': {
                    input: {
                        'padding-left': '16px !important'
                    }
                },
                '.not-empty': {
                    input: {
                        color: 'white'
                    },
                    '.label': {
                        'font-size': '14px',
                        color: 'white',
                    }
                },
                '.error': {
                    '.label': {
                        color: '#e46161'
                    }
                }
            },
            card_holder: {
                input: {
                    'background-color': 'transparent',
                    'border-color': 'rgba(187, 197, 241, 0.33)',
                    'border-radius': '8px',
                    'border-width': '2px',
                    color: 'rgba(255, 255, 255, 0.33)',
                    'font-size': '12px',
                    padding: '12px 16px',
                    'padding-right': '3.3em !important',
                    'margin-top': '12px',
                    '::placeholder': {
                        color: 'rgba(255, 255, 255, 0.5)'
                    },
                    ':focus': {
                        color: 'white',
                        'border-color': 'white'
                    }
                },
                '.label': {
                    'font-size': '14px',
                    color: 'white',
                    ':focus': {
                        color: 'white'
                    }
                },
                '.brand-icon': {
                    top: '3.25em',
                    right: '1.1em',
                    left: 'unset',
                },
                '.input-block': {
                    input: {
                        'padding-left': '16px !important'
                    }
                },
                '.not-empty': {
                    input: {
                        color: 'white'
                    },
                    '.label': {
                        'font-size': '14px',
                        color: 'white',
                    }
                },
                '.error': {
                    '.label': {
                        color: '#e46161'
                    }
                }
            },
            expiry_date: {
                input: {
                    'background-color': 'transparent',
                    'border-color': 'rgba(187, 197, 241, 0.33)',
                    'border-radius': '8px',
                    'border-width': '2px',
                    color: 'rgba(255, 255, 255, 0.33)',
                    'font-size': '12px',
                    padding: '12px 16',
                    '::placeholder': {
                        color: 'rgba(255, 255, 255, 0.5)'
                    },
                    ':focus': {
                        color: 'white',
                        'border-color': 'white'
                    }
                },
                '.label': {
                    display: 'none'
                },
                '.not-empty input': {
                    color: 'white'
                },
            },
            card_cvv: {
                input: {
                    'background-color': 'transparent',
                    'border-color': 'rgba(187, 197, 241, 0.33)',
                    'border-width': '2px',
                    'border-radius': '8px',
                    color: 'rgba(255, 255, 255, 0.33)',
                    'font-size': '12px',
                    padding: '12px 16',
                    '::placeholder': {
                        color: 'rgba(255, 255, 255, 0.5)'
                    },
                    ':focus': {
                        color: 'white',
                        'border-color': 'white'
                    }
                },
                '.label': {
                    display: 'none'
                },
                '.tooltip-icon': {
                    top: '.4em'
                },
                '.tooltip': {
                    top: '2.5rem'
                },
                '.not-empty input': {
                    color: 'white'
                },
            }
        };
        const formParams = {
            cardExpiryDatePlaceholder: 'MM/YY',
            cardNumberLabel: 'Card Number',
            isCardHolderVisible: true,
        };

        const onSuccess = (response, updateCallback) => {
            console.log('Payment successful:', response);
            updateCallback();
            // reaload the page
              // reaload the page
            const userStore = useUserStore()
            window.location.href = `${this.$urlDashbaord}/successPayment?customer_id=${userStore?.user?._id || ''}`
        };

        const onError = (error, updateCallback) => {
            console.error('Payment error:', error);
            updateCallback();
        };

        return {
            formParams,
            styleParams, // Aggiungi questo per passare gli stili
            onSuccess,
            onError
        };
    }
}
</script>

<style scoped>
    .modal-content {
        background-color: #3D4251;
        color: white;
    }
    .modal-header {
        border-bottom: 1px solid #3D4251;
    }
    .modal-footer {
        border-top: 1px solid #3D4251;
    }
    .pay {
        background-color: #3D4251;
        color: white;
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1040;
    }
    .modal {
        z-index: 1050;
    }
    .modal-dialog {
        min-width: 580px;
    }
    .modal-body {
        border-radius: 20px;
        background: rgba(38, 37, 51, 1);
        padding: 32px;
        position: relative;
        border: none;
    }
    .modal-body .btn-close {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .gradient-border::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 20px;
        border: 0 solid;
        padding: 1px;
        background: linear-gradient(26.84deg, #a485ee32 33.05%,rgba(211, 210, 210, 0.5)  88.49%);
        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
    }
    .result {
        background: white;
    }
</style>

