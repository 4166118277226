<template>
    <BrandSection :loading="loading">
        <template v-slot:content>
            <div v-if="error" class="card text-center py-4 m-0 ">
                <h3 class="text-danger fw-bold">{{ textError }}</h3>
            </div>
            <form class="form-horizontal form-material" id="loginform" @submit.prevent="logIn()" v-if="!showVerifyEmail && !emailSent">
                <h2 class="text-start text-white mb-3">{{ $t('SingIn') }}</h2>
                <h5 class="subtitle mb-5">{{ $t('Welcome') }}</h5>
                <div class="col-xs-12 mb-5 position-relative">
                    <label class="text-white mb-1 input-label">{{ $t('Email') }}</label>
                    <input :class="[ showUserError ? 'border-danger' : 'border-white', 'form-control border border-1 px-3 py-2']" type="email" v-model="email" required placeholder="Email">
                    <p class="error-message mt-2 text-danger" v-if="showUserError">{{ NoFound[langParam||'en'] }}</p>
                    <p class="error-message mt-2 text-danger" v-if="bannedUser">{{ NoAuth[langParam||'en'] }}</p>
                </div>
                <div class="col-xs-12 mb-5 position-relative">
                    <label class="text-white mb-1 input-label">{{ $t('Password') }}</label>
                    <input :class="[ showPasswordError ? 'border-danger' : 'border-white', 'form-control border border-1 px-3 py-2']" :type="typePsw?'password':'text'" v-model="password" required placeholder="Password">
                    <i class="fa-eye far toggle-password" @click="changeTipePsw" ></i>
                    <p class="error-message mt-2 text-danger" v-if="showPasswordError">{{ wrongPsw[langParam||'en'] }}</p>
                </div>
                <div class="col-xs-12 mb-5">
                    <div class="d-flex no-block align-items-center justify-content-between">
                        <div class="d-flex align-items-center gap-2 displayFlex">
                            <input type="checkbox" name="" class="remember-credentials">
                            <label for="">{{ $t('Credentilas-rem') }}</label>
                        </div>
                        <div>
                            <a :href="'/reset/'+langParam" id="to-recover" class="text-primary text-decoration-underline forgot-password">{{ $t('Psw-forgot') }}</a>
                        </div>
                    </div>
                </div>
                <div class="text-center col-xs-12 mb-5">
                    <button class="btn btn-lg w-100 waves-effect waves-light text-white btn-primary">{{ $t('LogIn') }}</button>
                </div>
                <div class="m-b-0 col-sm-12 text-center text-white fw-normal">
                    {{ $t('NoClients') }} <router-link :to="'/register/'+langParam" class="text-primary text-decoration-underline m-l-5">{{ $t('SingUp') }}</router-link>
                </div>
            </form>
            <NeedVerifyEmail :resendEmail="resendEmail" :email="email" v-if="showVerifyEmail" />
            <EmailSent :email="email" :resendEmail="resendEmail" v-if="emailSent" />
        </template>
        <template v-slot:image-section>
            <div class="col-xs-12 image-text">
                <h3 class="text-center text-white mb-3">{{ $t('SingIn') }}</h3>
                <p class="subtitle">{{ $t('Welcome') }}</p>
            </div>
        </template>
    </BrandSection>
</template>

<script>
    import Spinner from "../components/Spinner"
    import ImageSection from "../components/layout/ImageSection.vue";
    import BrandSection from "../components/layout/BrandSection.vue";
    import NeedVerifyEmail from "../components/NeedVerifyEmail.vue";
    import EmailSent from "../components/EmailSent.vue";
    export default {
        components:{
            Spinner,
            ImageSection,
            BrandSection,
            NeedVerifyEmail,
            EmailSent
        },
        data() {
            return {
                email: "",
                password: "",
                error: false,
                typePsw:true,
                loading:true,
                langParam:'',
                showUserError: false,
                showPasswordError: false,
                showVerifyEmail: false,
                bannedUser:false,
                emailSent: false,
                verifyEmail:{
                    en:'You need to verify your email address',
                    it:"E' necessario verificare l'email",
                    pt:'Você precisa verificar seu endereço de e-mail',
                    es:'Necesitas verificar tu dirección de correo electrónico.'
                },
                checkEmail:{
                    en:'Check your email:',
                    it:"Controlla la tua email:",
                    pt:'Verifique seu e-mail:',
                    es:'Consultar su correo electrónico:'
                },
                wrongPsw:{
                    en:'Wrong password!',
                    it:"Password errata",
                    pt:'Senha incorreta',
                    es:'Contraseña incorrecta'
                },
                NoAuth:{
                    en:'User is not authorized to perform login',
                    it:"L'utente non è autorizzato a eseguire il login",
                    pt:'O usuário não está autorizado a realizar login',
                    es:'El usuario no está autorizado para realizar inicio de sesión'
                },
                NoFound:{
                    en:'User not found!',
                    it:"Utente non trovato",
                    pt:'Usuário não encontrado',
                    es:'Usuario no encontrado'
                }
            }
        },
        mounted(){
            this.checkCredentials();
            this.checkUrlLang();
        },
        methods: {
            resendEmail() {
                this.loading = true;
                axios.get(`${this.$baseUrl}/customers/resend-email?email=${this.email}`).then((response)=>{
                    if (response.status === 200) {
                        this.emailSent = true;
                        this.showVerifyEmail = false;
                    } else {
                        this.error = true;
                        this.textError = response.message;
                        this.emailSent = false;
                        this.showVerifyEmail = false;
                    }
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    this.error = true;
                    this.textError = error.message;
                    this.emailSent = false;
                    this.showVerifyEmail = false;
                });
            },
            checkUrlLang(){
               this.langParam = this.$route.params.lang;
                if (this.langParam) {
                    this.$i18n.locale=this.langParam
                } else {
                    this.langParam=''
                    this.$i18n.locale='en'
                }
            },
            checkCredentials(){
                if(this.$cookies.get('login')){
                    this.email=this.$cookies.get('emailAccount')
                    axios.get(`${this.$baseUrl}/customers/getCustomerInfo?email=${this.email}`).then(({data})=>{
                        this.loading = false;
                        if(data?.verified){
                            window.location.replace('/home')
                        }else{
                            this.showVerifyEmail=true
                        }
                    }).catch(error => this.loading = false)
                }else{
                    if( this.$cookies.get('emailAccount')){
                        this.email=this.$cookies.get('emailAccount')
                    }
                    this.loading = false;
                }

            },
            changeTipePsw($event){
                $event.target.classList.toggle('fa-eye');
                $event.target.classList.toggle('fa-eye-slash');
                this.typePsw=!this.typePsw
            },
            logIn(){
                this.showPasswordError = false;
                this.showUserError = false;
                axios.post(`${this.$baseUrl}/customers/login`,{
                    "email":this.email,
                    "password":this.password
                }).then((response) => {
                    console.log(response)
                    if(response.data.status==200){
                        try {
                            axios.get(`${this.$baseUrl}/customers/getCustomerInfo?email=${this.email}`).then(({data})=>{
                                if(data.verified){
                                    this.error=false
                                    this.$cookies.set('login', true, Infinity)
                                    this.$cookies.set('emailAccount',this.email,Infinity)
                                    window.Laravel.isLoggedin
                                    window.location.replace('/home')
                                }else{
                                    this.showVerifyEmail = true
                                    this.$notify({
                                        title: this.verifyEmail[this.langParam||'en'],
                                        text: this.checkEmail[this.langParam||'en']+ this.email,
                                        type: 'warn'
                                    })
                                }
                            })
                        } catch (error) {

                        }
                    }else{
                        this.error=true
                        if(response.data.status==401){
                            this.showPasswordError = true;
                        }else if(response.data.status==400){
                            this.showUserError = true;
                        }
                        else if (response.data.status == 403 && response.data.message == 'User banned'){
                            this.bannedUser = true
                        }
                        else if(response.data.status == 403 && response.data.message == 'Please verify your email before login.'){
                            this.showVerifyEmail = true
                        }
                    }
                })
            .catch((error) => {
                console.error(error);
            });
            }
        },
    }
</script>
<style scoped lang="scss">

.subtitle {
    color: rgba(255, 255, 255, 0.5);
}

.toggle-password {
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-size: 16px;
    cursor: pointer;
}

.image-text {
    margin-top: 77px;
}

.remember-credentials {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    color: #c24f35;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 4px;
    display: grid;
    place-content: center;

    &:checked {
        background-color: #fff;
    }

    &::before {
        content: "";
        width: 14px;
        height: 14px;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #c24f35;
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked::before {
        transform: scale(1);
    }
}

.error-message {
    font-size: 12px;
    font-weight: 500;
    position: absolute;
}
</style>
