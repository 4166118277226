<template>
     <div v-if="showRank" class="card rounded py-3 px-1 col-11 col-md-8 col-xl-4 position-absolute start-50 translate-middle" >
            <div class="col-12" v-if="ranking">
                <div class="p-3 d-flex justify-content-between align-items-center" style="display: flex!important;">
                <h5 class="text-white d-flex align-items-center gap-2" style="display: flex!important;"><i class="mdi mdi-comment-outline text-primary fw-bold comment"></i>{{ $t('rank-title') }}</h5>
                <div @click="closeRank()"  style='cursor: pointer;'><i class="mdi mdi-close"></i></div>
            </div>
            <div class="text-center">
                <h3 class="text-white fw-semibold text-center">{{ $t('rank-text1') }}</h3>
                <p>{{ $t('rank-text2') }}</p>
            </div>
            <div class="d-flex justify-content-evenly my-3" style="display: flex!important;">
                <div @click="chooseRank(1)" :class="rankN==1?'selectEmot':''">
                    <span style='font-size:50px;cursor: pointer;'>&#128532;</span>
                </div>
                <div @click="chooseRank(2)" :class="rankN==2?'selectEmot':''">
                    <span style='font-size:50px;cursor: pointer;'>&#128528;</span>
                </div>
                <div @click="chooseRank(3)" :class="rankN==3?'selectEmot':''">
                    <span style='font-size:50px;cursor: pointer;'>&#128525;</span>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <textarea v-model="comment" placeholder="Add Comment" class="m-3 mx-5 w-75 p-2" name="" id="" cols="7" rows="7"></textarea>
            </div>
            <div class="d-flex flex-column align-items-center gap-2 justify-content-center mt-4" style="display: flex!important;">
                <button class="btn btn-primary w-50 " :disabled="submit" @click="sendRank()">{{ $t('Submit') }}</button>
                <p class="text-danger mt-2 ">{{ commentMess }}</p>
            </div>
            </div>
            <div v-else class="d-flex justify-content-between px-5 py-3" style="display: flex!important;">
                <div>
                    <h3 class="text-success">{{ $t('rank-footer1') }}</h3>
                    <h6>{{ $t('rank-footer2') }}</h6>
                </div>
                <div @click="closeRank()"  style='cursor: pointer;'><i class="mdi mdi-close"></i></div>
            </div>
        </div>
</template>

<script>
import {mapState} from "pinia";
import { useUserStore } from "../store/user.store";

export default {
    props:{
        showRank:Boolean
    },
    computed: {
        ...mapState(useUserStore, ['user'])
    },
    watch: {
        user: {
            handler: function (user) {
                if (user) {
                    this.verifyRankClient()
                }
            },
            deep: true
        }
    },
    data(){
        return{
            rankN:0,
            comment:'',
            commentMess:'',
            ranking:true,
            submit:false
        }
    },
    methods:{
        closeRank(){
            this.$emit("close");
            this.rankN=0
            this.comment=''
            this.commentMess=''
        },
        chooseRank(value){
            this.rankN=value
            this.commentMess=''

        },
        sendRank(){
            this.submit=true
                let feedbackData={
                "customer_id": this.id,
                "message": this.comment,
                "impression": this.rankN
            }
            let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${this.$baseUrl}/feedbacks/storeFeedback`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data : feedbackData
                };
            this.commentMess=''
            if(this.rankN==1 || this.rankN==2){
                if(this.comment==''){
                    this.submit=false
                    this.commentMess='Please explain the reason for your rating to help us improve'
                }
                else{
                    this.commentMess=''
                    axios.request(config)
                    .then((response) => {
                    this.ranking=false
                    })
                }
            }else if(this.rankN==3){
                this.commentMess=''
                axios.request(config)
                .then((response) => {
                this.ranking=false
                })
            }else{
                this.submit=false
                this.commentMess='To send feedback, selection is required'
            }
        },
        verifyRankClient(){
            axios.post(`${this.$baseUrl}/feedbacks/getCustomerFeedback/${this.user._id}`).then((resp)=>{
                if(resp.data.length>0){
                    this.ranking=false
                }
            })
        }
    }
}
</script>

<style scoped>

    .card{
        opacity: 1!important;
        background-image: url('/assets/images/sfondo.jpg')!important;
        background-size: cover;
        z-index: 9999;
        top: 30%;
    }
    .comment{
        background-color: rgb(194 79 53 / 20%)!important;
        border-radius: 50px;
        padding: 10px!important;
    }
    .selectEmot{
        background-color: rgb(194 79 53 / 30%)!important;
        border-radius: 25%;
    }
</style>
