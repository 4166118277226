<template>
    <!--Fidelity-->
    <li v-if="reward && reward.total" class="nav-small-cap btnStyle">
        <a class="btn mx-2 text-center p-2" href="/buy-account"><font-awesome-icon class="me-2" icon="fa-solid fa-medal"/>{{ reward.total.toFixed(2) }}</a>
    </li>
    <!-- /Fidelity Point -->
</template>

<script>

export default {
    name: 'banner',
    methods: {
        setClient() {
            // console.debug('setClient @ bannerFidelity');

            this.client = this.$cookies.get('client');
            if (this.client) {
                this.id = this.client._id
                this.getReward();
            }
        },
        getReward() {
            axios.get(`${this.$baseUrl}/ecommerce/getCustomerReward?id=${this.id}`).then((response)=>{
                this.reward=response.data
            })
        },
        goShop() {
            this.$router.push({path: '/buy-account'})
        }
    },
    data() {
        return {
            client:'',
            id:'',
            reward:''
        }
    },
    beforeMount() {
        this.setClient();
    },
    expose: ['setClient']
}
</script>

<style scoped>
    .page-wrapper{
        min-height: 100px!important;
    }
    .btnStyle{
        border-radius: 10px;
        background-color: rgba(194,79,53,0.2);
    }
    a{
        color: #eb6e52;
    }
</style>
