<template>
    <footer v-if="promo"  :class="{
        'footer': true,
        'd-none': true,
        'd-xl-flex': true,
        'justify-content-between': true,
        'promo-padding-x': promo,
        'promo-padding-y': promo,
        'bg-off-30': promo,
        'scrolling-background': true}" @click="goBuy()">
        <div class="d-block align-items-center justify-content-center flex-grow-1">
            <div class="gradient-box-off-30" v-if="promo">
                <span>{{ $t('Footer1-promo') }}</span>
            </div>
        </div>
        <div class="d-none d-lg-flex justify-content-center align-items-center flex-grow-1">
            <img src="assets/images/OFP.png" alt="" style="width: 100px;" v-if="promo">
        </div>
        <div class="d-block align-items-center justify-content-center flex-grow-1">
            <div class="gradient-box-off-30 gradient-reverse" v-if="promo">
                <span class="text-uppercase">{{ $t('Footer2-promo') }}</span>
            </div>
        </div>
    </footer>

    <footer class="footer px-0 d-xl-none bg-off-30 scrolling-background" v-if="promo" @click="goBuy()">
        <marquee>
            <div class="d-flex flex-row align-items-center promo-padding-x promo-padding-y" style="width: 100% !important; display: flex !important;">
                <div class="d-flex align-items-center">
                    <div class="gradient-box-off-30">
                        <span>{{ $t('Footer1-promo') }}</span>
                    </div>
                </div>
                <div class="flex-column align-items-center">
                    <img src="assets/images/OFP.png" alt="" style="width: 100px;">
                </div>
                <div class="d-flex align-items-center">
                    <div class="gradient-box-off-30 gradient-reverse">
                        <span class="text-uppercase">{{ $t('Footer2-promo') }}</span>
                    </div>
                </div>
            </div>
        </marquee>
    </footer>

    <footer class="footer d-none d-md-flex justify-content-center scrolling-background" v-if=" !promo" :style="footerImageStyle">
        <div class="d-flex align-items-center">
            <img src="assets/images/OFP_white.png" alt="" style="width: 50px;" v-if="0">
        </div>
        <div class="w-75 d-none d-lg-flex flex-column align-items-center" @click="goShop()">
            <h4 class="text-center text-white m-0" v-if="0">{{ $t('Footer1-custom') }}</h4>
            <h4 class="text-center text-white m-0" v-if="0">{{ $t('Footer2-custom') }}</h4>
        </div>
        <div class="d-flex align-items-center">
            <img src="assets/images/visa_logo_white.png" alt="" style="width: 50px;" v-if="0">
        </div>
    </footer>
    <footer class="footer px-0 d-flex flex-column align-items-center d-md-none scrolling-background" v-if=" !promo" :style="footerImageStyle">
        <div class="d-block d-lg-none w-75 mx-auto text-center" @click="goShop()" v-if="0">
            <h6 class="text-white m-0">{{ $t('Footer1-custom') }}</h6>
            <h6 class="text-white m-0">{{ $t('Footer2-custom') }}</h6>
        </div>
        <div class="w-50 mx-auto displayFlex justify-content-between">
            <div class="displayFlex align-items-center">
                <img src="assets/images/OFP_white.png" alt="" style="width: 50px;" v-if="0">
            </div>
            <div class="displayFlex align-items-center">
                <img src="assets/images/visa_logo_white.png" alt="" style="width: 50px;" v-if="0">
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "footer",
    data() {
        return {

            promo: false,
        };
    },
    computed: {
        footerImageStyle() {
            return {
                backgroundImage: `url(${this.$t('footer-image-custom')})`
            };
        }
    },
    methods: {
        goBuy() {
            this.$router.push({ path: '/buy-account' });
        },
        checkPromo() {
            const now = new Date();
            const year = new Date().getUTCFullYear();

            const startPromo = new Date(Date.UTC(year, 8, 12, 13, 30));
            const endPromo = new Date(Date.UTC(year, 8, 15, 21, 55));
            if (now >= startPromo && now <= endPromo) {
                this.promo = true;
            }
        },
        goShop() {
            this.$router.push({ path: '/buy-account' });
        }
    },
    mounted() {
        this.checkPromo();
    }
};
</script>

<style scoped>
.footer {
    position: fixed;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: contain;
    background-repeat: repeat;
}

.scrolling-background {
    max-height: 8.5%;
    height: 7%;
    background-position: center;
}

@keyframes scrollBackground {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1000px 0;
    }
}

#footer-image-custom {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
}
div {
    cursor: pointer;
}
h4 {
    font-size: 1.35rem!important;
    font-weight: 600;
    font-family: "Roboto", Sans-serif;
    text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3);
}
h6 {
    font-family: "Roboto", Sans-serif;
    text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3);
}
.displayFlex {
    display: flex!important;
}

.gradient-box-off-30, .gradient-box-off-30.gradient-reverse {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 24px;
    background: linear-gradient(90deg, #FF6844 4.06%, #241D80 97.81%);
    border-radius: 8px;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 1;
    z-index: 3;
    flex-grow: 1;
    z-index: 2;
}
.gradient-box-off-30 span {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    color: #FFFFFF;
}
.promo-padding-x {
    padding-left: 32px;
    padding-right: 32px;
}
.bg-off-30 {
    background-color: #4c313a;
}
.d-none.d-lg-flex {
    display: flex!important;
}
@media (min-width: 992px) {
    .d-none.d-lg-flex {
        display: flex;
    }
}
@media (max-width: 991px) {
    .footer {
        padding-top: 10px;
        padding-bottom: 5px;
    }
    .gradient-box-off-30, .gradient-box-off-30.gradient-reverse {
        padding: 18px;
    }
    .flash-sale {
        width: 150px;
    }
}
@media (max-width: 767px) {
    .footer {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .gradient-box-off-30, .gradient-box-off-30.gradient-reverse {
        padding: 12px;
    }
    .flash-sale {
        width: 100px;
    }
}
</style>
