<template>
    <div class="col-12 container">
        
            <div>{{ $t('Currency') }}</div>
            <ul class="nav nav-pills justify-content-start gap-4 pt-2 pb-4">
                <li class="nav-item" v-for="(item, index) of currencies" :key="index">
                    <a class="nav-link text-uppercase" :class="{ active: modelValue.currency === item }"
                        aria-current="page" :id="`nav-${item}-tab`" data-bs-toggle="tab" type="button" role="tab"
                        aria-selected="true" href="#" @click="onUpdateCurrency(item)">
                        {{ formatCurrency(item) }}
                    </a>
                </li>
            </ul>
       
       
            <div>{{ $t('buyaccount-type-of-payout') }}</div>
            <ul class="nav nav-pills justify-content-start gap-4 pt-2">
                <li class="nav-item" v-for="(item, index) of payout_frequencies" :key="index">
                    <a class="nav-link" :class="{ active: modelValue.payout_frequency === item }"
                        aria-current="page" :id="`nav-${item}-tab`" data-bs-toggle="tab" type="button" role="tab"
                        aria-selected="true" href="#" @click="onUpdatePayoutFrequency(item)">
                        {{ formatPayoutFrequency(item) }}
                    </a>
                </li>
            </ul>
            <BundleMobile v-if="bundlePromoEnabled" />
        
            <div class="pt-4">{{ $t('buyaccount-account-size') }}</div>
            <div class="slider-wrapper">
                <Slider v-model="capital" :data="capitals.map(e => `${e / 1000}K`)" adsorb contained marks
                    tooltip="hover" />
            </div>
       
            <div  class="pt-4">{{ $t('max_daily_drawdown') }}</div>
            <div class="slider-wrapper">
                <Slider v-model="drawdown" :data="drawdowns.map(e => `${e}%`)" adsorb contained marks
                        tooltip="hover" />
            </div>

            <div  class="pt-4">{{ $t('profit_split') }}</div>
            <div class="slider-wrapper">
                <Slider v-model="profit_split" :data="profit_splits.map(e => `${e}%`)" adsorb contained marks
                    tooltip="hover" />
            </div>
       

    </div>
</template>

<script setup>
import { defineEmits, defineProps, unref, computed } from "vue";
import Slider from "./Slider.vue";
import {
    formatCurrency,
    formatPayoutFrequency,
    formatPlatform,
    platformImage,
} from "./utils/format";
import BundleMobile from "./BundleMobile.vue";
const props = defineProps({
    modelValue: {
        required: true,
        type: Object,
    },
    capitals: Array,
    drawdowns: Array,
    profit_splits: Array,
    payout_frequencies: Array,
    currencies: Array,
});

const profit_split = computed({
    get: () => `${props.modelValue.profit_split}%`,
    set: (val) => emit('update:modelValue', { ...props.modelValue, ...{ profit_split: parseInt(val.replace('%', '')) } })
})

const capital = computed({
    get: () => `${props.modelValue.capital / 1000}K`,
    set: (val) => emit('update:modelValue', { ...props.modelValue, ...{ capital: parseInt(val.replace('K', '') * 1000) } })
})

const drawdown = computed({
    get: () => `${props.modelValue.drawdown}%`,
    set: (val) => emit('update:modelValue', { ...props.modelValue, ...{ drawdown: parseInt(val.replace('%', '')) } })
})

const bundlePromoEnabled = computed(() => {
    const untilDate = new Date('2024-10-14 23:59:59');
    return new Date() <= untilDate;
})

const emit = defineEmits(["update:modelValue"]);

const onUpdateCapital = (val) => {
    emit("update:modelValue", {
        ...unref(props.modelValue),
        ...{ capital: val },
    });
};

const onUpdateDrawdown = (val) => {

    emit("update:modelValue", {
        ...unref(props.modelValue),
        ...{ drawdown: val },
    });
};

const onUpdatePayoutFrequency = (val) => {
    emit("update:modelValue", {
        ...unref(props.modelValue),
        ...{ payout_frequency: val },
    });
};

const onUpdateCurrency = (val) => {
    emit("update:modelValue", {
        ...unref(props.modelValue),
        ...{ currency: val },
    });
};

</script>

<style scoped>
.container {
    padding: 0;
    margin-bottom: 60px;
    margin-top: -50px;
}
.josefin-sans {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.box {
    margin-top: -40px;
/*    min-width: 360px;*/
    max-width: 100%;
    margin-bottom: 40px;
    width: 100%;
    color: white;
    
    font-size: 14px;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    text-align: left;
}

.selector {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.slider-wrapper {
    padding-top: 32px;
    padding-left: 4px;
    padding-right: 4px;
}

.nav-item {
    .nav-link {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 14px;
        padding-right: 14px;
        min-width: 60px;
        font-family: "Josefin Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        background: rgba(255, 255, 255, 0.05);
        color: white;
        border-radius: 6px;

        &.active {
            background: rgba(255, 104, 68, 1);
        }
    }
}

</style>
