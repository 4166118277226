<template>
   <div class="row no-gutters">
    <Spinner v-if="loading" />
    <section class="reset-container col-xs-12 col-lg-6" style="background-image: url(/assets/images/sfondo.jpg);">
      <img src="/assets/images/logoScritte.png" />
      <div v-if="!send" class="form-container col-xs-12">
        <slot name="content"></slot>
      </div>
    </section>
    <ImageSection>
      <slot name="image-section"></slot>
    </ImageSection>
  </div>
</template>
<script>
  import Spinner from "../../components/Spinner";
  import ImageSection from "./ImageSection.vue";

  export default {
    components:{
      Spinner,
      ImageSection,
    },
    props: {
      send: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style lang="scss">
  .reset-container {
    background-size: cover;
    background-attachment: fixed;
    padding: 16px;
    height: 100vh;

    h2 {
      font-size: 32px;
      font-weight: 500;
    }

    @media (min-width: 992px) {
      & {
          padding: 80px;
      }
    }
  }

  .form-container {
    display: flex;
    align-items: center;
    height: calc(100% - 64px);

    *:not(.far) {
      font-family: Heebo;
    }

    form {
      width: 100%;

      .input-label {
        font-size: 12px;
        font-weight: 500;
      }

      input.form-control {
        background-color: transparent;
        border-radius: 8px;
      }

      .btn {
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        padding: 12px;
      }
    }

    a {
      font-weight: 500;
    }

    .form-control::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
</style>