<template>
    <div class="platform-list">
        <div v-for="(item, index) in platforms" :key="index"
            class="form-check d-inline-block d-md-flex flex-row align-items-center">
            <div class="platform" @click="emit('update:modelValue',item)">
                <div class="platform-content"
                    :class="isSelected(item) ? ['border', 'border-2', 'border-danger', 'platform-selected'] : []">
                    <img class="platform-image" :src="platformImage(item)" :alt="formatPlatform(item)">
                </div>
                <div class="platform-label">
                    {{ formatPlatform(item) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, defineProps, unref } from "vue";
import {  formatPlatform, platformImage } from "./utils/format";
const props = defineProps({
    modelValue: {
        required: true,
        type: String,
    },
    platforms: Array,
})

const isSelected = (item) => props.modelValue && item ? props.modelValue.toLowerCase() === item.toLowerCase() : false
const emit = defineEmits([
    'update:modelValue',
])

</script>

<style scoped lang="scss">
.josefin-sans {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.platform-list {
    margin-left: -20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.platform {
    width: 163px;
    height: 140px;
    @media (max-width: 1023px) {
        width: 120px;
        height: 140px;
    };
}

.platform-content {
    border: 1px solid #686F7C;
    border-radius: 8px;
    display: flex;
    @media (max-width: 1023px) {
        width: 120px;
        height: 100px;
    };
}

.platform-image {
    width: 64px !important;
    height: 64px !important;
    margin: auto;
}

.platform-label {
    padding-top: 10px;
    text-align: center;
    font-size: 16px;
}

.platform-selected {
    background: rgba(255, 104, 68, 0.12);
}
</style>
