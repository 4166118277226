import {defineStore} from "pinia";
import {useUserStore} from "./user.store";

export const useCartStore = defineStore('cart', {
    state: () => ({
        cart: null
    }),
    getters: {
        getCart: state => {
            return state.cart;
        },
    },
    actions: {
        async addItemToCart(versionId, platform, tag, type, payout_type, max_daily_drawdown) {
            const { user } = useUserStore();

            const body = {
                customer_id: user._id,
                item: versionId,
                platform,
                tag,
                type,
                payout_type
            }
            if ( max_daily_drawdown) {
                body.max_daily_drawdown = max_daily_drawdown
            }
            const { data } = await this.$httpClient.post(`/ecommerce/cart/addItem`, body);

            return data;
        },
        async fetchCart() {
            const { user } = useUserStore();

            const { data } = await this.$httpClient.get(`/ecommerce/cart/getCart?customer_id=${user._id}`);
            this.cart = data;
            return data;
        },
        async deleteCart() {
            const { user } = useUserStore();

            await this.$httpClient.post(`/ecommerce/cleanCart`, {customer_id: user._id});
            this.cart = null;
        },
        async checkCoupon(code) {
            const { user } = useUserStore();

            const { data } = await this.$httpClient.get(`/ecommerce/check-coupon?customer_id=${user._id}&code=${code}`);
            return data.data;
        },
        async addCoupon(code) {
            const { user } = useUserStore();
            await this.$httpClient.post(`/ecommerce/cart/addCouponToCart`, {customer_id: user._id, coupon: code});
        },
        async setPlatformForCart( customer_id, platform) {
            await this.$httpClient.post(`/ecommerce/cart/setPlatformForCart`, {customer_id, platform});
        },
        async confirmOrder(order) {
            console.debug('Order:', order);
            return await this.$httpClient.post(`/ecommerce/cart/confirmOFPOrder`, order);
        },
        payOrder(payload) {
            return this.$httpClient.post(`/ecommerce/order/pay`, payload);
        },
        clean() {
            const { user } = useUserStore();

            const payload = {
                customer_id: user._id
            };
            return this.$httpClient.post(`/ecommerce/cleanCart`, payload);
        }
    }
})
