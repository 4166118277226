<template>
    <BrandSection :send="send">
        <template v-slot:content>
            <div v-if="error" class="card text-center py-4 m-0 ">
                <h3 class="text-danger fw-bold">{{ textError }}</h3>
            </div>
            <form class="form-horizontal form-material" id="loginform" @submit.prevent="sendEmail()">
                <h2 class="text-start text-white mb-5">{{ $t('ResetPsw') }}</h2>
                <div class="col-xs-12 mb-5">
                    <input class="form-control border border-1 border-white px-3 py-2" v-model=email type="email" required placeholder="Enter your email">
                </div>
                <div class="text-center col-xs-12 mb-5">
                    <button class="btn btn-lg w-100 waves-effect waves-light text-white btn-primary">{{ $t('Reset') }}</button>
                </div>
                <div class="m-b-0 col-sm-12 text-center text-white back-text">
                    {{ $t('BackTo') }} <a  :href="'/'+ langParam" class="text-primary fw-semibold text-decoration-underline m-l-5" >{{ $t('LogIn') }}</a>
                </div>
            </form>
        </template>
    </BrandSection>
</template>

<script>
    import ImageSection from "../components/layout/ImageSection.vue";
    import BrandSection from "../components/layout/BrandSection.vue";

    export default {
        name: 'LostPsw',
        components:{
            ImageSection,
            BrandSection
        },
        data(){
            return{
                email:'',
                send:false,
                error:false,
                textError:'',
                langParam:''
            }
        },
        methods:{
            sendEmail(){
                if(this.email!=''){
                    axios.post(`${this.$baseUrl}/customers/reset-password`, {
                        "customer_email": this.email
                    })
                    .then((response) => {
                        this.send = response.data.status === 200;
                        this.error = !this.send;
                        this.textError= this.error ? this.$t('ResetFailed') : null;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                }
            },
            checkUrlLang(){
                this.langParam = this.$route.params.lang;
                if (this.langParam) {
                    this.$i18n.locale=this.langParam
                } else {
                    this.langParam=''
                    this.$i18n.locale='en'
                }
            },
        },
        mounted(){
            this.checkUrlLang()
        }
    }
</script>

<style scoped lang="scss">
    .reset-container {
        background-size: cover;
        background-attachment: fixed;
        padding: 16px;
        height: 100vh;

        h2 {
            font-family: Heebo;
            font-weight: 500;
        }

        @media (min-width: 992px) {
            & {
                padding: 80px;
            }
        }
    }

    .form-container {
        display: flex;
        align-items: center;
        height: calc(100% - 64px);

        form {
            width: 100%;

            input.form-control {
                font-family: Heebo;
                background-color: transparent;
                border-radius: 8px;
            }

            .btn {
                font-family: Heebo;
                font-weight: 500;
                font-size: 14px;
                padding: 12px;
            }

            .back-text {
                font-family: Heebo;
                font-weight: 400;
            }
        }
    }

    .image-container {
        background-color: #03081c;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form-control::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }
</style>
