<template>
    <dialog ref="dialogRef">
        <div class="popup">
            <div class="popup-container">
                <div class="popup-title">
                    <div class="popup-title__icon">
                        <i class="fa text-ofp" :class="{[icon || 'fa-info-circle']: true}"></i>
                    </div>
                    <h4 v-html="title || 'OFP Dashboard'"></h4>
                    <i class="popup-title__close-icon fas text-white fa-2x" :class="{'fa-times': !(settings || { disableClose: false }).disableClose}" @click="close"></i>
                </div>
                <div class="popup-content text-center">
                    <img v-if="image" :src="'/assets/images/' + image">
                    <h3 class="popup-heading" v-html="heading"></h3>
                    <p name="body" class="popup-body" v-html="body" v-if="body"></p>
                    <Component :is="component" v-if="component" v-bind="componentProps" />
                </div>
                <button
                    v-if="!(settings || { disableCloseButton: false }).disableCloseButton"
                    class="btn popup-button"
                    @click="_buttonClick();"
                    :disabled="loadingButton"
                >
                    {{ buttonText || 'Close' }}
                    <i class="fa fa-spinner fa-spin mx-2" v-if="loadingButton"></i>
                </button>
            </div>
        </div>
    </dialog>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import { storeToRefs } from "pinia";

import { usePopup } from '../../store/popup.store';
const popup = usePopup();
const { settings, icon, title, image, heading, body, buttonText, buttonClick, component, componentProps, onClose } = storeToRefs(popup);

const emit = defineEmits(['close', 'button-click']);

const dialogRef = ref(null);
const open = () => dialogRef.value.showModal();
const loadingButton = ref(false);
const close = () => {
    dialogRef.value.close();
    onClose.value();
    emit('close');
}

const _buttonClick = async () => {
    loadingButton.value = true;
    try {
        await buttonClick.value();
        close()
    } finally {
        loadingButton.value = false;
    }
}

onMounted(() => {
    popup.$onAction(({ name, store, args, after, onError }) => {
        if (name === 'popup') {
            open();
        }
    });
});

defineExpose({ open, close });
</script>

<style scoped lang="scss">
dialog {
    background: none;
    border: none;
    overflow-y: hidden;

    &::backdrop {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
}
.popup {
    background: rgba(34, 40, 51, 1);
    border: 1px solid rgba(54, 60, 71, 1);
    border-radius: 12px;

    padding: 40px;

    display: flex;
    flex-direction: row;
    gap: 32px;
}
.popup-container {
    min-width: 466px;
    max-width: 800px;

    @media (max-width: 768px) {
        min-width: 100%;
        max-width: 100%;
    }

    display: flex;
    flex-direction: column;
    gap: 32px;
}
.popup-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h4 {
        font-family: Heebo;
        font-size: 16px;
        font-weight: 500;
        line-height: 23.5px;
        text-align: left;
        color: white;
        margin-left: 16px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .popup-title__icon {
        width: 34px;
        height: 34px;
        padding: 8px;
        border-radius: 32px;
        background: rgba(255, 104, 68, 0.12);

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .popup-title__close-icon {
        cursor: pointer;
        margin-left: auto;
    }
}
.popup-heading {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;

    color: #FFFFFF;
}

.popup-body {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;

    color: rgba(255, 255, 255, 0.5);
}

.popup-content {
    overflow-y: auto;
    max-height: calc(100vh / 2);
}

.popup-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    width: 100%;

    background: #FF6844;
    border-radius: 8px;

    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: #FFFFFF;
}
</style>
