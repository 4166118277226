<template>
  <div class="rounded-circle container_2" @click="goToBundles">
    <img class="bundle-img" src="/assets/images/bundle-box.svg" />
    <p>
      Special Promotion<br/>
      <span>Buy One, Get One Free!</span>
    </p>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const goToBundles = () => {
  router.push('/bundles');
};

</script>
<style scoped lang="scss">
  .container_2 {
    font-family: Heebo;
    width: 150px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    transform: translateX(155%) translateY(-100%);
    z-index: 19;

    .bundle-img {
      position: absolute;
      transform: translateY(-80%);
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.56px;
      text-align: center;
      width: 100%;

      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 23.5px;
        text-align: center;
      }
    }

    .counter {
      display: flex;
      gap: 16px;
      
      .counter-container {
        display: flex;
        flex-direction: column;

        div {
          display: flex;
          gap: 4px;
        }

        span {
          padding: 1px 8px 1px 8px;
          border-radius: 4px;
          background: rgba(217, 217, 217, 0.1);
          backdrop-filter: blur(30px);
        }

        p {
          font-size: 11px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.1em;
          text-align: center;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }

    .gradient-border-btn {
      padding: 10px 20px;
      font-size: 16px;
      background-color: rgba(255, 255, 255, 0);
      border: none;
      border-radius: 8px;
      position: relative;
      cursor: pointer;
    }

    .gradient-border-btn::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      padding: 2px;
      background: linear-gradient(142.67deg, #FFB7A7 8.58%, #FB8469 25.6%, #FB8469 35.86%, #7D5FFC 66.99%, #FB8469 98.31%);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
    }

    .gradient-border-btn:hover {
      opacity: 0.9;
    }
  }
</style>