<template>
    <div class="col-12" v-for="(item, index) in items" :key="index">
        <table :class="tableClass(index)">
            <thead>
                <tr>
                    <th colspan="2" class="clickable" @click="emit('update:modelValue', index)">

                        <div class="text-center title">{{ title(index) }}</div>

                        <button class="btn-buy mt-4" @click="buyAccount(item)" :disabled="processing || !item">
                            <span v-if="!processing">
                                <span class="left">
                                    {{ $t('buyaccount-buy_account') }}
                                </span>
                                <span class="right price">
                                    ${{ item.version.price.toFixed(2) }}

                                </span>

                            </span>
                            <i class="fa fa-spin fa-spinner" v-else></i>
                        </button>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td nowrap>{{ $t('buyaccount-account-size') }}</td>
                    <td>{{ $formatAmountRounded(item.version.size, item.version.currency_account) }} </td>

                </tr>
                <tr>
                    <td colspan="2" class="divider">
                        <div class="divider"/>
                    </td>
                </tr>

                <tr>
                    <td nowrap>{{ $t('leverage') }}</td>
                    <td>{{ formatLeverage(item.props.leverage) }}</td>
                </tr>
                <tr>
                    <td colspan="2" class="divider">
                        <div class="divider"/>
                    </td>
                </tr>
                <tr>
                    <td nowrap> {{ $t('max_daily_drawdown') }} </td>
                    <td>{{ formatPercents(item.props.max_daily_drawdown) }}</td>
                </tr>
                <tr>
                    <td colspan="2" class="divider">
                        <div class="divider"/>
                    </td>
                </tr>
                <tr>
                    <td nowrap>{{ $t('max_drawdown') }}</td>
                    <td>{{ formatPercents(item.props.max_drawdown) }}</td>
                </tr>
                <tr>
                    <td colspan="2" class="divider">
                        <div class="divider"/>
                    </td>
                </tr>
                <tr>
                    <td nowrap>{{ $t('max_trader_value_score') }}</td>
                    <td>{{ formatPercents(item.props.max_trader_value_score) || '-' }}</td>
                </tr>
                <tr>
                    <td colspan="2" class="divider">
                        <div class="divider"/>
                    </td>
                </tr>
                <tr>
                    <td nowrap>{{ $t('Payouts') }}</td>
                    <td>{{ formatPayoutFrequency(item.payout_type) }}</td>
                </tr>
                <tr>
                    <td colspan="2" class="divider">
                        <div class="divider"/>
                    </td>
                </tr>
                <tr>
                    <td nowrap>{{ $t('profit_split') }}</td>
                    <td>{{ formatPercents(item.props.profit_split) }}</td>
                </tr>
                <tr>
                    <td>
                        {{ $t('payout-calculator') }}
                    </td>
                    <td @click="openPayoutCalculatorModal(item)">
                        <i class="fa fa-info-circle"></i>
                    </td>
                </tr>

            </tbody>

        </table>
    </div>
    <PayoutCalculator
        :showModal="showModal"
        @update:showModal="showModal = $event"
        :selectedItem="selectedItem"
    />
</template>
<script setup>
import { ref, unref, toRaw, computed } from 'vue';

import PayoutCalculator from './PayoutCalculator.vue';

import { formatPayoutFrequency, formatLeverage, formatPercents } from "./utils/format";
import BundleMobile from './BundleMobile.vue';

const showModal = ref(false);
const selectedItem = ref(null);

const props = defineProps({
    modelValue: {
        required: true,
        type: Number,
    },
    items: {
        required: true,
        type: Array
    },
    processing: false
})

const emit = defineEmits([
    'update:modelValue',
    'buy-account',
])

const title = (index) => {
    return index === 0 ? 'Custom Plan' : `Custom Plan #${index + 1}`
}

const buyAccount = (item) => {
    emit('buy-account', toRaw(item));
}

const tableClass = (index) => {
    if ( props.items.length === 1) return [];
    return props.modelValue === index ? ['selected'] : []
}

const openPayoutCalculatorModal = (item) => {
    showModal.value = true;
    selectedItem.value = item;
}
</script>
<style scoped lang="scss">
.box {
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 40px;
    padding: 0px;
    min-width: 325px;
}

.title {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
}

table th,
td {
    padding: 16px 0;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    width: 300px;
    color: white;

    @media (min-width: 768.1px) {
        padding: 16px;
    }
}

table thead > tr:first-child {

    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 92px, transparent 92px,transparent 100%  );

    height: 134px;
}
table.selected {
    background: rgba(74, 31, 30, 1);
}

td.divider {
    height: 1px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0px;
    padding-bottom: 0px;
}

table th.clickable {
    cursor: pointer;
}

div.divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

table tbody tr>td:first-child {
    text-align: left;
}
table tbody tr>td:first-child+td {
    text-align: right;
}

/*
table tr {
    border-bottom: 1px solid #FF6844;
}
table tbody tr > td:first-child {
    border-right: 1px solid #FF6844;
}
table tbody > tr:last-child { border: none; }
table th {
    font-weight: 600;
    font-size: 24px;
}
*/
.btn-buy {
    border-radius: 10px;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: rgb(255, 104, 68, 1);
    padding: 8px 16px 8px 16px;
    margin: auto;
    width: 100%;
    min-width: 150px;
    max-width: 350px;
    border: none;
    vertical-align: sub;
}

.btn-buy .price {
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
}

.btn-buy span .left {
    float: left;
}

.btn-buy span .right {
    padding-top: 2px;
    float: right;
}

.btn-buy:disabled {
    color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.05);
}
</style>
