<script setup>
import {getCurrentInstance, ref} from "vue";
import {useCookies} from "vue3-cookies";
import axios from "axios";
import moment from "moment";
import {useUserStore} from "../../store/user.store";
import {storeToRefs} from "pinia";

// Misc
const props = defineProps({
    accountInfo: Object
});
const app = getCurrentInstance().appContext.app;
const baseUrl = getCurrentInstance().appContext.config.globalProperties.$baseUrl;

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

// Stores
const { cookies } = useCookies();

// Methods: Date
const formatData = (value) => {
    let date = new Date(value);
    let local = app.config.globalProperties.$languages[user.value.language] || app.config.globalProperties.$languages['en'];
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var formatter = new Intl.DateTimeFormat(local, options);
    return formatter.format(date);
};

const formatDay = (value) => {
    let date = new Date(value);
    let local = app.config.globalProperties.$languages[user.value.language] || app.config.globalProperties.$languages['en'];
    return date.toLocaleDateString(local, { weekday: 'long' });
};

// Methods: Payouts
const requestPayout = ref(null);
const requestError = ref(false);
const requestPay = ref(true)
const checkPayoutRequest = async () => {
    try {
        const {data} = await axios.get(`${baseUrl}/request-payouts/${props.accountInfo._id}`);
        requestPayout.value=data[0]
        requestPayout.value.created_at = moment(requestPayout.value.created_at).format('YYYY-MM-DD HH:mm:ss');
        requestPay.value=true
    } catch (error) {
        console.error('Error getting payout request', error);
    }
}
const askPayout = async () => {
    try {
        if(props.accountInfo.consistencyScore.scoreValue < 20 && props.accountInfo.consistencyScore.scoreValue>0){
            requestPay.value= false
            const { data } = await axios.post(`${baseUrl}/request-payouts`,{
                "account_id": props.accountInfo._id
            });
            await checkPayoutRequest()
        }
    } catch (error) {
        console.log(error)
        requestError.value=true
    }
};
</script>

<template>
    <!-- Skeleton: Loading -->
    <div class="skeleton d-flex no-block align-items-center text-white fw-semibold d-flex" v-if="!props.accountInfo">
        <span>
            <h2></h2>
            <h4></h4>
        </span>
        <div class="ms-auto text-end">
            <h2></h2>
            <medium></medium>
        </div>
    </div>

    <template v-if="props.accountInfo">
        <!-- Monthly or Biweekly -->
        <div v-if="props.accountInfo.type_account.includes('Monthly') || props.accountInfo.type_account.includes('Biweekly')" class="d-flex no-block align-items-center text-white fw-semibold d-flex">
            <span v-if="props.accountInfo.datePayout">
                <h2 class="text-capitalize">{{formatDay(props.accountInfo.datePayout.slice(props.accountInfo.datePayout.indexOf(' ') + 1)) }}</h2>
                <h4>{{ formatData(props.accountInfo.datePayout.slice(props.accountInfo.datePayout.indexOf(' ') + 1) )}}</h4>
            </span>
            <div class="ms-auto text-end">
                <h2>{{ props.accountInfo.nextPayout }} {{ $t('Days') }}</h2>
                <medium>{{ $t('toPayout') }}</medium>
            </div>
        </div>

        <!-- On Demand -->
        <div v-if="props.accountInfo.type_account.includes('Demand')" class="rounded py-3 px-4 bgSfumato no-block  text-white fw-semibold ">
            <div v-if=" requestPayout && requestPayout.status=='pending'" class="d-flex align-items-center justify-content-between w-100">
                <div class="d-flex flex-column">
                    <h4 class="fw-bold">{{ $t('requestOndemandTitle') }}:</h4>
                    <small> {{ $t('requestOndemandText1') }} : {{ requestPayout.created_at }}</small>
                </div>
                <div class="ms-auto text-end">
                    <h5 class="text-uppercase text-warning">{{ requestPayout.status }}</h5>
                </div>
            </div>
            <div v-else class="d-flex flex-column align-items-start justify-content-start w-100">
                <div class="d-flex align-items-center justify-content-between w-100">
                    <h6>{{ $t('requestOndemandSubTitle') }}</h6>
                    <div class="ms-auto text-end">
                        <button v-if="props.accountInfo.consistencyScore.scoreValue==null || props.accountInfo.consistencyScore.scoreValue > 20 || props.accountInfo.consistencyScore.scoreValue < 0 ||  isNaN(props.accountInfo.consistencyScore.scoreValue)" class="btn btn-primary disabled" >{{ $t('requestOndemandButton') }} </button>
                        <button v-else class="btn btn-primary " @click="askPayout()">{{ $t('requestOndemandButton') }} </button>
                    </div>
                </div >
                <div v-if=" requestPayout && requestPayout.status=='cancelled'">
                    <i class="text-danger"> {{ $t('Warning') }} </i> :   {{ $t('requestOndemandText1') }} : {{ formatData(requestPayout.created_at) }}  {{ $t('requestOndemandText2') }} <i class="text-danger text-uppercase">{{ $t('requestCancelled') }}</i>
                </div>
            </div>
            <h5 v-if="requestError"><span class="text-danger fw-bold">{{ $t('Warning') }}:</span> {{ $t('requestOndemandFailed') }}</h5>
        </div>
    </template>
</template>

<style scoped>

</style>
