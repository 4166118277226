<script setup>
import {ref, watch, computed, watchEffect} from "vue";
import {useI18n} from "vue-i18n";
import {storeToRefs} from "pinia";

import Card from "../Card.vue";
import IllegalStrategies from '../modals/IllegalStrategies.vue';

import { usePopup } from '../../store/popup.store';
import { useAccountStore } from '../../store/account.store';

const accountStore = useAccountStore();
const { accountInfo, openTrades } = storeToRefs(accountStore);

const props = defineProps({
    canReset: Boolean,
    resetTvs: Function,
});
const { popup } = usePopup();
const showStrategyPopup = () => {
    popup({
        title: 'Illegal strategies',
        component: IllegalStrategies,
        componentProps: { rules }
    });
}
const showRuleDescription = rule => {
    popup({
        title: rule.name,
        body: rule.description
    })
}

const accountItem = ref(null);
const lossAnalytics = ref({
    dailyLoss: {
        available: 0,
        total: 0,
        percentage: 0,
        progressBarValue: 0,
    },
    totalLoss: {
        available: 0,
        total: 0,
        percentage: 0,
        progressBarValue: 0,
    }
});
watch(accountInfo, async (oldValue, newValue) => {
    if (!newValue) {
        return;
    }

    accountStore.getAccountItem().then(async item => {
        accountItem.value = item || {
            props: {
                max_daily_drawdown: 5,
                max_drawdown: 10,
            }
        }

        const data = await accountStore.getUserTrackingForChart();

        let indexes = [];
        if (data.label.length) {
            let startTime = new Date();
            startTime.setHours(0, 0, 0);

            let endTime = new Date();
            endTime.setDate(endTime.getDate() + 1);
            endTime.setHours(23, 59, 59);

            indexes = data.label
                .filter(label => {
                    return new Date(label).getTime() >= startTime.getTime()
                        && new Date(label).getTime() <= endTime.getTime();
                })
                .map(label => data.label.indexOf(label));
            if (indexes.length === 0) { // Fallback to last available day
                const lastAvailableDay = data.label[data.label.length - 1];

                startTime = new Date(lastAvailableDay);
                startTime.setHours(0, 0, 0);

                endTime = new Date(lastAvailableDay);
                endTime.setHours(23, 59, 59);

                console.debug('getUserTrackingForChart @ Fallback to last available day', startTime, endTime);
                indexes = data.label
                    .filter(label => {
                        return new Date(label).getTime() >= startTime.getTime()
                            && new Date(label).getTime() <= endTime.getTime();
                    })
                    .map(label => data.label.indexOf(label));
            }
        }

        const balances = data.balance.filter((balance, index) => indexes.includes(index));
        const dailyMaxBalance = balances.length > 0 ? Math.max(...balances) : 0;
        let lastEquity = data.equity[data.equity.length - 1] || 0;

        // Daily Loss (Max amount of the balance that can be lost in a day):
        // - Available: (Daily Max Balance - Last Equity)
        // - Total: Initial Balance * Max Daily Drawdown Percentage
        // - Percentage: Daily Loss > Total Loss ? (Available - Total) : (Total + Available)
        // - Progress Bar Value: Available / Total
        const dailyLossAvailable = (dailyMaxBalance - lastEquity) * -1;
        lossAnalytics.value.dailyLoss.total = accountInfo.value.initial_balance * (accountItem.value.props.max_daily_drawdown / 100);
        lossAnalytics.value.dailyLoss.available = dailyLossAvailable > lossAnalytics.value.dailyLoss.total
            ? (dailyLossAvailable - lossAnalytics.value.dailyLoss.total) * -1
            : lossAnalytics.value.dailyLoss.total + dailyLossAvailable;
        const dailyLossPogressBarValue = +(dailyLossAvailable / lossAnalytics.value.dailyLoss.total);
        lossAnalytics.value.dailyLoss.progressBarValue = (dailyLossPogressBarValue || 0) > 100
            ? parseInt(100 + ((dailyLossPogressBarValue - 100) * -1))
            : parseInt(((dailyLossPogressBarValue || 0) * 100) + 100)

        // Max Loss (Max amount of the balance that can be lost in total):
        // - Available: (Total Loss + (Last Equity - Initial Balance))
        // - Total: Initial Balance * Max Drawdown Percentage
        // - Percentage: Daily Loss > Total Loss ? (Available - Total) : (Total + Available)
        // - Progress Bar Value: Available / Total
        if (!lastEquity && !data.equity.length && accountInfo.value.platform === 'match-trader') {
            lastEquity = accountInfo.value.ref_balance;
        }
        lossAnalytics.value.totalLoss.total = accountInfo.value.initial_balance * (accountItem.value.props.max_drawdown / 100);
        const maxLossAvailable = (lossAnalytics.value.totalLoss.total + (lastEquity - accountInfo.value.ref_balance));
        lossAnalytics.value.totalLoss.available = Number(maxLossAvailable);
        const maxLossProgressBarValue = +(maxLossAvailable / lossAnalytics.value.totalLoss.total);
        lossAnalytics.value.totalLoss.progressBarValue = maxLossProgressBarValue * 100;
    });
}, { deep: true });

const i18n = useI18n();
const healthCheckViolations = computed(() => {
    return accountInfo.value?.health_check_violations?.filter(v => ['martingale', 'hedging'].includes(v.rule))
        .reduce((a, b) => {
            return a + (b.count || 0);
        }, 0) || 0;
});
const rules = computed(() => ([
    {
        name: i18n.t('trial-max-daily-drawdown', { maxDailyDrawdownPercentage: accountItem.value?.props.max_daily_drawdown }),
        violated: accountInfo.value?.rule_1 === false,
        description: i18n.t('trial-max-daily-drawdown-description', { maxDailyDrawdownPercentage: accountItem.value?.props.max_daily_drawdown })
    },
    {
        name: i18n.t('trial-max-drawdown', { maxDrawdownPercentage: accountItem.value?.props.max_drawdown }),
        violated: accountInfo.value?.rule_2 === false,
        description: i18n.t('trial-max-drawdown-description', { maxDrawdownPercentage: accountItem.value?.props.max_drawdown })
    },
    {
        name: `Martingale + Hedging (${healthCheckViolations.value}/3)`,
        violated: accountInfo.value?.illegal_strategies?.martingale?.violated || accountInfo.value?.illegal_strategies?.hedging?.violated,
        warning: (healthCheckViolations.value > 0 && healthCheckViolations.value < 3),
        description: i18n.t('rules.martingale-hedging')
    },
    {
        name: `Arbitrage`,
        violated: accountInfo.value?.illegal_strategies?.arbitrage?.violated,
        description: i18n.t('rules.arbitrage')
    },
    {
        name: `High Frequency Trading`,
        violated: (accountInfo.value?.illegal_strategies && accountInfo.value?.illegal_strategies['high-frequency-trading']?.violated),
        description: i18n.t('rules.high-frequency-trading')
    },
    {
        name: `Gambling`,
        violated: accountInfo.value?.illegal_strategies?.gambling?.violated,
        description: i18n.t('rules.gambling')
    },
    {
        name: `Copy Trading`,
        violated: accountInfo.value?.illegal_strategies?.copytrading?.violated,
        description: i18n.t('rules.copy-trading')
    },
    {
        name: `News Straddling`,
        violated: (accountInfo.value?.illegal_strategies && accountInfo.value?.illegal_strategies['news-straddling']?.violated),
        description: i18n.t('rules.news-straddling')
    },
    {
        name: `Cross Hedging`,
        violated: (accountInfo.value?.illegal_strategies && accountInfo.value?.illegal_strategies['cross-hedging']?.violated),
        description: i18n.t('rules.cross-hedging')
    },
    /*{
        name: `Expert Advisor`,
        violated: (accountInfo.value?.illegal_strategies && accountInfo.value?.illegal_strategies['expert-advisor']?.violated),
        description: i18n.t('rules.expert-advisor')
    }*/
]));
const hasViolatedStrategies = computed(() => rules.value.some(rule => rule.violated));

// Dummy trick to make Bootstrap catch popover   :)
const tvsPopoverRef = ref(null);
watch(tvsPopoverRef, () => {
    try {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })
    } catch (e) {
        //
    }
}, { immediate: true, deep: true });
</script>

<template>
    <Card>
        <template #header>
            <h3 class="card-title">
                Loss Analytics

                <button
                    type="button"
                    @click="resetTvs();"
                    v-if="accountInfo?.status === 'active'"
                    class="btn btn-primary position-relative"
                    :class="{ 'btn-disabled': !(canReset && openTrades.length == 0)}"
                    :disabled="!(canReset && openTrades.length == 0)"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalReset"
                >
                    {{ $t('btn-reset') }}
                </button>

                <font-awesome-icon class="text-white px-2" icon="fa fa-info-circle" data-bs-toggle="modal" data-bs-target="#exampleModalinfotvs"/>
                <!-- Modal -->
                <div class="modal fade" id="exampleModalinfotvs" tabindex="-1" aria-labelledby="exampleModalinfotvsLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title text-primary fs-5" id="exampleModalinfotvsLabel"> {{ $t('trial-resetRule') }}</h1>
                            </div>
                            <div class="modal-body">
                                <ul>
                                    <li>
                                        {{ $t('trial-resetRule1') }}
                                    </li>
                                    <li>
                                        {{ $t('trial-resetRule2') }}
                                    </li>
                                    <li>
                                        {{ $t('trial-resetRule3') }}
                                    </li>
                                    <li>
                                        {{ $t('trial-resetRule4') }}
                                    </li>
                                </ul>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('Close') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Modal -->
            </h3>
        </template>
        <div class="loss-analytics">
            <div class="loss-analytics_progress-box">
                <div class="loss-analytics_progress-item">
                    <h1 class="loss-analytics_progress-item-title">{{ $t('trial-AvaibDLoss') }}</h1>
                    <div class="loss-analytics_progress-item-progress">
                        <span>{{ $formatAmount(lossAnalytics.dailyLoss.available, accountInfo?.currency) }} / {{ $formatAmount(lossAnalytics.dailyLoss.total, accountInfo?.currency) }}</span>
                        <span>{{ lossAnalytics.dailyLoss.progressBarValue.toFixed(0) }}% / 100%</span>
                    </div>
                    <div class="progress loss-analytics_progress-item-progress-bar" id="daily-loss">
                        <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" :style="{width: `${lossAnalytics.dailyLoss.progressBarValue}%`}"></div>
                    </div>
                </div>
                <div class="loss-analytics_progress-item">
                    <h1 class="loss-analytics_progress-item-title">{{ $t('trial-AvaibLoss') }}</h1>
                    <div class="loss-analytics_progress-item-progress">
                        <span>{{ $formatAmount(lossAnalytics.totalLoss.available, accountInfo?.currency) }} / {{ $formatAmount(lossAnalytics.totalLoss.total, accountInfo?.currency) }}</span>
                        <span>{{ lossAnalytics.totalLoss.progressBarValue.toFixed(0) }}% / 100%</span>
                    </div>
                    <div class="progress loss-analytics_progress-item-progress-bar" id="total-loss">
                        <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" :style="{width: `${lossAnalytics.totalLoss.progressBarValue}%`}"></div>
                    </div>
                </div>

                <div class="loss-analytics_progress-item">
                    <h1 class="loss-analytics_progress-item-title">
                        Inconsistency Score
                        <i class="text-white px-2 fa fa-info-circle cursor-pointer" v-if="accountInfo?.consistencyScore?.bestProfit" ref="tvsPopoverRef" data-bs-toggle="popover" title="INCONSISTENCY SCORE" :data-bs-content="`Formula: ${accountInfo?.consistencyScore.bestProfit}/${accountInfo?.consistencyScore.sum} * 100 = ${accountInfo?.consistencyScore.scoreValue + '%'}`"/>
                    </h1>
                    <div class="loss-analytics_progress-item-progress">
                        <div class="loss-analytics_progress-item-progress-tvs">
                            <div class="loss-analytics_progress-item-progress-tvs-total">
                                <span class="loss-analytics_progress-item-progress-tvs-total-title">Max Inconsistency Score</span>
                                <span class="loss-analytics_progress-item-progress-tvs-total-value">{{ accountItem?.props?.max_trader_value_score }} %</span>
                            </div>
                            <div class="loss-analytics_progress-item-progress-tvs-total">
                                <span class="loss-analytics_progress-item-progress-tvs-total-title">Highest Profit in a day</span>
                                <span class="loss-analytics_progress-item-progress-tvs-total-value">{{ $formatAmount(accountInfo?.consistencyScore?.bestProfit || 0, accountInfo?.currency) }}</span>
                            </div>
                            <div class="loss-analytics_progress-item-progress-tvs-total">
                                <span class="loss-analytics_progress-item-progress-tvs-total-title">Total account profit</span>
                                <span class="loss-analytics_progress-item-progress-tvs-total-value">{{ $formatAmount(accountInfo?.consistencyScore?.sum || 0, accountInfo?.currency) }}</span>
                            </div>
                        </div>
                        <span>
                            {{ accountInfo?.consistencyScore?.scoreValue ? parseFloat(accountInfo?.consistencyScore?.scoreValue).toFixed(2) : '0' }}%
                            / 100%
                        </span>
                    </div>
                    <div class="progress loss-analytics_progress-item-progress-bar" id="total-loss">
                        <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" :style="{'width': `${accountInfo?.consistencyScore?.scoreValue ? parseFloat(accountInfo?.consistencyScore?.scoreValue).toFixed(2) : '0'}%`}"></div>
                    </div>
                </div>
            </div>

            <div class="loss-analytics_progress-list">
                <template v-if="hasViolatedStrategies">
                    <h1 class="loss-analytics_progress-list-rules-item">Illegal strategies</h1>
                    <ul class="loss-analytics_progress-list-rules">
                        <li v-for="rule in rules" :key="rule" :class="{ 'violated': rule.violated, 'warning': rule.warning }">
                            {{ rule.name }}

                            <i class="fa fa-info-circle" @click="showRuleDescription(rule)" />
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <div class="loss-analytics_progress-list-rules-green-check-box">
                        <h2>
                            Illegal strategies

                            <i class="text-white px-2 fa fa-info-circle" @click="showStrategyPopup" />
                        </h2>
                        <img src="/assets/images/icon/check-icon.svg" alt="green-check" />
                    </div>
                </template>
            </div>
        </div>
    </Card>
</template>

<style scoped lang="scss">
.loss-analytics {
    display: flex;
    gap: 32px;
}
.loss-analytics_progress-box {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 70px;
    border-right: 1px solid rgba(53, 59, 70, 1);
    padding-right: 32px;
}
.loss-analytics_progress-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
}
.loss-analytics_progress-item-title {
    font-family: Heebo;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.38px;
    text-align: left;
}
.loss-analytics_progress-item-progress {
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
        font-family: Heebo;
        font-size: 14px;
        font-weight: 500;
        line-height: 20.56px;
        text-align: left;
    }
}
.loss-analytics_progress-item-progress-bar {
    width: 100%;
    height: 8px;
    border-radius: 32px;

    &#daily-loss {
        background: rgba(111, 207, 151, 0.2);

        .progress-bar {
            background: rgba(111, 207, 151, 1);
        }
    }

    &#total-loss {
        background: rgba(255, 128, 86, 0.2);

        .progress-bar {
            background: rgba(255, 128, 86, 1);
        }
    }
}
.loss-analytics_progress-list-rules-item {
    font-family: Heebo;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.5px;
    text-align: left;

    display: flex;

    margin-bottom: 32px;

    &::before {
        content: url(/assets/images/icon/alert-icon.svg);
        padding-inline-end: 24px;
    }
}
.loss-analytics_progress-list-rules {
    padding: 0;

    li {
        padding: 0;

        list-style: none;

        &:not(.violated)::before {
            content: url(/assets/images/icon/check-icon-24.svg);
            padding-inline-end: 24px;
        }

        &.violated::before {
            content: url(/assets/images/icon/alert-icon.svg);
            padding-inline-end: 24px;
        }

        font-family: Heebo;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.5px;
        text-align: left;

        display: flex;
        align-items: center;
        width: 100%;

        margin: 12px 0;

        color: rgba(53, 59, 70, 1);

        &.violated {
            color: rgba(255, 98, 98, 1);
        }

        &.warning {
            color: rgba(255, 255, 0, 1);
        }

        i {
            margin-left: auto;
            cursor: pointer;
            color: white;
        }
    }
}
.modal-dialog {
    max-width: 800px!important;
}
.loss-analytics_progress-item-progress-tvs {
    display: flex;
    gap: 16px;

    .loss-analytics_progress-item-progress-tvs-total {
        display: flex;
        flex-direction: column;
        gap: 3px;

        .loss-analytics_progress-item-progress-tvs-total-title {
            font-family: Heebo;
            font-size: 12px;
            font-weight: 500;
            line-height: 11.75px;
            text-align: left;
            color: rgba(255, 255, 255, 0.5);
            text-transform: uppercase;
        }

        .loss-analytics_progress-item-progress-tvs-total-value {
            font-family: Heebo;
            font-size: 12px;
            font-weight: 500;
            line-height: 11.75px;
            text-align: left;
            color: white;
        }
    }
}
.loss-analytics_progress-list-rules-green-check-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 32px;

    h2 {
        font-family: Heebo;
        font-size: 18px;
        font-weight: 400;
        line-height: 20.56px;
        text-align: left;
    }

    img {
        width: 48px;
    }
}
</style>
