<template>
    <div v-if="status!='active'" class="banner-violation">
    <div  class="row mx-auto py-2 px-1 d-flex justify-content-between rounded " style="background-color: rgba(194, 79, 53, 0.2)!important;display: flex!important;">
        <div class="col-12 d-flex flex-column align-items-start justify-content-center fw-bold " style="display: flex!important;">
            <h2 class="m-0 p-0 text-white text-center mb-2" >{{ $t('Violation-title') }}</h2>
            <h4 class="d-flex gap-2">{{ $t('Violation-rule') }}
                <h4 v-if="reason?.includes('3DAYS')" class="text-white">{{ $t('3DAYS') }}</h4>
                <h4 v-if="reason?.includes('10%')" class="text-white">{{ $t('10%MarginViolation') }}</h4>
                <h4 v-if="reason?.includes('5%')" class="text-white">{{ $t('5%MarginViolation') }}</h4>
                <h4 v-if="reason?.includes('120sec')" class="text-white">{{ $t('120sec') }}</h4>
                <h4 v-if="reason?.includes('Health check')" class="text-white">{{ $t('health-check-violations-title') }}</h4>
            </h4>
            <p v-if="reason?.includes('3DAYS')" class="m-0 mb-1 fw-normal">{{ $t('3DAYStext') }}</p>
            <p v-if="reason?.includes('10%')" class="m-0 mb-1 fw-normal">{{ $t('10%MarginViolationText') }}</p>
            <p v-if="reason?.includes('5%')" class="m-0 mb-1 fw-normal">{{ $t('5%MarginViolationText') }}</p>
            <p v-if="reason?.includes('120sec')" class="m-0 mb-1 fw-normal">{{ $t('120secText') }}</p>
            <p v-if="reason?.includes('Health check')" class="text-white">{{ $t('health-check-violations-description') }}</p>
            <h6 class="d-flex gap-2">{{ $t('Date') }} <h6 class="text-white">{{ reasonPattern.date }}</h6></h6>
        </div>
        <div class="col-12 d-flex flex-column align-items-start justify-content-center fw-bold " style="display: flex!important;">
            <h6 class="m-0 p-0 text-white text-center mb-2" ><span class="text-danger">{{ $t('Warning') }}:</span> {{ $t('Violation-text') }} </h6>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    name:'bannerViolation',
  methods:{
        patternReason(){
            if(this.reason?.includes('3DAYS')){
                let res= this.reason.split(';')[0]
                this.reasonPattern.date=res.split('-')[1]
            }else if(this.reason?.includes('10%')){
                this.reasonPattern.date=this.reason.split('-')[1]
            }else if(this.reason?.includes('5%')){
                let parts =  this.reason.split(" - ");
                this.reasonPattern.date=parts[parts.length - 1];
            }else if(this.reason?.includes('120sec')){
                let date= this.reason.split('-')[1]
                this.reasonPattern.date=date.split(';')[0]
            }
            else if(this.reason?.includes('Health check')) {
                this.reasonPattern.date = this.reason.split('-')[1].trim();
            }
        }
  },
  props:{
        status:String,
        reason:String
    },
    mounted(){
        this.patternReason()
    },
  data(){
    return{
        reasonPattern:{}
    }
  },
  beforeMount() {

    }
}
</script>

<style scoped>
.banner-violation{
    width:100%;
}
</style>
