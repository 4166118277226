<template>
    <div>
        <h2 class="text-white fw-bold mb-5" >{{ $t('sidebar-Calendar') }}</h2>
       <div class="m-auto" >
                    <div class="row p-0">
                        <div class="col-12 m-auto">
                        <div class=" cardNoHover p-3" >
                            <div class="card-body p-0 p-lg-2">
                                <h4 class="mb-3">{{ today}}</h4>
                                <div class="table card-body p-0 overflow-auto" style="max-height: 70vh">
                                    <table class="table table-hover" >
                                        <thead class=" text-white">
                                            <tr>
                                                <th scope="col">{{ $t('Time') }}</th>
                                                <th scope="col">{{ $t('Currency') }}</th>
                                                <th scope="col">{{ $t('Event') }}</th>
                                                <th scope="col">{{ $t('Actual') }}</th>
                                                <th class="d-none d-md-table-cell" scope="col">{{ $t('Forecast') }}</th>
                                                <th class="d-none d-md-table-cell" scope="col">{{ $t('Prev') }}</th>
                                                <th class="d-none d-md-table-cell" scope="col">{{ $t('Importance') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="info in infos" :key="info._id">
                                                <th>{{ info.creazione }}</th>
                                                <th v-if="info.flag!=undefined"><img class="me-2 " :src="'assets/icons/flag-icon-css/flags/'+ info.flag.toLowerCase() +'.svg'" alt="" style="width: 30px;">{{ info.currency }}</th>
                                                <th v-else>{{ info.flagCur }}</th>
                                                <th>{{ info.event }}</th>
                                                <th >{{ info.actual }}</th>
                                                <th class="d-none d-md-table-cell">{{ info.forecast }}</th>
                                                <th class="d-none d-md-table-cell">{{ info.previous }}</th>
                                                <th class="d-none d-md-table-cell"><font-awesome-icon v-for="n in 3" :key="n"  :class="n<=info.rank?'text-success':'op'" icon="fa-regular fa-star"/></th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
        </div>
    </div>
</template>

<script>
import { getCode } from 'country-list';
import countryList from 'country-list';

export default {
  name: "",
  data(){
    return{
        infos:[],
        today:'',
        paesi_valute : [
        {
    "paese": "Afghanistan",
    "codice_valuta": "AFN",
    "sigla_iso": "AF"
  },
  {
    "paese": "Albania",
    "codice_valuta": "ALL",
    "sigla_iso": "AL"
  },
  {
    "paese": "Algeria",
    "codice_valuta": "DZD",
    "sigla_iso": "DZ"
  },
  {
    "paese": "Andorra",
    "codice_valuta": "EUR",
    "sigla_iso": "AD"
  },
  {
    "paese": "Angola",
    "codice_valuta": "AOA",
    "sigla_iso": "AO"
  },
  {
    "paese": "Antigua e Barbuda",
    "codice_valuta": "XCD",
    "sigla_iso": "AG"
  },
  {
    "paese": "Arabia Saudita",
    "codice_valuta": "SAR",
    "sigla_iso": "SA"
  },
  {
    "paese": "Argentina",
    "codice_valuta": "ARS",
    "sigla_iso": "AR"
  },
  {
    "paese": "Armenia",
    "codice_valuta": "AMD",
    "sigla_iso": "AM"
  },
  {
    "paese": "Australia",
    "codice_valuta": "AUD",
    "sigla_iso": "AU"
  },
  {
    "paese": "Austria",
    "codice_valuta": "EUR",
    "sigla_iso": "AT"
  },
  {
    "paese": "Azerbaigian",
    "codice_valuta": "AZN",
    "sigla_iso": "AZ"
  },
  {
  "paese": "India",
  "codice_valuta": "INR",
  "sigla_iso": "IN"
},{
  "paese": "Singapore",
  "codice_valuta": "SGD",
  "sigla_iso": "SG"
},
        {"paese": "Italia", "valuta": "Euro", "codice_valuta": "EUR", "sigla_iso": "IT"},
        {"paese": "Stati Uniti", "valuta": "Dollaro", "codice_valuta": "USD", "sigla_iso": "US"},
        {"paese": "Giappone", "valuta": "Yen", "codice_valuta": "JPY", "sigla_iso": "JP"},
        {"paese": "Regno Unito", "valuta": "Sterlina", "codice_valuta": "GBP", "sigla_iso": "GB"},
        {"paese": "Canada", "valuta": "Dollaro canadese", "codice_valuta": "CAD", "sigla_iso": "CA"},
        {"paese": "Svizzera", "valuta": "Franco svizzero", "codice_valuta": "CHF", "sigla_iso": "CH"},
        {"paese": "Cina", "valuta": "Renminbi", "codice_valuta": "CNY", "sigla_iso": "CN"},
        {"paese": "Hong Kong", "valuta": "Dollaro di Hong Kong", "codice_valuta": "HKD", "sigla_iso": "HK"},
        {"paese": "Nuova Zelanda", "valuta": "Dollaro neozelandese", "codice_valuta": "NZD", "sigla_iso": "NZ"},
        {"paese": "Sudafrica", "valuta": "Rand sudafricano", "codice_valuta": "ZAR", "sigla_iso": "ZA"},
        {"paese": "Svezia", "valuta": "Corona svedese", "codice_valuta": "SEK", "sigla_iso": "SE"},
        {"paese": "Russia", "valuta": "Rublo", "codice_valuta": "RUB", "sigla_iso": "RU"},
        {"paese": "Germania", "valuta": "Euro", "codice_valuta": "EUR", "sigla_iso": "DE"},
        {"paese": "Brasile", "valuta": "Real", "codice_valuta": "BRL", "sigla_iso": "BR"},
        {"paese": "Corea del Sud", "valuta": "Won", "codice_valuta": "KRW", "sigla_iso": "KR"},
        {"paese": "Spagna", "valuta": "Euro", "codice_valuta": "EUR", "sigla_iso": "ES"},
         {"paese": "Francia", "valuta": "Euro", "codice_valuta": "EUR", "sigla_iso": "FR"},
        ]
    }
  },
  mounted() {
    this.getCalendar()
  },
  methods:{
    getCalendar(){
        axios.get(`${this.$baseUrl}/extra/getEconomicCalendar`).then((response)=>{
            this.infos=response.data
            const countries = countryList.getData();
            this.infos.forEach(element => {
                let countryCode=''
                if(element.flagCur=='United Kingdom'){
                    countryCode='GB'
                }else if(element.flagCur=='United States'){
                    countryCode='US'
                }
                else if(element.flagCur=='Russia'){
                    countryCode='RU'
                }
                else if(element.flagCur=='South Korea'){
                    countryCode='KR'
                }
                else if(element.flagCur=='North Korea'){
                    countryCode='KP'
                }
                else{
                    countryCode = getCode(element.flagCur);
                }
                element.flag=countryCode
                const dateObject = new Date(element.timestamp);
                const options = { hour: 'numeric', minute: 'numeric', hour12: false };
                const timeString = dateObject.toLocaleTimeString(undefined, options);
                element.creazione= timeString
                this.paesi_valute.forEach(item => {
                    if(item.sigla_iso==element.flag){
                        element.currency=item.codice_valuta
                    }
                });
            });
            this.today= new Date().toDateString()
        })
    }
  }
};
</script>

<style scoped>
.op{
    opacity: 0.5;
}

</style>
