<template>
    <div id="main-wrapper">
        <Spinner v-if="loading" />

        <header class="topbar">
            <Navbar />
        </header>

        <aside class="left-sidebar">
            <div class="scroll-sidebar" style="background-color: rgba(255, 255, 255, 0)">
                <Sidebar />
            </div>
        </aside>



        <div class="page-wrapper">
            <div class="container-fluid mt-5">
                <!-- Breadcrumb -->
                <div class="row">
                    <div class="col-12">
                        <h1 class="h3 mb-2 text-white fw-bold">
                            {{ $t("Checkout") }}
                        </h1>
                    </div>
                </div>
                <!-- /Breadcrumb -->
                <ModalCreditCard
                    :showCreditCardModal="showCreditCardModal"
                    @update:showCreditCardModal="showCreditCardModal = $event"
                    :merchantData="solidgateData"
                    @update:loadingPayment="loadingPayment = $event"
                    ref="creditCardModal"
                />

                <form class="checkout" @submit.prevent="confirm">
                    <div class="checkout-payment" ref="paymentEl">
                        <h2 class="checkout-payment__title">
                            {{ $t("checkout-paymentMethods") }}
                        </h2>
                        <div class="mt-3 p-3 mb-5 checkout-warning" v-if="user?.kyc !== 'approved'">
                            <p>Card payments are only allowed to verified members, please complete your <b>KYC</b>.</p>
                            <button class="checkout-warning__btn btn gradient-border-btn" type="button" @click.prevent="verifyClick" :disabled="veriff === null">
                                Verify Now
                            </button>
                            <div  id='veriff-root' class="veriff-content col-12"></div>
                        </div>
                        <ul class="checkout-payment__methods">
                            <li :class="user?.kyc !== 'approved' ? 'checkout-payment__disabled' : ''">
                                <label class="checkout-payment__label">
                                    <input type="radio" name="payment-method" v-model="paymentMethod"
                                           value="solidgate" :disabled="!user?.kyc" />
                                    Credit or Debit Card
                                </label>
                                <PaymentMethods />
                            </li>
                            <li :class="user?.kyc !== 'approved' ? 'checkout-payment__disabled' : ''">
                                <label class="checkout-payment__label">
                                    <input type="radio" name="payment-method" v-model="paymentMethod"
                                           value="card-processor" :disabled="user?.kyc !== 'approved'" required />
                                    Credit or Debit Card
                                </label>
                                <PaymentMethods />
                            </li>
                            <li>
                                <label class="checkout-payment__label">
                                    <input type="radio" name="payment-method" v-model="paymentMethod"
                                        value="cryptocurrency" />
                                    Cryptocurrency
                                </label>
                                <div class="payment-method__container">
                                    <img src="/assets/images/coinbase_white.png" class="checkout-payment__logo coinbase"
                                        alt="Coinbase" />
                                </div>
                            </li>
                            <li>
                                <label class="checkout-payment__label">
                                    <input type="radio" name="payment-method" v-model="paymentMethod"
                                        value="confirmo" />
                                    Confirmo
                                </label>
                                <div class="payment-method__container">
                                    <img src="/assets/images/confirmo_white.svg" class="checkout-payment__logo"
                                        alt="Confirmo" />
                                </div>
                            </li>
                        </ul>

                        <h2 class="checkout-payment__title">
                            {{ $t("checkout-billingDettails") }}
                        </h2>
                        <div class="checkout-payment__billing-details">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="name">{{
                                            $t("checkout-name")
                                            }}</label>
                                        <input type="text" class="form-control" id="name"
                                            :placeholder="$t('checkout-name')" v-model="checkout.billing_details.name" required />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="lastName">{{
                                            $t("checkout-lastName")
                                            }}</label>
                                        <input type="text" class="form-control" id="lastName" :placeholder="$t('checkout-lastName')" v-model="checkout.billing_details.surname" required />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="country">{{
                                    $t("checkout-country")
                                    }}</label>
                                <select class="form-control" id="country" v-model="checkout.billing_details.country"
                                    >
                                    <option disabled selected value="">
                                        {{ $t("checkout-country") }}
                                    </option>
                                    <option v-for="country in countries" :value="country.code">
                                        {{ country.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="col-10">
                                    <div class="form-group">
                                        <label for="address">{{
                                            $t("checkout-address")
                                            }}</label>
                                        <input type="text" class="form-control" id="address" :placeholder="$t('checkout-address')" v-model="checkout.billing_details.address" />
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="nr">{{
                                            $t("checkout-nr")
                                            }}</label>
                                        <input type="text" class="form-control" id="nr" :placeholder="$t('checkout-nr')"
                                            v-model="checkout.billing_details.number" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="city">{{
                                    $t("checkout-city")
                                    }}</label>
                                <input type="text" class="form-control" id="city" :placeholder="$t('checkout-city')"
                                    v-model="checkout.billing_details.city" />
                            </div>
                            <div class="form-group">
                                <label for="zip">{{
                                    $t("checkout-zip")
                                    }}</label>
                                <input type="text" class="form-control" id="zip" :placeholder="$t('checkout-zip')"
                                    v-model="checkout.billing_details.zip" />
                            </div>
                        </div>
                    </div>

                    <div class="checkout-cart" ref="cartEl">
                        <div class="checkout-cart__box">
                            <h3 class="checkout-cart__box-title">
                                {{ $t("checkout-yourOrder") }}
                            </h3>

                            <ul class="checkout-cart__items">
                                <li v-for="(item, index) in checkoutCart.items" :key="index">
                                    <div class="d-flex flex-column w-100">
                                        <Platforms v-model="item.platform_name" :platforms="platforms" />
                                        <div class="d-flex pt-sm-2 checkout-cart__item">
                                            <span class="checkout-cart__items-quantity">1</span>
                                            <span class="checkout-cart__items-name">
                                                <span>{{ item.name }}</span>
                                                <small class="d-sm-none d-md-block">
                                                    {{ $formatAmount(item.size, item.currency_account) }} •
                                                    1:{{ item.leverage }} •
                                                    MDD: {{ item.max_daily_drawdown }}% •
                                                    MD: {{ item.max_drawdown }}% •
                                                    Max TVR: {{ item.max_trader_value_score }}% •
                                                    {{ item.payout_type }} •
                                                    {{ item.profit_split }}%
                                                </small>
                                            </span>
                                            <span
                                                class="checkout-cart-items-amount d-flex flex-column text-center justify-content-center align-items-center px-2">
                                                <!--<span class="text-small text-decoration-line-through" v-if="item.original_price">{{ $formatAmount(item.original_price, 'USD') }}</span>
                                            <span class="text-discount" v-if="item.original_price">{{ -item.discount_value }}% OFF</span>-->
                                                <span>{{
                                                    $formatAmount(
                                                        item.price,
                                                        "USD"
                                                    )
                                                }}</span>
                                            </span>

                                            <button class="btn btn-outline-danger mx-2" type="button"
                                                @click="removeItemFromCart" :disabled="loadingRemove">
                                                <i class="fa" :class="{
                                                    'fa-times':
                                                        !loadingRemove,
                                                    'fa-spinner':
                                                        loadingRemove,
                                                    'fa-spin':
                                                        loadingRemove,
                                                }"></i>
                                            </button>
                                        </div>
                                        <div class="checkout-cart__items-name">
                                            <small class="d-md-none d-sm-block">
                                                {{ $formatAmount(item.size, item.currency_account) }} •
                                                1:{{ item.leverage }} •
                                                MDD: {{ item.max_daily_drawdown }}% •
                                                MD: {{ item.max_drawdown }}% •
                                                Max TVR: {{ item.max_trader_value_score }}% •
                                                {{ item.payout_type }} •
                                                {{ item.profit_split }}%
                                            </small>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <div class="checkout-cart__credentials">
                                <i class="fa fa-clock"></i>
                                <label v-html="$t('checkout-receiveCredentials', {
                                    time: 120,
                                })
                                    "></label>
                            </div>

                            <div class="checkout-cart__coupon">
                                <div class="form-group d-flex flex-row">
                                    <input type="text" class="form-control" id="coupon"
                                        :placeholder="$t('checkout-coupon')" v-model="coupon.code"
                                        :readonly="cart?.coupon?.length" />
                                    <button type="button" class="btn" @click="applyCoupon" :disabled="cart?.coupon || coupon.applied
                                        ">
                                        {{ $t("checkout-apply") }}
                                    </button>
                                </div>
                                <div class="checkout-cart__coupon-applied" :class="{
                                    'text-success': coupon.applied,
                                    'text-danger':
                                        coupon.message && !coupon.applied,
                                }" v-show="coupon.message">
                                    <i class="fa" :class="{
                                        'fa-check': coupon.applied,
                                        'fa-times': !coupon.applied,
                                    }"></i>
                                    {{ coupon.message }}
                                </div>
                            </div>


                            <div class="checkout-cart_fidelity-points" v-if="usableFidelityPoints">
                                <h5 class="text-ofp text-center">Fidelity points</h5>
                                <div class="checkout-cart_fidelity-points_counter">
                                    <span class="checkout-cart_fidelity-points_selected">{{ $formatNumber(fidelityPointsToUse) }} / {{ $formatNumber(fidelityPoints.total) }}</span>
                                    <span class="checkout-cart_fidelity-points_max">{{ $formatNumber(fidelityPoints.total) }}</span>
                                </div>
                                <div>
<!--                                    <input type="range" class="form-range" id="fidelityPointsRange" v-model="fidelityPointsToUse" :max="fidelityPoints.total" min="0" step="1">-->
                                    <VueSlider @dragEnd="draggingFidelityPoints" v-model="fidelityPointsToUse" :max="fidelityPoints.total" :process-style="{backgroundColor: 'background-color: rgb(255, 104, 68)'}" />
                                </div>
                                <div class="checkout-cart_fidelity-discount">
                                    <span>{{ Math.floor(fidelityPointsToUse / 40) >= 1 ? '- ' : '' }}{{ $formatAmount(Math.floor(fidelityPointsToUse / 40)) }}</span>
                                </div>
                            </div>

                            <div class="checkout-cart__total">
                                <span class="checkout-cart__total-title">{{ $t("Total") }}</span>
                                <div class="checkout-cart__total-amount-box">
                                    <span class="checkout-cart__total-amount">{{ $formatAmount(checkoutCart.total, "USD") }}</span>
                                    <span class="checkout-cart__total-amount checkout-cart__total-amount-original" v-if="coupon.applied">{{ $formatAmount(coupon.originalAmount, "USD") }}</span>
                                </div>
                            </div>

                            <div class="checkout-cart__terms">
                                <label>
                                    <input type="checkbox" name="terms" id="terms" v-model="termsAgreed" required />
                                    <span v-html="$t('checkout-terms')"></span>
                                </label>

                                <label>
                                    <input type="checkbox" name="platform-agree" id="platform-agree" v-model="platformAgree" required />
                                    <span v-html="$t('checkout-platform')"></span>
                                </label>

                                <label>
                                    <input type="checkbox" name="rules-agree" id="rules-agree" v-model="rulesAgree" required />
                                    <span v-html="$t('checkout-rules')"></span>
                                </label>
                            </div>

                            <button class="checkout-cart__pay btn btn-ofp w-100" type="submit"
                                :disabled="loadingPayment">
                                Pay with {{ paymentMethodName }}
                                <i class="fa fa-spinner fa-spin mx-2" v-if="loadingPayment"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <TermsAndConditionModal />

        <Footer />
    </div>
</template>

<script setup>
import {
    ref,
    onMounted,
    watch,
    readonly,
    inject,
    watchEffect,
    computed,
    nextTick,
} from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "../store/user.store";
import { useCartStore } from "../store/cart.store";
import { useItemsStore } from "../store/items.store";

import Spinner from "../components/Spinner.vue";
import Navbar from "../components/statics/navbar.vue";
import Sidebar from "../components/Sidebar.vue";
import Footer from "../components/statics/footer.vue";
import TermsAndConditionModal from "../components/modals/TermsAndConditionModal.vue";
import Platforms from "../components/custom-account/Platforms.vue";
import PaymentMethods from '../components/checkout/PaymentMethods.vue'
import { useRoute, useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useGtm } from "@gtm-support/vue-gtm";
import { useNotification } from "@kyvg/vue3-notification";
import { usePopup } from "../store/popup.store";
import { useI18n } from "vue-i18n";
import { Veriff } from '@veriff/js-sdk';

import VueSlider from "vue-3-slider-component";
import ModalCreditCard from "../components/ModalCreditCard.vue";

const trackKlaviyoEvent = inject("$trackKlaviyoEvent");

const $gtm = useGtm();

const route = useRoute();
const router = useRouter();
const { cookies } = useCookies();
const { notify } = useNotification();

const userStore = useUserStore();
const { user, fidelityPoints } = storeToRefs(userStore);

const cartStore = useCartStore();
const { fetchCart, checkCoupon, addCoupon, confirmOrder, payOrder, clean, setPlatformForCart } =
    cartStore;
const { cart } = storeToRefs(cartStore);

const itemsStore = useItemsStore();
const { fetchItems, getVersionById, getItemByVersionId, queryVersion } =
    itemsStore;

const { popup } = usePopup();
const i18n = useI18n();

const loading = ref(false);
const loadingPayment = ref(false);
const loadingRemove = ref(false);
let usableFidelityPoints = ref(true);
const showCreditCardModal = ref(false);
const solidgateData = ref({});

const veriff =  ref(null);

const paymentMethod = ref("solidgate");
const countries = readonly([
    { code: "AX", label: "Aland Islands" },
    { code: "AL", label: "Albania" },
    { code: "DZ", label: "Algeria" },
    { code: "AS", label: "American Samoa" },
    { code: "AD", label: "Andorra" },
    { code: "AO", label: "Angola" },
    { code: "AI", label: "Anguilla" },
    { code: "AQ", label: "Antarctica" },
    { code: "AG", label: "Antigua And Barbuda" },
    { code: "AR", label: "Argentina" },
    { code: "AM", label: "Armenia" },
    { code: "AW", label: "Aruba" },
    { code: "AU", label: "Australia" },
    { code: "AT", label: "Austria" },
    { code: "AZ", label: "Azerbaijan" },
    { code: "BS", label: "Bahamas" },
    { code: "BH", label: "Bahrain" },
    { code: "BD", label: "Bangladesh" },
    { code: "BB", label: "Barbados" },
    { code: "BY", label: "Belarus" },
    { code: "BE", label: "Belgium" },
    { code: "BZ", label: "Belize" },
    { code: "BJ", label: "Benin" },
    { code: "BM", label: "Bermuda" },
    { code: "BT", label: "Bhutan" },
    { code: "BO", label: "Bolivia" },
    { code: "BA", label: "Bosnia And Herzegovina" },
    { code: "BW", label: "Botswana" },
    { code: "BV", label: "Bouvet Island" },
    { code: "BR", label: "Brazil" },
    { code: "IO", label: "British Indian Ocean Territory" },
    { code: "BN", label: "Brunei Darussalam" },
    { code: "BG", label: "Bulgaria" },
    { code: "BF", label: "Burkina Faso" },
    { code: "BI", label: "Burundi" },
    { code: "KH", label: "Cambodia" },
    { code: "CM", label: "Cameroon" },
    { code: "CA", label: "Canada" },
    { code: "CV", label: "Cape Verde" },
    { code: "KY", label: "Cayman Islands" },
    { code: "CF", label: "Central African Republic" },
    { code: "TD", label: "Chad" },
    { code: "CL", label: "Chile" },
    { code: "CN", label: "China" },
    { code: "CX", label: "Christmas Island" },
    { code: "CC", label: "Cocos (Keeling) Islands" },
    { code: "CO", label: "Colombia" },
    { code: "KM", label: "Comoros" },
    { code: "CG", label: "Congo" },
    { code: "CD", label: "Congo}, Democratic Republic" },
    { code: "CK", label: "Cook Islands" },
    { code: "CR", label: "Costa Rica" },
    { code: "CI", label: "Cote D'Ivoire" },
    { code: "HR", label: "Croatia" },
    { code: "CU", label: "Cuba" },
    { code: "CY", label: "Cyprus" },
    { code: "CZ", label: "Czech Republic" },
    { code: "DK", label: "Denmark" },
    { code: "DJ", label: "Djibouti" },
    { code: "DM", label: "Dominica" },
    { code: "DO", label: "Dominican Republic" },
    { code: "EC", label: "Ecuador" },
    { code: "EG", label: "Egypt" },
    { code: "SV", label: "El Salvador" },
    { code: "GQ", label: "Equatorial Guinea" },
    { code: "ER", label: "Eritrea" },
    { code: "EE", label: "Estonia" },
    { code: "ET", label: "Ethiopia" },
    { code: "FK", label: "Falkland Islands (Malvinas)" },
    { code: "FO", label: "Faroe Islands" },
    { code: "FJ", label: "Fiji" },
    { code: "FI", label: "Finland" },
    { code: "FR", label: "France" },
    { code: "GF", label: "French Guiana" },
    { code: "PF", label: "French Polynesia" },
    { code: "TF", label: "French Southern Territories" },
    { code: "GA", label: "Gabon" },
    { code: "GM", label: "Gambia" },
    { code: "GE", label: "Georgia" },
    { code: "DE", label: "Germany" },
    { code: "GH", label: "Ghana" },
    { code: "GI", label: "Gibraltar" },
    { code: "GR", label: "Greece" },
    { code: "GL", label: "Greenland" },
    { code: "GD", label: "Grenada" },
    { code: "GP", label: "Guadeloupe" },
    { code: "GU", label: "Guam" },
    { code: "GT", label: "Guatemala" },
    { code: "GG", label: "Guernsey" },
    { code: "GN", label: "Guinea" },
    { code: "GW", label: "Guinea-Bissau" },
    { code: "GY", label: "Guyana" },
    { code: "HT", label: "Haiti" },
    { code: "HM", label: "Heard Island & Mcdonald Islands" },
    { code: "VA", label: "Holy See (Vatican City State)" },
    { code: "HN", label: "Honduras" },
    { code: "HK", label: "Hong Kong" },
    { code: "HU", label: "Hungary" },
    { code: "IS", label: "Iceland" },
    { code: "IN", label: "India" },
    { code: "ID", label: "Indonesia" },
    { code: "IE", label: "Ireland" },
    { code: "IM", label: "Isle Of Man" },
    { code: "IL", label: "Israel" },
    { code: "IT", label: "Italia" },
    { code: "JM", label: "Jamaica" },
    { code: "JP", label: "Japan" },
    { code: "JE", label: "Jersey" },
    { code: "JO", label: "Jordan" },
    { code: "KZ", label: "Kazakhstan" },
    { code: "KE", label: "Kenya" },
    { code: "KI", label: "Kiribati" },
    { code: "KR", label: "Korea" },
    { code: "XK", label: "Kosovo" },
    { code: "KW", label: "Kuwait" },
    { code: "KG", label: "Kyrgyzstan" },
    { code: "LA", label: "Lao People's Democratic Republic" },
    { code: "LV", label: "Latvia" },
    { code: "LB", label: "Lebanon" },
    { code: "LS", label: "Lesotho" },
    { code: "LR", label: "Liberia" },
    { code: "LY", label: "Libyan Arab Jamahiriya" },
    { code: "LI", label: "Liechtenstein" },
    { code: "LT", label: "Lithuania" },
    { code: "LU", label: "Luxembourg" },
    { code: "MO", label: "Macao" },
    { code: "MK", label: "Macedonia" },
    { code: "MG", label: "Madagascar" },
    { code: "MW", label: "Malawi" },
    { code: "MY", label: "Malaysia" },
    { code: "MV", label: "Maldives" },
    { code: "ML", label: "Mali" },
    { code: "MT", label: "Malta" },
    { code: "MH", label: "Marshall Islands" },
    { code: "MQ", label: "Martinique" },
    { code: "MR", label: "Mauritania" },
    { code: "MU", label: "Mauritius" },
    { code: "YT", label: "Mayotte" },
    { code: "MX", label: "Mexico" },
    { code: "FM", label: "Micronesia}, Federated States Of" },
    { code: "MD", label: "Moldova" },
    { code: "MC", label: "Monaco" },
    { code: "MN", label: "Mongolia" },
    { code: "ME", label: "Montenegro" },
    { code: "MS", label: "Montserrat" },
    { code: "MA", label: "Morocco" },
    { code: "MZ", label: "Mozambique" },
    { code: "MM", label: "Myanmar" },
    { code: "NA", label: "Namibia" },
    { code: "NR", label: "Nauru" },
    { code: "NP", label: "Nepal" },
    { code: "NL", label: "Netherlands" },
    { code: "AN", label: "Netherlands Antilles" },
    { code: "NC", label: "New Caledonia" },
    { code: "NZ", label: "New Zealand" },
    { code: "NI", label: "Nicaragua" },
    { code: "NE", label: "Niger" },
    { code: "NG", label: "Nigeria" },
    { code: "NU", label: "Niue" },
    { code: "NF", label: "Norfolk Island" },
    { code: "MP", label: "Northern Mariana Islands" },
    { code: "NO", label: "Norway" },
    { code: "OM", label: "Oman" },
    { code: "PK", label: "Pakistan" },
    { code: "PW", label: "Palau" },
    { code: "PS", label: "Palestinian Territory}, Occupied" },
    { code: "PA", label: "Panama" },
    { code: "PG", label: "Papua New Guinea" },
    { code: "PY", label: "Paraguay" },
    { code: "PE", label: "Peru" },
    { code: "PH", label: "Philippines" },
    { code: "PN", label: "Pitcairn" },
    { code: "PL", label: "Poland" },
    { code: "PT", label: "Portugal" },
    { code: "PR", label: "Puerto Rico" },
    { code: "QA", label: "Qatar" },
    { code: "RE", label: "Reunion" },
    { code: "RO", label: "Romania" },
    { code: "RW", label: "Rwanda" },
    { code: "BL", label: "Saint Barthelemy" },
    { code: "SH", label: "Saint Helena" },
    { code: "KN", label: "Saint Kitts And Nevis" },
    { code: "LC", label: "Saint Lucia" },
    { code: "MF", label: "Saint Martin" },
    { code: "PM", label: "Saint Pierre And Miquelon" },
    { code: "VC", label: "Saint Vincent And Grenadines" },
    { code: "WS", label: "Samoa" },
    { code: "SM", label: "San Marino" },
    { code: "ST", label: "Sao Tome And Principe" },
    { code: "SA", label: "Saudi Arabia" },
    { code: "SN", label: "Senegal" },
    { code: "RS", label: "Serbia" },
    { code: "SC", label: "Seychelles" },
    { code: "SL", label: "Sierra Leone" },
    { code: "SG", label: "Singapore" },
    { code: "SK", label: "Slovakia" },
    { code: "SI", label: "Slovenia" },
    { code: "SB", label: "Solomon Islands" },
    { code: "ZA", label: "South Africa" },
    { code: "GS", label: "South Georgia And Sandwich Isl." },
    { code: "ES", label: "Spain" },
    { code: "LK", label: "Sri Lanka" },
    { code: "SD", label: "Sudan" },
    { code: "SR", label: "Suriname" },
    { code: "SJ", label: "Svalbard And Jan Mayen" },
    { code: "SZ", label: "Swaziland" },
    { code: "SE", label: "Sweden" },
    { code: "CH", label: "Switzerland" },
    { code: "TW", label: "Taiwan" },
    { code: "TJ", label: "Tajikistan" },
    { code: "TZ", label: "Tanzania" },
    { code: "TH", label: "Thailand" },
    { code: "TL", label: "Timor-Leste" },
    { code: "TG", label: "Togo" },
    { code: "TK", label: "Tokelau" },
    { code: "TO", label: "Tonga" },
    { code: "TT", label: "Trinidad And Tobago" },
    { code: "TN", label: "Tunisia" },
    { code: "TR", label: "Turkey" },
    { code: "TM", label: "Turkmenistan" },
    { code: "TC", label: "Turks And Caicos Islands" },
    { code: "TV", label: "Tuvalu" },
    { code: "UG", label: "Uganda" },
    { code: "UA", label: "Ukraine" },
    { code: "AE", label: "United Arab Emirates" },
    { code: "GB", label: "United Kingdom" },
    { code: "US", label: "United States" },
    { code: "UM", label: "United States Outlying Islands" },
    { code: "UY", label: "Uruguay" },
    { code: "UZ", label: "Uzbekistan" },
    { code: "VU", label: "Vanuatu" },
    { code: "VE", label: "Venezuela" },
    { code: "VN", label: "Viet Nam" },
    { code: "VG", label: "Virgin Islands}, British" },
    { code: "VI", label: "Virgin Islands}, U.S." },
    { code: "WF", label: "Wallis And Futuna" },
    { code: "EH", label: "Western Sahara" },
    { code: "ZM", label: "Zambia" },
    { code: "ZW", label: "Zimbabwe" },
]);
const checkout = ref({
    customer_id: null,
    billing_details: {
        name: "",
        surname: "",
        country: "",
        address: "",
        number: "",
        city: "",
        zip: "",
    },
});
const checkoutCart = ref({
    items: [],
    total: 0,
});
const platforms = ref(['ctrader', 'match-trader', 'tradelocker']);
const fidelityPointsToUse = ref(0);

const paymentMethodName = computed(() => {
    switch (paymentMethod.value) {
        case "solidgate":
            return "Credit or Debit Card (Solidgate)";
        case "cryptocurrency":
            return "Cryptocurrency (Coinbase)";
        case "card-processor":
            return "Credit or Debit Card (Paragon Processor)";
        case "confirmo":
            return "Cryptocurrency (Confirmo)";
    }
});

const coupon = ref({
    code: "",
    applied: false,
    originalAmount: 0,
    message: "",
    originalCoupon: null
});

const verifyCoupon = async () => {
    loading.value = true;

    const checkedCoupon = await checkCoupon(coupon.value.code);
    coupon.value.message = checkedCoupon.reason;
    if (checkedCoupon.isValid) {
        fidelityPointsToUse.value = 0;
        checkoutCart.value.total = checkoutCart.value.items?.reduce((acc, item) => acc + item?.price, 0);

        coupon.value.applied = true;
        coupon.value.originalAmount = checkoutCart.value.total;

        checkoutCart.value.total -=
           (checkedCoupon.coupon.type === "fixed" || checkedCoupon.coupon.type === 'amount')
                ? checkedCoupon.coupon.value
                : checkoutCart.value.total * (checkedCoupon.coupon.value / 100);
        coupon.value.originalCoupon = checkedCoupon.coupon;

        const canUseFidelity = coupon.value.originalCoupon.hasOwnProperty('canUseFidelity')
            ? coupon.value.originalCoupon.canUseFidelity
            : true;
        if (!canUseFidelity) {
            disableFidelity();
        }
    }

    loading.value = false;
};


const applyCoupon = async () => {
    if (!coupon.value.code) {
        return;
    }

    await verifyCoupon();

    loading.value = true;
    await addCoupon(coupon.value.code);
    loading.value = false;
};

const termsAgreed = ref(false);
const platformAgree = ref(false);
const rulesAgree = ref(false);
const paymentError = ref("");
const createdOrder = ref({ total: 0, OFPPaymentId: "" });
const removeItemFromCart = async () => {
    loadingRemove.value = true;
    await clean();

    const brevoPayload = {
        order_id: "",
        ORDERID: "",
        ITEM: checkoutCart.value.items[0].versionId,
        PRICE: `${checkoutCart.value.total}`,
        CURR: checkoutCart.value.items[0].currency,
        SIZE: new Intl.NumberFormat("en-US").format(
            checkoutCart.value.items[0].size
        ),
        NAME: checkoutCart.value.items[0].versionName,
    };
    trackKlaviyoEvent(
        "cart_deleted",
        {
            email: user.value.email,
            FIRSTNAME: user.value.firstName,
            LASTNAME: user.value.surname,
        },
        brevoPayload
    );
    trackKlaviyoEvent(
        "article_removed",
        {
            email: user.value.email,
            FIRSTNAME: user.value.firstName,
            LASTNAME: user.value.surname,
        },
        brevoPayload
    );
    loadingRemove.value = false;

    if (checkoutCart.value.items.length === 1) {
        router.replace("/buy-account");
    }
};

// function to disable using fidelity points
const disableFidelity = () => {
    fidelityPointsToUse.value = 0;
    usableFidelityPoints = false;
};
const confirm = async () => {
    if (!termsAgreed.value || !platformAgree.value || !rulesAgree.value) {
        return;
    }

    loadingPayment.value = true;

    const ref_origin =
        route.query.ref_origin ||
        cookies.get("ref_origin") ||
        sessionStorage.getItem("ref_origin") ||
        localStorage.getItem("ref_origin") ||
        null;
    const utm_campaign =
        route.query.utm_campaign || cookies.get("utm_campaign") || null;
    const utm_id = route.query.utm_id || cookies.get("utm_id") || null;
    const utm_medium =
        route.query.utm_medium || cookies.get("utm_medium") || null;
    const utm_source =
        route.query.utm_source || cookies.get("utm_source") || null;

    checkout.value.billing_details.address = `${checkout.value.billing_details.address} ${checkout.value.billing_details.number}`|| ' ';

    const platform =
        checkoutCart.value && checkoutCart.value.items ?
        checkoutCart.value.items[0].platform_name : null

    try {

        if ( platform) {
           await setPlatformForCart(checkout.value.customer_id, platform)
        }

        const { data } = await confirmOrder({
            ...checkout.value,
            ref_origin,
            marketing: { utm_campaign, utm_id, utm_medium, utm_source },
            affiliate: cookies.get("ofp_aff")
                ? { bannerId: cookies.get("a_bid"), referralId: cookies.get("ofp_aff") }
                : undefined
        });

        if (data._id) {
            const brevoPayload = {
                order_id: data.id,
                ORDERID: data.id,
                ITEM: checkoutCart.value.items[0].versionId,
                PRICE: (data.total || 0).toFixed(2),
                CURR: checkoutCart.value.items[0].currency,
                SIZE: new Intl.NumberFormat("en-US").format(
                    checkoutCart.value.items[0].size
                ),
                NAME: checkoutCart.value.items[0].versionName,
            };
            trackKlaviyoEvent(
                "order_created",
                {
                    email: user.value.email,
                    FIRSTNAME: user.value.firstName,
                    LASTNAME: user.value.surname,
                },
                brevoPayload
            );

            cookies.remove("utm_campaign");
            cookies.remove("utm_id");
            cookies.remove("utm_medium");
            cookies.remove("utm_source");
            cookies.remove("ref_origin");
            cookies.remove("ofp_aff");
            sessionStorage.removeItem("ref_origin");
            localStorage.removeItem("ref_origin");

            createdOrder.value = { ...data, OFPPaymentId: "" };
            await pay();
        } else {
            paymentError.value =
                data.name === "TotalAllocationError" ||
                    data.name === "ModelAllocationError"
                    ? data.message
                    : "An error occurred while processing your payment. Please try again later.";
            loadingPayment.value = false;

            popup({
                settings: { disableClose: true },
                heading: i18n.t("checkout-error-heading"),
                image: "alert.svg",
                body: paymentError.value,
                buttonText: i18n.t("retry"),
            });
        }
    } catch (e) {
        console.error(e);
        notify({
            title: "Error",
            text: "An error occurred while processing your payment. Please try again later.",
            type: "error",
        });
    }
};
const pay = async () => {
    loadingPayment.value = true;

    const payPayload = {
        order_id: createdOrder.value.id,
        use_points: fidelityPointsToUse.value,
    };
    switch (paymentMethod.value) {
        case "solidgate":
            payPayload.payment_type = "solidgate";
            break;
        case "cryptocurrency":
            payPayload.payment_type = "coinbase";
            break;
        case "card-processor":
            payPayload.payment_type = "paragon";
            break;
        case "confirmo":
            payPayload.payment_type = "confirmo";
            break;
    }

    try {
        const { data } = await payOrder(payPayload);
        console.log('data', data)
        createdOrder.value = { ...createdOrder.value, ...data };
        await nextTick();
        if (data === "Not enough points") {
            console.debug("Not enough points");
            // TODO: Show error message and handle rewards
            return;
        }

        $gtm.trackEvent({
            event: "begin_checkout",
            value: {
                ORDERID: data.id,
                ITEM: data.id,
                PRICE: data.total || 0,
                CURR: checkoutCart.value.items[0].currency,
                SIZE: new Intl.NumberFormat("en-US").format(
                    checkoutCart.value.items[0].size
                ),
                NAME: checkoutCart.value.items[0].versionName,
            },
        });

        switch (paymentMethod.value) {
            case "solidgate":
                if (data?.paymentIntent) {
                    solidgateData.value = {
                        paymentIntent: data?.paymentIntent,
                        merchant: data?.merchant,
                        signature: data?.signature
                    };
                    showCreditCardModal.value = true;
                }
                break;
            case "cryptocurrency":
            case "card-processor":
            case "confirmo":
                if (data?.url && data?.url.length) {
                    window.location.href = data.url;
                } else {
                    console.log(data);
                    loadingPayment.value = false;
                    notify({
                        title: "Error",
                        text: "An error occurred while processing your payment. Please try again later.",
                        type: "error",
                    });
                }
                break;
        }
    } catch (e) {
        console.error(e);
        loadingPayment.value = false;
        notify({
            title: "Error",
            text: "An error occurred while processing your payment. Please try again later.",
            type: "error",
        });
    }
};
const startVeriff = async () => {
    veriff.value = new Veriff({
        apiKey: '3f684eb9-2d9e-4ff2-8aed-993ee59d4b85',
        parentId: 'veriff-root',
        onSession: function (err, response) {
            if (err) {
                console.error(err);
                return;
            }
            window.open(response.verification.url, '_blank');
        }
    });
    veriff.value.setParams({
        vendorData: user.value?._id,
        person: {
            givenName: user.value?.firstName,
            lastName: user.value?.lastName
        }
    });
    veriff.value.mount({
        submitBtnText: 'Verify Now',
    });
};
const paymentEl = ref(null);
const cartEl = ref(null);

onMounted(async () => {
    if (window.innerWidth < 1000) {
        paymentEl.value.insertBefore(
            cartEl.value,
            paymentEl.value.childNodes[2]
        );
    }
});

watchEffect(async () => {

    if (user.value) {
        checkout.value.customer_id = user.value?._id;

        checkout.value.billing_details.name = user.value?.firstName || " ";
        checkout.value.billing_details.surname =
            user.value?.surname || user.value?.lastName || " ";
        checkout.value.billing_details.country = user.value?.country;
        checkout.value.billing_details.address = (user.value?.address || " ")
            .split(" ")
            .slice(0, -1)
            .join(" ");
        checkout.value.billing_details.number = (user.value?.address || " ")
            .split(" ")
            .pop();
        checkout.value.billing_details.city = user.value?.city || " ";
        checkout.value.billing_details.zip = user.value?.zip || " ";

        loading.value = true;
        await fetchCart();
        await userStore.getFidelityPoints();
        loading.value = false;
    }

    if (cart.value) {
        if (!cart.value.items?.length) {
            router.replace("/buy-account");
            return;
        }

        let { payout_type, type } = cart.value.items[0];
        payout_type = payout_type || "monthly";
        type = type || "classic";
        await fetchItems({ payout_type, type });
        checkoutCart.value.items = cart.value.items
            ?.map((cartItem) => {
                const item = getItemByVersionId(
                    cartItem.itemId,
                    payout_type,
                    type,
                    cartItem.max_daily_drawdown
                );
                const version = cartItem.max_daily_drawdown
                    ? queryVersion(cartItem.itemId, {
                        max_daily_drawdown: cartItem.max_daily_drawdown,
                    })
                    : getVersionById(cartItem.itemId);

                if (!item || !version) {
                    return null;
                }
                let platformName = "";
                switch (cartItem.platform.toLowerCase()) {
                    case "mt5":
                        platformName = "MetaTrader 5";
                        break;
                    case "mt4":
                        platformName = "MetaTrader 4";
                        break;
                    case "ctrader":
                        platformName = "cTrader";
                        break;
                    case "match-trader":
                        platformName = "Match-Trader";
                        break;
                    case "tradelocker":
                        platformName = "Tradelocker";
                        break;
                    default:
                        platformName = "Unknown";
                        break;
                }

                return {
                    name: item.name,
                    price: version.price,
                    currency: version.currency,
                    size: version.size,
                    currency_account: version.currency_account,
                    versionName: version.name,
                    versionId: version.id,
                    platform_name: platformName,
                    max_daily_drawdown: cartItem.max_daily_drawdown,
                    original_price: version.original_price,
                    discount_value: version.discount_value,
                    leverage: item.props.leverage,
                    max_drawdown: item.props.max_drawdown,
                    max_trader_value_score: item.props.max_trader_value_score,
                    payout_type: item.payout_type,
                    profit_split: item.props.profit_split
                };
            })
            .filter(Boolean);
        checkoutCart.value.total =
            checkoutCart.value.items?.reduce((acc, item) => acc + item?.price, 0);

        if (cart.value.coupon) {
            coupon.value.code = cart.value.coupon;
            await verifyCoupon();
        }
    }
    if (user && user.value.kyc !== 'approved') {
        paymentMethod.value = null;
        await startVeriff();
    }
});

const draggingFidelityPoints = () => {
    let cartTotal = checkoutCart.value.items?.reduce((acc, item) => acc + item?.price, 0);

    let couponValue = 0;
    if (coupon.value.applied) {
        couponValue = coupon.value.originalCoupon.type === "fixed"
            ? coupon.value.originalCoupon.value
            : cartTotal * (coupon.value.originalCoupon.value / 100);
        cartTotal -= couponValue;
    }

    let total = cartTotal
        - Math.floor(fidelityPointsToUse.value / 40);

    if (total < 1) {
        const maxPoints = Math.floor((cartTotal - 1) * 40);
        fidelityPointsToUse.value = maxPoints;
    }

    total = cartTotal
        - Math.floor(fidelityPointsToUse.value / 40);

    checkoutCart.value.total = total;
};



const verifyClick = () => {
    document.querySelector('#veriff-submit-btn').click();
};
</script>

<style scoped lang="scss">
.gradient-border-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.gradient-border-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(142.67deg, #FFB7A7 8.58%, #FB8469 25.6%, #FB8469 35.86%, #7D5FFC 66.99%, #FB8469 98.31%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
}

.gradient-border-btn:hover {
  opacity: 0.9;
}
option{
    background-color: #0e0927 !important;
    color: white;
}
.checkout {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    height: 100%;

    .form-control {
        border: 1px solid rgb(255, 255, 255, 0.5);
        background: transparent;
    }
}
.container-fluid{
    padding-bottom: 100px;

    *:not(i) {
        font-family: Heebo;
    }
}

.checkout-warning {
    align-items: center;
    background: rgba(217, 217, 217, 0.1);
    border: 1px solid rgba(181, 174, 174, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    width: 100%;

    b {
        font-weight: 700;
    }

    p {
        text-align: center;
    }

    &__btn {
        border-radius: 8px;
        color: white;
        border: 1px solid #7D5FFC;
        margin-top: 12px;
        padding: 12px 26px;

        &:hover {
            background: rgba(125, 95, 252, 0.12);
        }
    }

    .veriff-content {
        height: 0;
        overflow: hidden;
    }
}

.checkout-payment {
    width: calc(50% - 20px);

    @media (max-width: 768px) {
        width: 100%;
    }

    .checkout-payment__title {
        font-size: 20px;
        font-weight: 400;

        color: white;

        line-height: 35.25px;
    }

    .checkout-payment__methods {
        width: 100%;

        margin-bottom: 40px;
        padding: 0;

        li {
            display: flex;
            justify-content: space-between;

            @media (max-width: 1000px) {
                flex-direction: column;
            }

            padding: 24px 16px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.25);

            &.checkout-payment__disabled {
                filter: brightness(0.5) grayscale(1);
            }

            .checkout-payment__label {
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: 24px;

                input[type="radio"] {
                    -webkit-appearance: none;
                    appearance: none;
                    cursor: pointer;

                    &::before {
                        content: "";

                        display: block;
                        width: 24px;
                        height: 24px;

                        border-radius: 50px;
                        outline: 2px solid rgba(255, 255, 255, 0.25);

                        transition: all 0.2s ease-in-out;
                    }

                    &:checked::before {
                        background: rgba(255, 104, 68);

                        outline: 2px solid rgb(255, 104, 68);
                        border: 3px solid #3b3b3b;
                    }
                }
            }

            .checkout-payment__logo {
                height: 13px;

                &.coinbase {
                    height: 16px;
                }

                @media (max-width: 768px) {
                    align-self: center;
                }
            }
        }
    }

    .checkout-payment__billing-details {
        @media (max-width: 768px) {
            margin-bottom: 8em;
        }

        label {
            font-size: 12px;
            font-weight: 400;
            //font-family: Heebo;

            color: white;

            line-height: 18px;

            margin-bottom: 8px;
        }
    }
}

.checkout-cart {
    width: calc(50% - 20px);

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 24px;
    }

    .checkout-cart__box {
        border-radius: 8px;
        border: 1px solid rgb(84, 90, 101);

        padding: 32px;

        display: flex;
        flex-direction: column;
        gap: 24px;

        .checkout-cart__box-title {
            font-size: 20px;
            font-weight: 500;
            //font-family: Heebo;

            color: white;

            line-height: 30px;
        }

        .checkout-cart__items {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                display: flex;
                flex-direction: row;
                align-items: center;

                width: 100%;

                .checkout-cart__items-quantity {
                    font-size: 14px;
                    font-weight: 400;

                    line-height: 20px;

                    color: rgb(255, 104, 68);

                    margin-right: 14px;

                    &::after {
                        content: "x";
                    }
                }

                .checkout-cart__items-name {
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 16px;
                        font-weight: 400;

                        line-height: 20px;

                        color: white;

                        margin-bottom: 8px;
                    }

                    small {
                        text-transform: capitalize;
                        font-size: 14px;
                        font-weight: 400;

                        line-height: 20px;

                        color: rgb(114, 120, 131);
                    }
                }

                .checkout-cart-items-amount {
                    font-size: 14px;
                    font-weight: 500;

                    line-height: 20px;

                    color: white;

                    margin-left: auto;
                }
            }
        }

        .checkout-cart__credentials {
            background: rgba(255, 255, 255, 0.05);

            width: 100%;
            padding: 16px;

            border-radius: 8px;

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;

            i {
                font-size: 24px;
                color: rgb(255, 104, 68);
            }

            label {
                font-size: 16px;
                font-weight: 400;

                line-height: 24px;

                color: white;
            }
        }

        .checkout-cart__coupon {
            .btn {
                background: transparent;
                color: white;
                border: 1px solid white;
                margin-left: 8px;
            }

            .checkout-cart__coupon-applied {
                background: rgba(255, 255, 255, 0.05);

                width: 100%;
                padding: 16px;

                border-radius: 8px;

                span {
                    font-size: 16px;
                    font-weight: 400;

                    line-height: 24px;

                    color: white;
                }
            }
        }

        .checkout-cart__total {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            width: 100%;

            border-top: 1px solid rgb(84, 90, 101);

            padding: 16px 0px;

            .checkout-cart__total-title {
                font-size: 14px;
                font-weight: 400;

                line-height: 20px;

                color: rgb(185, 185, 185);
            }

            .checkout-cart__total-amount-box {
                display: flex;
                flex-direction: column;
                text-align: center;

                .checkout-cart__total-amount {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;

                    letter-spacing: 0.02em;
                }

                .checkout-cart__total-amount-original {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;

                    color: rgb(185, 185, 185);

                    position: relative;

                    &::before {
                        content: " ";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(-15deg);
                        width: 100%;
                        height: 1px;
                        background: red;
                    }
                }
            }
        }

        .checkout-cart__terms {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            label {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;

                input[type="checkbox"] {
                    accent-color: rgb(255, 104, 68);

                    width: 20px;
                    height: 20px;

                    transition: all 0.2s ease-in-out;
                }

                span {
                    flex: 1;

                    font-size: 14px;
                    font-weight: 400;

                    line-height: 21px;
                }
            }
        }

        .checkout-cart_fidelity-points {
            display: flex;
            flex-direction: column;

            padding: 16px 0px;

            border-top: 1px solid rgb(84, 90, 101);

            font-size: .875rem;
            font-weight: 400;

            #fidelityPointsRange {
                accent-color: rgb(255, 104, 68);
            }

            .checkout-cart_fidelity-points_counter {
                width: 100%;

                display: flex;
                justify-content: space-between;
            }

            .checkout-cart_fidelity-discount {
                width: 100;
                text-align: right;
            }
        }

        .checkout-cart__pay {
            border-radius: 8px;
            padding: 10px 16px;

            font-size: 16px;
            font-weight: 500;

            line-height: 20px;
        }
    }
}

@media (max-width: 1023px) {
    .d-flex {
        display: flex !important;
    }
}

.text-discount {
    color: #6cf542;
}


.d-sm-none {
    display: none !important;
}

.d-md-none {
    display: none !important;
}

.d-md-block {
    display: block !important;
}

@media (max-width: 768px) {
    .pt-sm-2 {
        padding-top: 2rem;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-md-block {
        display: none !important;
    }
}
</style>
