<template>
    <div class="w-100">

        <div class="row gap-4 justify-content-center">
            <div class="col selector-box">

                <h4>{{ $t('buyaccount-type-of-payout') }}</h4>
                <div class="row justify-content-between gap-2 text-center">
                    <a class="col button" v-for="(item, index) of payout_frequencies" :key="index" :class="{ active: modelValue.payout_frequency === item }"
                            aria-current="page" :id="`nav-${item}-tab`" data-bs-toggle="tab" type="button" role="tab"
                            aria-selected="true" href="#" @click="onUpdatePayoutFrequency(item)">
                            {{ formatPayoutFrequency(item) }}
                        </a>
                </div>
            </div>
            <div class="col selector-box">

                <h4>{{ $t('buyaccount-currency') }}</h4>
                <div class="row justify-content-between gap-2 text-center">
                    <a class="col button" v-for="(item, index) of currencies" :key="index" :class="{ active: modelValue.currency === item }"
                            aria-current="page" :id="`nav-${item}-tab`" data-bs-toggle="tab" type="button" role="tab"
                            aria-selected="true" href="#" @click="onUpdateCurrency(item)">
                            {{ formatCurrency(item) }}
                        </a>
                </div>
            </div>
        </div>
        <div class="row mt-4 gap-4 justify-content-center">
            <div class="col selector-box2">
                <h4>{{ $t('buyaccount-account-size') }}</h4>
                <div class="slider-wrapper">
                    <Slider v-model="capital" :data="capitals.map(e => `${e / 1000}K`)" adsorb contained :marks="true"
                        tooltip="hover" />
                </div>
            </div>

            <div class="col selector-box2">
                <h4>{{ $t('max_daily_drawdown') }}</h4>
                <div class="slider-wrapper">
                    <Slider v-model="drawdown" :data="drawdowns.map(e => `${e}%`)" adsorb contained marks
                        tooltip="hover" />
                </div>
            </div>

            <div class="col selector-box2">
                <h4>{{ $t('profit_split') }}</h4>
                <div class="slider-wrapper">
                    <Slider v-model="profit_split" :data="profit_splits.map(e => `${e}%`)" adsorb contained marks
                        tooltip="hover" />
                </div>
            </div>
        </div>


    </div>
</template>

<script setup>
import { defineEmits, defineProps, unref, watch, computed } from "vue";
import Slider from "./Slider.vue";
import { formatCurrency, formatPayoutFrequency, formatPlatform, platformImage } from "./utils/format";
const props = defineProps({

    modelValue: {
        required: true,
    },
    capitals: Array,
    drawdowns: Array,
    profit_splits: Array,
    payout_frequencies: Array,
    currencies: Array,
})
const capital = computed({
    get: () => `${props.modelValue.capital / 1000}K`,
    set: (val) => emit('update:modelValue', { ...props.modelValue, ...{ capital: parseInt(val.replace('K', '') * 1000) } })
})

const drawdown = computed({
    get: () => `${props.modelValue.drawdown}%`,
    set: (val) => emit('update:modelValue', { ...props.modelValue, ...{ drawdown: parseInt(val.replace('%', '')) } })
})

const profit_split = computed({
    get: () => `${props.modelValue.profit_split}%`,
    set: (val) => emit('update:modelValue', { ...props.modelValue, ...{ profit_split: parseInt(val.replace('%', '')) } })
})

const emit = defineEmits([
    'update:modelValue',
])

const onUpdatePayoutFrequency = (val) => {
    emit('update:modelValue', { ...unref(props.modelValue), ...{ payout_frequency: val } })
}

const onUpdateCurrency = (val) => {
    emit('update:modelValue', { ...unref(props.modelValue), ...{ currency: val } })
}

</script>

<style scoped>
.josefin-sans {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.box {
    max-width: fit-content;
    min-width: 390px;
    color: white;
    padding-left: 24px;
    padding-right: 24px;
}

.selector-box {
    width: 390px;
    min-width: 390px;
    max-width: 390px;
    min-height: 130px;
    padding: 24px;
    text-align: left;
    color: white;
}

.selector-box2 {
    width: 390px;
    min-width: 390px;
    max-width: 390px;
    height: 144px;
    padding: 24px;
    text-align: left;
    color: white;
}

.selector {
    padding-top: 40px;
    padding-bottom: 20px;
}

h4 {
    font-size: 24px;
    line-height: 24px;
    font-weight: bolder;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    padding-bottom: 10px;
}

.slider-wrapper {
    padding-top: 36px;
    padding-left: 4px;
    padding-right: 4px;
}

.button {
    text-align: center;
    white-space: nowrap;
    padding-left: 10px; 
    padding-right: 10px;
   
    border-radius: 18px;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    max-width: 109px;
    min-width: fit-content;
    line-height: 34px;
    color: white;
    background: rgba(255, 255, 255, 0.05);

    &.active {
       
        background: rgb(255, 104, 68, 1);
    }
}
.options-type {
    gap: 8px;

    .nav-item {

        text-align: center;
        white-space: nowrap;
        padding-left: 10px; 
        padding-right: 10px;
        min-width: fit-content;
        .nav-link {
            border-radius: 18px;
            font-family: "Josefin Sans", sans-serif;
            font-optical-sizing: auto;
            font-weight: 600;
            font-style: normal;
            font-size: 14px;
            line-height: 20px;
            max-width: 96px;
            
            color: rgba(255, 255, 255, 0.5);
            background: rgba(84, 90, 101, 0.5);

            &.active {
                color: white;
                background: rgb(255, 104, 68, 1);
            }
        }
    }
}

.platform-list {
    margin-left: -20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.platform {
    width: 163px;
    height: 140px;
}

.platform-content {
    border: 1px solid #686F7C;
    border-radius: 8px;
    width: 163px;
    height: 100px;
    display: flex;
}

.platform-image {
    width: 64px !important;
    height: 64px !important;
    margin: auto;
}

.platform-label {
    padding-top: 10px;
    text-align: center;
    font-size: 16px;
}

.platform-selected {
    background: rgba(255, 104, 68, 0.12);
}
</style>
