<template>
    <nav class="sidebar-nav pt-0" >
        <ul id="sidebarnav" class="mt-3 pt-3" >
            <li ><a  class="btn btn-outline-primary waves-effect waves-dark border border-primary text-white mx-3 text-center p-2 animated-button"
                href="/buy-account" style="border-radius: 10px;">{{ $t('navbar-buy') }}</a></li>
            <li> <a class=" waves-effect waves-dark" href="/home"
                    aria-expanded="false"><font-awesome-icon icon="fa-solid fa-house" class="m10"/><span class="hide-menu">{{ $t('sidebar-home') }}
                                    </span></a>
            </li>

            <li> <a class=" waves-effect waves-dark nav-link"  href="/calendar"
                    aria-expanded="false"><font-awesome-icon icon="fa-solid fa-calendar" class="m10"/><span class="hide-menu">
                        {{ $t('sidebar-tradingCalendar') }}
                </span></a>
            </li>
            <li> <a class=" waves-effect waves-dark nav-link"  href="https://overviewforex.com/tools/" target="_blank"
                    aria-expanded="false"><i class="mdi mdi-wrench colorIcon" ></i><span class="hide-menu">
                        {{ $t('sidebar-Tools') }}
                </span></a>
            </li>
             <li> <a  class=" waves-effect waves-dark nav-link" href="/leaderboard"
                    aria-expanded="false"><font-awesome-icon icon="fa-solid fa-trophy" class="m10" /><span
                class="hide-menu">{{ $t('sidebar-Lead') }}</span></a>
            </li>
            <li> <a class=" waves-effect waves-dark nav-link"  href="/orders"
                    aria-expanded="false"><font-awesome-icon icon="fa-solid fa-cart-shopping" class="m10"/><span
                class="hide-menu">{{ $t('sidebar-Orders') }}</span></a>
            </li>
            <li> <a class=" waves-effect waves-dark nav-link"  href="/certificate"
                    aria-expanded="false"><font-awesome-icon icon="fa-solid fa-certificate " class="m10"/><span
                class="hide-menu">{{ $t('sidebar-Certificati') }}</span></a>
            </li>
            <li> <a class=" waves-effect waves-dark" href="/news"
                    aria-expanded="false"><font-awesome-icon icon="fa-solid fa-newspaper" class="m10"/><span
                class="hide-menu">{{ $t('sidebar-News') }}</span></a>
            </li>
            <li> <a class=" waves-effect waves-dark" href="/economicCalendar"
                    aria-expanded="false"><font-awesome-icon icon="fa-solid fa-calendar" class="m10"/><span
                class="hide-menu">{{ $t('sidebar-Calendar') }}</span></a>
            </li>
            <li> <a class=" waves-effect waves-dark nav-link"  href="https://overviewforex.com/course/" target="_blank"
                    aria-expanded="false"><font-awesome-icon icon="fa-solid fa-book" class="m10"/><span class="hide-menu">
                        {{ $t('sidebar-Educ') }}
                </span></a>
            </li>
            <li> <a class=" waves-effect waves-dark " target="_blank" href="https://ofpfunding.com/mental-coach"
                    aria-expanded="false"><font-awesome-icon icon="fa-solid fa-chalkboard-teacher" class="m10"/> <span class="hide-menu">{{ $t('sidebar-Coach') }}</span></a>
            </li>
            <li class="nav-small-cap py-1"><hr></li>
            <li> <a class=" waves-effect waves-dark" href="https://ofpfunding.com/" target="_blank"
                    aria-expanded="false"><font-awesome-icon icon="fa-solid fa-globe" class="m10"/><span class="hide-menu">{{ $t('sidebar-Web') }}</span></a>
            </li>
            <li> <a class=" waves-effect waves-dark pe-0" href="https://discord.com/channels/916660711789322300/916660712259072043" target="_blank"
                    aria-expanded="false"><i class="mdi mdi-discord colorIcon" ></i><span class="hide-menu m-0">{{ $t('sidebar-Discord') }}</span></a>
            </li>
            <li> <a class=" waves-effect waves-dark pe-0" href="https://ofpfunding.com/earn-points-and-rewards-at-ofp-trading-made-simple/ofp-affiliate-program/" target="_blank"
                    aria-expanded="false"><i class="mdi mdi-link-variant colorIcon" ></i><span class="hide-menu m-0">{{ $t('sidebar-Affiliate') }}</span></a>
            </li>
            <li class="nav-small-cap py-1"> <TrustPilot/></li>
            <li @click="rank()"> <a class=" waves-effect waves-dark pe-0" href="#"
                    aria-expanded="false"> <i class="mdi mdi-pencil-box-outline "></i><span class="hide-menu m-0">{{ $t('sidebar-Rank') }}</span></a>
            </li>
        </ul>

    </nav>
</template>

<script>
import TrustPilot from './TrustPilot';
export default {
    name: "Sidebar",
    data(){
        return{
            client:{},
            accountLen:0,
            profileLetter:'',
            image:'',
            rankPopup:false,
        }
    },
    components:{
        TrustPilot
    },
    methods:{
        rank(){
            this.rankPopup=true
            this.$emit('rank', this.rankPopup)
        },
        Logout(){
            this.$cookies.keys().forEach(key =>{
                            if(key!=='emailAccount')
                            this.$cookies.remove(key)
                        });
                        window.location.replace('/')
        }
    },
}
</script>

<style scoped>
.m10{
    margin-right: 10px;
}
.m5{
    margin-right: 5px;
}
.user-img{
    border-radius: 100%;
    width: 100px!important;
    height: 100px;

}
.iconaFoto{
    display: none;
    opacity: 100%;
}

.box-icona:hover .iconaFoto{
    display: block;
}
.colorIcon{
    color: #8d97ad;
}
.opacitaDisable{
    opacity: 30%;
}
.animated-button {

animation: stretchRotate 3s infinite;
}

@keyframes stretchRotate {
0% {
  transform: scaleX(1) rotate(0deg);
}
25% {
  transform: scaleX(1.1) rotate(5deg);
}
50% {
  transform: scaleX(1) rotate(0deg);
}
75% {
  transform: scaleX(1.1) rotate(-5deg);
}
100% {
  transform: scaleX(1) rotate(0deg);
}
}
</style>
