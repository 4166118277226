<template>

    <body  class="skin-default card-no-border" >
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper">
        <div v-if="loading" class="position-absolute top-50 start-50 translate-middle fw-bold text-uppercase text-primary">Loading</div>
        <div v-if="!loading" class="row h-100 justify-content-start">
            <toastMessageVerify v-if="showToast" :email="email" :messageToast="message" :subMessToast="subMessage"/>
            <div class="w-100 d-flex">
              <div class="d-flex flex-column col-12 col-lg-7">
                <div class="mt-2 ms-2 mb-5 mb-lg-0 mt-lg-5 ms-lg-5"> <img src="/assets/images/logoScritte.png" ></div>
                    <div class="w-100 d-flex flex-column align-items-center my-auto " >
                    <div class="w-100" >
                        <div v-if="error" class="text-center pb-5 ">
                            <h3 class="text-danger fw-bold">{{ texterror }}</h3>
                        </div>
                        <form  class="form-horizontal form-material d-flex flex-column gap-5 col-11 col-lg-7 mx-2 mx-lg-auto" id="loginform" @submit.prevent="logIn()" >
                            <div class="m-b-20">
                                <h1 class="text-start fw-bold text-white">{{ $t('SingIn') }}</h1>
                                <p>{{ $t('Welcome') }}</p>
                            </div>
                            <div class="form-group ">
                                <div class="col-xs-12">
                                    <label class="text-white mb-1">{{ $t('Email') }}</label>
                                    <input class="form-control border border-1 border-white rounded" type="email" v-model="email" required placeholder="Email"> </div>
                            </div>
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="text-white mb-1">{{ $t('Password') }}</label>
                                    <div class="d-flex border border-1 border-white rounded pe-2 displayFlex">
                                        <input class="form-control" :type="typePsw?'password':'text'" v-model="password" required placeholder="Password">
                                        <i class="icons-Eye-Visible my-auto" style="justify-self:  end; align-self: flex-end;" @click="changeTipePsw()" ></i>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <div class="d-flex no-block align-items-center justify-content-between">
                                        <div class="d-flex align-items-center gap-2 displayFlex">
                                            <input type="checkbox" name="" id="">
                                            <label for="">{{ $t('Credentilas-rem') }}</label>
                                        </div>
                                        <div class="text-primary">
                                            <a :href="'/reset/'+langParam" id="to-recover" class="text-primary fw-bold"><i class="fas fa-lock m-r-5"></i>{{ $t('Psw-forgot') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="p-b-20">
                                    <button class="btn w-100 btn-lg btn-primary rounded text-white"
                                    >{{ $t('LogIn') }}</button>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-12 text-center fw-bold">
                                    {{ $t('NoClients') }} <router-link :to="'/register/'+langParam" class="text-primary m-l-5"><b>{{ $t('SingUp') }}</b></router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
              </div>
                <div class="col-5 d-none d-lg-flex loginDx position-relative">
                    <div class="position-absolute posizioneImg w-100 rounded">
                        <img class="img-fluid" src="/assets/images/imgLoginRegister.jpg" alt="">
                    </div>
                    <div class="text-center">
                        <h1>{{ $t('SingIn') }}</h1>
                        <h6>{{ $t('Welcome') }}</h6>
                    </div>
                </div>
            </div>
        </div>

    </section>

</body>
</template>

<script>
import toastMessageVerify from "../components/statics/toastMessageVerify.vue"
import Spinner from "../components/Spinner"
    export default {
        components:{
        Spinner,toastMessageVerify
    },
        data() {
            return {
                email: "",
                password: "",
                error: false,
                texterror:'',
                typePsw:true,
                loading:true,
                showToast:false,
                message:'',
                subMessage:'',
                langParam:'',
                verifyEmail:{
                    en:'You need to verify your email address',
                    it:"E' necessario verificare l'email",
                    pt:'Você precisa verificar seu endereço de e-mail',
                    es:'Necesitas verificar tu dirección de correo electrónico.'
                },
                checkEmail:{
                    en:'Check your email:',
                    it:"Controlla la tua email:",
                    pt:'Verifique seu e-mail:',
                    es:'Consultar su correo electrónico:'
                },
                wrongPsw:{
                    en:'Wrong Password',
                    it:"Password errata",
                    pt:'Senha incorreta',
                    es:'Contraseña incorrecta'
                },
                NoAuth:{
                    en:'User is not authorized to perform login',
                    it:"L'utente non è autorizzato a eseguire il login",
                    pt:'O usuário não está autorizado a realizar login',
                    es:'El usuario no está autorizado para realizar inicio de sesión'
                },
                NoFound:{
                    en:'User Not Found',
                    it:"Utente non trovato",
                    pt:'Usuário não encontrado',
                    es:'Usuario no encontrado'
                }
            }
        },
        mounted(){
            this.checkCredentials(),
            this.checkUrlLang()
        },
        methods: {
            checkUrlLang(){
               this.langParam = this.$route.params.lang;
                if (this.langParam) {
                    this.$i18n.locale=this.langParam
                } else {
                    this.langParam=''
                    this.$i18n.locale='en'
                }
            },
            checkCredentials(){
                if(this.$cookies.get('login')){
                    this.email=this.$cookies.get('emailAccount')
                    axios.get(`${this.$baseUrl}/customers/getCustomerInfo?email=${this.email}`).then(({data})=>{
                        if(data?.verified){
                            window.location.replace('/home')
                        }else{
                            this.showToast=true
                            this.message=this.verifyEmail[this.langParam||'en']
                            this.subMessage= this.checkEmail[this.langParam||'en']+ this.email
                            this.loading=false
                        }
                    })
                }else{
                    if( this.$cookies.get('emailAccount')){
                        this.loading=false
                        this.email=this.$cookies.get('emailAccount')
                    }else{
                        this.loading=false
                    }
                }

            },
            changeTipePsw(){
                this.typePsw=!this.typePsw
            },
            logIn(){
                axios.post(`${this.$baseUrl}/customers/login`,{
                    "email":this.email,
                    "password":this.password
                }).then((response) => {
                    console.log(response)
                    if(response.data.status==200){
                        try {
                            axios.get(`${this.$baseUrl}/customers/getCustomerInfo?email=${this.email}`).then(({data})=>{
                                if(data.verified){
                                    this.error=false
                                    this.$cookies.set('login', true, Infinity)
                                    this.$cookies.set('emailAccount',this.email,Infinity)
                                    window.Laravel.isLoggedin
                                    window.location.replace('/home')
                                }else{
                                    this.$notify({
                                        title: this.verifyEmail[this.langParam||'en'],
                                        text: this.checkEmail[this.langParam||'en']+ this.email,
                                        type: 'warn'
                                    })
                                }
                            })
                        } catch (error) {

                        }
                    }else{
                        this.error=true
                        if(response.data.status==401){
                            this.$notify({text: this.wrongPsw[this.langParam||'en'], type: 'warn'})
                        }else if(response.data.status==400){
                            this.$notify({text: this.NoFound[this.langParam||'en'], type: 'warn'})
                        }else if(response.data.status == 403){
                            this.$notify({
                                title: this.verifyEmail[this.langParam||'en'],
                                text: this.checkEmail[this.langParam||'en']+ this.email,
                                type: 'warn'
                            })
                        }else{
                            this.$notify({text: this.NoAuth[this.langParam||'en'], type: 'warn'});
                        }
                    }
                })
            .catch((error) => {
            console.error(error);
            });
            }
        },
    }
</script>
<style scoped>
.displayFlex{
    display: flex!important;
}
.form-group{
    margin-bottom:20px ;
}
input{
    background-color: transparent!important;
    padding: 10px!important;
}
.loginDx{
    background-color:rgba(255, 255, 255,0.1);
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 200px;
}
.posizioneImg{
    transform: translate(-15%,-35%);
}
</style>
