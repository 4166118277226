<template>
    <Spinner v-if="loading" />
    <div id="main-wrapper" :style="loading ? 'opacity:20%' : ''">
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar" :class="{ 'opacity-25': loading }">
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar" :class="{ 'opacity-25': loading }">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <Sidebar @rank="watchRank" />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->

        <div
            class="page-wrapper"
            :class="showRank || loading ? 'opacity-25' : ''"
        >
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid pb-sm-5" v-if="!loading">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!--- Start Content --->

                <div class="d-block card-body d-lg-none pb-3" style="width: 90%; margin: auto">
                    <h2 class="mt-3 text-white fw-bold text-start">
                        {{ $t('leaderboard-Title') }}
                    </h2>
                    <div class="col-12 d-flex">
                        <ul class="d-flex w-100 gap-1 justify-content-start ps-0 mb-0" style="display: flex!important;">
                            <li :class="size==''? 'on-focus': ''" class="p-2 ps-0 liSize" @click="selectSize('')">All</li>
                            <li :class="size=='5000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('5000')">5K</li>
                            <li :class="size=='10000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('10000')">10K</li>
                            <li :class="size=='25000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('25000')">25K</li>
                            <li :class="size=='50000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('50000')">50K </li>
                            <li :class="size=='100000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('100000')">100K</li>
                            <li :class="size=='200000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('200000')">200K </li>
                        </ul>
                    </div>
                    <div v-for="element in cardPodio" :key="element.position" :class="element.position == 1 ? 'first' : element.position == 2 ? 'second' : element.position == 3 ? 'third' : element.username == name ? 'accountBox' : '' " class="py-3 card mt-5 mt-lg-0" style=" display: flex !important; justify-content: center;" >
                        <ul class="w-100 m-auto py-2 px-2 px-md-5">
                            <div class="text-center myt-2 mb-4">
                                <font-awesome-icon icon="fa-solid fa-medal" v-if="element.position == 1 || element.position == 2 || element.position == 3" :class="element.position == 1 ? 'iconFirst' : element.position == 2 ? 'iconSecond' : element.position == 3 ? 'iconThird': '' " />
                            </div>
                            <li class="d-flex justify-content-between" style="display: flex !important">
                                <h5 class="fw-bold">{{ $t('Score') }}:</h5> <h4>{{ element.position }}</h4>
                            </li>
                            <hr />
                            <li class="d-flex justify-content-between" style="display: flex !important">
                                <h5 class="fw-bold">{{ $t('Username') }}:</h5>
                                <h4>
                                    {{ element.firstName}} {{ element.lastName[0] }}.
                                </h4>
                            </li>
                            <hr />
                            <li class="d-flex justify-content-between" style="display: flex !important">
                                <h5 class="fw-bold">{{ $t('Username') }}:</h5>
                                <h4>{{ element.currency }}</h4>
                            </li>
                            <hr />
                            <li class="d-flex justify-content-between" style="display: flex !important" >
                                <h5 class="fw-bold">{{ $t('CurrentBalance') }}:</h5>
                                <h4>{{ element.balance }}</h4>
                            </li>
                            <hr />
                            <li class="d-flex justify-content-between" style="display: flex !important">
                                <h5 class="fw-bold">{{ $t('Profit') }}:</h5>
                                <h4>{{ element.profit }}</h4>
                            </li>
                            <hr />
                            <li class="d-flex justify-content-between" style="display: flex !important" >
                                <h5 class="fw-bold">{{ $t('%Profit') }}:</h5>
                                <h4>{{ element.profitPerc }} %</h4>
                            </li>
                            <hr />
                        </ul>
                    </div>
                </div>

                <div class="d-none d-lg-block col-12 my-5 my-xl-0">
                    <div class="card-body col-12">
                        <h2 class="text-start fw-bold text-white mb-5">
                                {{ $t('leaderboard-Title') }}
                            </h2>
                        <div class="row d-flex justify-content-end align-items-center">
                            <ul class="d-flex gap-3 justify-content-end col-4 mb-0">
                                <li :class="size==''? 'on-focus': ''" class="p-2 liSize" @click="selectSize('')">All</li>
                                <li :class="size=='5000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('5000')">$ 5K</li>
                                <li :class="size=='10000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('10000')">$ 10K</li>
                                <li :class="size=='25000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('25000')">$ 25K</li>
                                <li :class="size=='50000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('50000')">$ 50K </li>
                                <li :class="size=='100000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('100000')">$ 100K</li>
                                <li :class="size=='200000'? 'on-focus': ''" class="p-2 liSize" @click="selectSize('200000')">$ 200K </li>
                            </ul>
                            <form class="app-search text-end ms-0 col-2">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search for First Name"
                                    v-model="searchTerm"
                                />
                            </form>
                        </div>

                        <div class="table-responsive card-body">
                            <table
                                class="table table-border table-hover text-white"
                            >
                                <thead class="text-white">
                                    <tr class="text-uppercase fs-6">
                                        <th>{{ $t('Score') }}</th>
                                        <th>{{ $t('Username') }}</th>
                                        <th>{{ $t('Currency') }}</th>
                                        <th>{{ $t('InitBalance') }}</th>
                                        <th>{{ $t('CurrentBalance') }}</th>
                                        <th>{{ $t('Profit') }}</th>
                                        <th>{{ $t('%Profit') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, id) in paginatedItems"
                                        :key="id"
                                    >
                                        <td
                                            v-if="
                                                item.position == 1 ||
                                                item.position == 2 ||
                                                item.position == 3
                                            "
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-medal"
                                                :class="
                                                    item.position == 1
                                                        ? 'iconFirstpc'
                                                        : item.position == 2
                                                        ? 'iconSecondpc'
                                                        : item.position == 3
                                                        ? 'iconThirdpc'
                                                        : 'd-none'
                                                "
                                            />
                                        </td>
                                        <td
                                            v-else
                                            :class="
                                                item.username == name
                                                    ? ' text-uppercase text-primary fw-bold'
                                                    : ''
                                            "
                                        >
                                            {{ item.position }}
                                        </td>
                                        <td
                                            :class="
                                                item.username == name
                                                    ? ' text-uppercase text-primary fw-bold'
                                                    : ''
                                            "
                                        >
                                            {{ item.firstName }}
                                            {{ item.lastName[0] }}.
                                        </td>
                                        <td>{{ item.currency }}</td>
                                        <td
                                            :class="
                                                item.username == name
                                                    ? ' text-uppercase text-primary fw-bold'
                                                    : ''
                                            "
                                        >
                                            {{ item.initial_balance }}
                                        </td>
                                        <td
                                            :class="
                                                item.username == name
                                                    ? ' text-uppercase text-primary fw-bold'
                                                    : ''
                                            "
                                        >
                                            {{ item.balance }}
                                        </td>
                                        <td
                                            :class="
                                                item.username == name
                                                    ? ' text-uppercase text-primary fw-bold'
                                                    : ''
                                            "
                                        >
                                            {{ item.profit }}
                                        </td>
                                        <td
                                            :class="
                                                item.username == name
                                                    ? ' text-uppercase text-primary fw-bold'
                                                    : ''
                                            "
                                        >
                                            {{ item.profitPerc }}%
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            class="d-flex justify-content-center mt-2 mt-lg-0"
                            style="column-gap: 50px; display: flex !important"
                        >
                            <ul id="pagination-ul"
                                class="pagination justify-content-center gap-3"
                                style="row-gap: 10px; display: flex !important"
                            >
                                <li class="page-item d-block">
                                    <button
                                        class="btn border border-primary text-primary"
                                        :disabled="currentPage === 1"
                                        @click="goToPage(1)"
                                    >
                                       {{ $t('First') }}
                                    </button>
                                </li>
                                <li class="page-item d-block">
                                    <button
                                        class="btn border border-primary text-primary"
                                        :disabled="currentPage === 1"
                                        @click="goToPage(currentPage - 1)"
                                    >
                                    {{ $t('Prev') }}
                                    </button>
                                </li>
                                <li>{{ currentPage }}/{{ totalPages }}</li>
                                <li class="page-item d-block">
                                    <button
                                        class="btn border border-primary text-primary"
                                        :disabled="currentPage === totalPages"
                                        @click="goToPage(currentPage + 1)"
                                    >
                                    {{ $t('Next') }}
                                    </button>
                                </li>
                                <li class="page-item d-block">
                                    <button
                                        class="btn border border-primary text-primary"
                                        :disabled="currentPage === totalPages"
                                        @click="goToPage(totalPages)"
                                    >
                                    {{ $t('Last') }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-center mt-2" style="display: flex !important">
                    <button type="button" class="btn btn-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#exampleModal" >
                        {{ $t('MapW') }}
                    </button>
                    <div class="modal fade bg-black opacity-9" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <MapLeaderboard />
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger rounded-pill" data-bs-dismiss="modal">
                                        {{ $t('Close') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- End Content --->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <RankModal :showRank="showRank" @close="closeRank" />

        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer />
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script>
import bannerKyc from "./statics/bannerKyc.vue";
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
import Spinner from "./Spinner";
import MapLeaderboard from "../components/MapLeaderboard";
import RankModal from "./RankModal";
export default {
    components: {
        Footer,
        Navbar,
        Sidebar,
        Spinner,
        MapLeaderboard,
        bannerKyc,
        RankModal,
    },
    data() {
        return {
            showRank: false,
            loading: true,
            name: "",
            info1: false,
            info2: false,
            info3: false,
            items: [],
            itemsPerPage: 7, // Numero di elementi da mostrare per pagina
            currentPage: 1, // Pagina corrente
            searchTerm: "",
            totalPages: "",
            FistPlace: {},
            SecondPlace: {},
            ThirdPlace: {},
            cardPodio: [],
            currencyCode: "",
            searchTerm: "",
            size:''
        };
    },
    computed: {
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            let filteredItems;

            if (this.searchTerm === "") {
                this.totalPages = Math.ceil(
                    (this.items.length - 3) / this.itemsPerPage
                );
                return this.items
                    .slice(0, 3)
                    .concat(
                        this.items
                            .filter((_, index) => index >= 3)
                            .slice(startIndex, endIndex)
                    );
            } else {
                filteredItems = this.items.filter((item) =>
                    item.firstName
                        .toLowerCase()
                        .includes(this.searchTerm.toLowerCase())
                );
                this.totalPages = Math.ceil(
                    filteredItems.length / this.itemsPerPage
                );
                return filteredItems.slice(startIndex, endIndex);
            }
        },
    },
    methods: {
        selectSize(value){
            console.log(value)
            this.size=value
            this.leaderData(this.size)
        },
        closeRank() {
            this.showRank = false;
        },
        watchRank(value) {
            console.log(value);
            this.showRank = value;
        },
        showInfo1() {
            this.info1 = !this.info1;
            this.info2 = false;
            this.info3 = false;
        },
        showInfo2() {
            this.info2 = !this.info2;
            this.info1 = false;
            this.info3 = false;
        },
        showInfo3() {
            this.info3 = !this.info3;
            this.info2 = false;
            this.info1 = false;
        },
        goToPage(page) {
            // Vai alla pagina specificata
            this.currentPage = page;
        },
        leaderData(value) {
            this.FistPlace= {},
            this.SecondPlace= {},
            this.ThirdPlace= {},
            this.cardPodio= [],
            this.loading=true
            let accounts = [];
            let list = sessionStorage.getItem("accounts");
            let accountsCookie = JSON.parse(list).list_account;
            accountsCookie.forEach((element) => {
                let acc = sessionStorage.getItem(element);
                let el = JSON.parse(acc);
                accounts.push(el);
            });
            accounts.sort(function (a, b) {
                var balanceA = "balance" in a ? a.balance : -Infinity;
                var balanceB = "balance" in b ? b.balance : -Infinity;
                return balanceB - balanceA;
            });
            if (accounts.length > 0) {
                this.name = accounts[0].firstName;
            }
            let baseUrl=''
            if(value==''){
                baseUrl=`${this.$baseUrl}/leaderboard/getTop20`
            }else{
                baseUrl=`${this.$baseUrl}/leaderboard/getTop20?size=${value}`
            }
            axios
                .get(baseUrl)
                .then((response) => {
                    this.items=response.data;
                    this.items.forEach((element, id) => {
                        if ( parseInt(element.initial_balance) > 5000 && parseInt(element.initial_balance) < 10000 ) {
                            element.initial_balance = element.value_account || "5000";
                        } else if ( parseInt(element.initial_balance) > 10000 && parseInt(element.initial_balance) < 25000) {
                             element.initial_balance = element.value_account || "10000";
                        } else if ( parseInt(element.initial_balance) > 25000 && parseInt(element.initial_balance) < 50000 ) {
                            element.initial_balance = element.value_account || "25000";
                        } else if (parseInt(element.initial_balance) > 50000 && parseInt(element.initial_balance) < 100000) {
                            element.initial_balance = element.value_account || "50000";
                        } else if ( parseInt(element.initial_balance) > 100000 && parseInt(element.initial_balance) < 200000 ) {
                            element.initial_balance = element.value_account || "100000";
                        } else if ( parseInt(element.initial_balance) > 200000) {
                            element.initial_balance = element.value_account || "200000";
                        }
                        element.profitPerc = (
                            (element.profit / element.initial_balance) *
                            100
                        ).toFixed(2);
                        element.position = id + 1;
                        const formatter = new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: element.currency,
                            minimumFractionDigits: 2,
                        });
                        if (
                            element.currency != "000" &&
                            element.currency != ""
                        ) {
                            const parts = formatter.formatToParts(
                                element.balance
                            );
                            let formBalance = "";
                            for (const part of parts) {
                                if (part.type !== "currency") {
                                    formBalance += part.value;
                                }
                            }
                            if (isNaN(element.balance)) {
                                element.balanceCurr = "Not Defined";
                            } else {
                                element.balance = formBalance;
                            }
                        } else {
                            element.balanceCurr = element.balance;
                        }
                        const profits = formatter.formatToParts(element.profit);
                        let formProfit = "";
                        for (const part of profits) {
                            if (part.type !== "currency") {
                                formProfit += part.value;
                            }
                        }
                        element.profit = formProfit;
                        const inbalances = formatter.formatToParts(
                            element.initial_balance
                        );
                        let intBalance = "";
                        for (const part of inbalances) {
                            if (part.type !== "currency") {
                                intBalance += part.value;
                            }
                        }
                        element.initial_balance = intBalance;
                    });
                    const itemIndex = this.items.findIndex(
                        (item) => item.username == this.name
                    );
                    const indexPage = itemIndex;
                    if (itemIndex >= 0) {
                        this.currentPage = Math.round(
                            indexPage / this.itemsPerPage
                        );
                    }
                    this.items.forEach((element) => {
                        if (element.position == 1) {
                            element.letters =
                                element.firstName[0] + element.lastName[0];
                            this.FistPlace = element;
                        } else if (element.position == 2) {
                            element.letters =
                                element.firstName[0] + element.lastName[0];
                            this.SecondPlace = element;
                        } else if (element.position == 3) {
                            element.letters =
                                element.firstName[0] + element.lastName[0];
                            this.ThirdPlace = element;
                        }
                    });
                    this.items.forEach((element) => {
                        if (element.position >= 1 && element.position <= 10) {
                            this.cardPodio.push(element);
                        } else if (
                            element.firstName.toLowerCase() ==
                            this.name.toLowerCase()
                        ) {
                            this.cardPodio.push(element);
                        }
                    });
                    this.loading=false
                });
        },
    },
    watch: {
        items() {
            this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
        },
    },
    created() {
        this.leaderData(this.size);
    },
    mounted() {
        const timeout = setTimeout(() => {
            this.loading = false;
        }, 2000);
    },
    beforeMount() {
        if (!this.$cookies.get("emailAccount")) {
            this.$router.push({ path: "/" });
        }
    },
};
</script>

<style scoped>
.table {
    --bs-table-hover-color: rgba(255, 255, 255, 1);
    --bs-table-hover-bg: rgba(234, 84, 48, 0.3);
}
td {
    background-color: transparent;
}
.user-img {
    border-radius: 100%;
    width: 100px;
    height: 100px;
}
img {
    cursor: pointer;
}
.boxPodio {
    width: 300px;
    z-index: 9999;
    box-shadow: 4px 4px 25px black;
    padding: 10px;
    left: 70%;
    top: -40px;
    flex-wrap: nowrap;
    border-radius: 10px;
}
table > * {
    width: 100%;
}
.card {
    border-radius: 10px;
    overflow: hidden;
}

#pagination-ul {
    width: 100%;
}
li {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.accountBox {
    border: 1px solid #00c292;
}
.accountBox:hover {
    box-shadow: 5px 5px 30px -10px #00c292;
}
.first {
    border: 1px solid goldenrod;
}
.first:hover {
    box-shadow: 5px 5px 30px -10px goldenrod;
}
.iconFirst {
    color: goldenrod;
    font-size: 30px;
}
.iconFirstpc {
    color: goldenrod;
    font-size: 20px;
}
.second {
    border: 1px solid silver;
}
.second:hover {
    box-shadow: 5px 5px 30px -10px silver;
}
.iconSecond {
    color: silver;
    font-size: 30px;
}
.iconSecondpc {
    color: silver;
    font-size: 20px;
}
.third {
    border: 1px solid rgb(127, 72, 0);
}
.third:hover {
    box-shadow: 5px 5px 30px -10px rgb(127, 72, 0);
}
.iconThird {
    color: rgb(127, 72, 0);
    font-size: 30px;
}
.iconThirdpc {
    color: rgb(127, 72, 0);
    font-size: 20px;
}
.modal-backdrop.fade.show {
    opacity: 0.8;
}
.modal-content {
    border: 0;
    background-color: rgba(
        255,
        255,
        255,
        0%
    ); /* Set the background color of the modal content */
}
.modal-footer {
    border: 0;
}
.bord1,
.bord2 {
    margin: 0 2px;
    border-right: 1px solid #00c292;
    border-left: 1px solid #00c292;
}
.bord3 {
    border-right: 1px solid #00c292;
    border-left: 1px solid #00c292;
    border-bottom: 1px solid #00c292 !important;
}
.form-control:focus {
    box-shadow: 0 0 0 0.25rem rgba(251, 150, 120, 0.8) !important;
}
tr {
    border: none !important;
}
th{
    text-transform: uppercase!important;
}
.on-focus{
    border-bottom:2px solid rgb(207, 97, 70)!important;
}
.liSize{
    cursor: pointer;
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 90% !important;
    }
}
</style>
