
import {createApp} from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';

require('./bootstrap');
require('./socket');

import '@popperjs/core';
import { Tooltip, Popover } from 'bootstrap';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'


/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faWallet,faPercent,faLineChart,faArrowUp,faArrowDown,faMoneyBillWave,faCoins,faCaretDown, faWindowClose,faLevelDown,faSackDollar,faPlusSquare,faComments, faIdCard, faTrophy, faMoneyBillTrendUp, faUserGear, faCloudDownload, faGlobe, faLock, faServer, faUserTag, faSatelliteDish, faLockOpen, faCalendar, faDollar, faDatabase, faPercentage, faChartLine, faArrowTrendUp, faCircleCheck, faCircleXmark, faCircleInfo, faShareNodes, faHouse, faCartShopping, faHandshake, faChartPie, faMoneyBill, faGear, faEye, faNewspaper, faDisplay, faUsers, faUserShield, faSpinner, faCheckSquare, faMedal, faHandsHelping, faArrowRightFromBracket, faUser, faCreditCard, faBook, faChalkboardTeacher, faCertificate, faClockRotateLeft, faCloudArrowDown, faFilePdf, faArrowRight,  faArrowUpRightFromSquare  } from '@fortawesome/free-solid-svg-icons'
import VueCountryDropdown from "vue-country-dropdown";
library.add(faWallet,faPercent,faLineChart, faCloudArrowDown,faFilePdf, faCircleCheck ,faArrowUp,faArrowDown,faArrowRight,faTwitter,faMoneyBillWave,faCoins,faCaretDown, faWindowClose,faLevelDown,faSackDollar,faPlusSquare,faComments,faInstagram, faIdCard,faTrophy, faMoneyBillTrendUp, faUserGear, faCloudDownload, faGlobe, faLock,faServer,faUserTag, faSatelliteDish, faLockOpen, faCalendar, faDollar,faDatabase, faPercentage, faChartLine,faArrowTrendUp, faFacebook, faCircleXmark, faCircleInfo , faShareNodes, faHouse, faCartShopping, faHandshake, faChartPie, faMoneyBill, faGear, faEye, faNewspaper, faDisplay, faUsers, faUserShield, faIdCard, faSpinner, faCheckSquare, faMedal, faHandsHelping, faArrowRightFromBracket, faStar, faUser, faCreditCard, faBook, faChalkboardTeacher, faCertificate, faLock, faClockRotateLeft, faArrowUpRightFromSquare )
import VueCookies from 'vue3-cookies'
import App from './App.vue';
import axios from 'axios';
import router from './router';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { createI18n } from 'vue-i18n'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import VCalendar from 'v-calendar';
import { faStar } from '@fortawesome/free-regular-svg-icons';

import { createPinia } from 'pinia';

const messages = {
    EN: require('./locales/en.json'),
    en: require('./locales/en.json'),
    it: require('./locales/it.json'),
    es: require('./locales/es.json'),
    pt: require('./locales/pt.json'),
  };

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  messages
})

const app = createApp(App);
/** Global Vars */
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$languages = {
    en: 'en-US' ,
    it: 'it-IT'  ,
    es: 'es-ES'  ,
    pt: 'pt-PT'  ,
};
if (location.hostname === '127.0.0.1' || location.hostname === 'localhost' || location.hostname.endsWith('.test')) {
    app.config.globalProperties.$trustpayment_domain = 'test_ofpfunding119867';
    app.config.globalProperties.$baseUrl = 'http://127.0.0.1:3000/api';
} else {
    app.config.globalProperties.$baseUrl = 'https://api.ofpfunding.com/api';
    //app.config.globalProperties.$baseUrl = 'https://ofp-api.ait-tech.it/api';
    app.config.globalProperties.$trustpayment_domain = 'ofpfunding119868';
    if(location.hostname.includes('dev')) {
        app.config.globalProperties.$baseUrl = 'https://dev.api.ofpfunding.com/api';
        app.config.globalProperties.$trustpayment_domain = 'test_ofpfunding119867';
    }
}

app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(VueTelInput)
app.use(i18n)
app.use(VCalendar, {})
app.use(VueCookies, {
    expireTimes: "7d",
    secure: true,
});
app.use(createGtm({
    id: "GTM-KTLX35D",
    vueRouter: router
}))

app.use(VueCountryDropdown)
app.directive('tooltip', {
    mounted(el) {
      new Tooltip(el);
    },
  });

app.directive('popover', {
    mounted(el) {
      new Popover(el);
    },
  });

const pinia = createPinia();
pinia.use(() => {
    let token = app.config.globalProperties.$cookies.get('token') || '';
    if (token[token.length - 1] === '/') {
        token = token.slice(0, token.length - 1)
    }

    const httpClient = axios.create({
        baseURL: app.config.globalProperties.$baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    });

    return {
        $httpClient: httpClient
    };
});
app.use(pinia);

import NumberPlugin from './plugins/number';
app.use(NumberPlugin);
import BrevoPlugin from './plugins/brevo';
app.use(BrevoPlugin);
import KlaviyoPlugin from './plugins/klaviyo';
app.use(KlaviyoPlugin);
import Notifications from '@kyvg/vue3-notification'
app.use(Notifications);

app.mount('#app');
