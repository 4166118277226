import { defineStore } from 'pinia';

export const usePopup = defineStore('popup', {
    state: () => ({
        settings: { disableClose: false, disableCloseButton: false },
        icon: 'fa-info-circle',
        title: 'OFP Dashboard',
        image: null,
        heading: '',
        body: '',
        buttonText: '',
        buttonClick: null,
        component: null,
        componentProps: null,
    }),
    actions: {
        reset() {
            this.settings.disableClose = false;
            this.icon = 'fa-info-circle';
            this.title = 'OFP Dashboard';
            this.image = null;
            this.heading = '';
            this.body = '';
            this.buttonText = '';
            this.buttonClick = null;
            this.component = null;
            this.componentProps = null;
        },
        popup({ settings, icon, title, image, heading, body, buttonText, buttonClick, component, componentProps }) {
            this.reset();

            if (settings) this.settings = settings;
            if (icon) this.icon = icon;
            if (title) this.title = title;
            if (image) this.image = image;
            if (heading) this.heading = heading;
            if (body) this.body = body;
            if (buttonText) this.buttonText = buttonText;
            this.buttonClick = buttonClick || (() => {});
            this.component = component;
            this.componentProps = componentProps;
        }
    }
});
