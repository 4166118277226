<template>
  <div class="card-section p-3">
    <img src="/assets/images/email-sent.svg" class="py-3" />
    <h2 class="text-white">{{ $t('email-sent-title') }}</h2>
    <p class="text-center fw-normal my-4">
      {{ $t('email-sent-description') }}<br/>
      {{ email }}
    </p>
    <p class="text-center fw-normal">
      <b class="text-white">{{ $t('email-sent-click-instructions') }}</b> {{ $t('email-sent-instructions') }}
    </p>
    <p class="text-center fw-normal">
      {{ $t('email-sent-cant-find') }}
    </p>
    <a class="text-primary text-decoration-underline mb-4" href="#" @click="resendEmail">{{ $t('resend-email') }}</a>
    <p class="text-center fw-normal">
      {{ $t('email-sent-need-help') }}
      <a class="text-primary text-decoration-underline mb-4" href="/">{{ $t('contact-us') }}</a>
    </p>
  </div>
</template>
<script>
  export default {
    props: {
      email: String,
      resendEmail: Function
    },
  }
</script>
