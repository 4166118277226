<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div v-if="!check" id="liveToast" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header coloreBG">
                <strong class="me-auto fw-bold text-primary">OFP Funding</strong>
            </div>
            <div class="borderColor w-75 m3-auto ms-2"></div>
            <div class="toast-body disFlex d-flex flex-column">
                <strong class="text-danger">{{ messageToast }}</strong>
                <small>{{ subMessToast }}</small>
                <button class=" mt-2 btn btn-outline-primary" @click="askEmail()">Ask Verify Email</button>
            </div>
        </div>
        <div v-else id="liveToast" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header coloreBG">
                <strong class="me-auto fw-bold text-primary">OFP Funding</strong>
            </div>
            <div class="borderColor w-75 m3-auto ms-2"></div>
            <div class="toast-body disFlex d-flex flex-column">
                <strong class="text-success">{{ messageToastEmail }}</strong>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props:{
        messageToast:String,
        subMessToast:String,
        email:String
    },
    data(){
        return{
            check:false,
            messageToastEmail:''
        }
    },
    methods:{
        askEmail(){
            try {
                axios.get(`${this.$baseUrl}/customers/resend-email?email=${this.email}`).then((response)=>{
                    console.log(response)
                    this.check=true
                    this.messageToastEmail='Verification email sent'
            })
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style scoped>
.toast,
.toast-header,
.toast-body{
    background-color: #080b1e!important;
}
.borderColor{
    border: 0.5px solid #7070702b;
}
.disFlex{
    display: flex!important;
}
</style>
