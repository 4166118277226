import {defineStore} from "pinia";
import axios from "axios";
import { useItemsStore } from "./items.store";
import moment from "moment";

export const useAccountStore = defineStore('account', {
    state: () => ({
        accountInfo: null,
        openTrades: [],
        tracking: {},
        accountItem: null
    }),
    actions: {
        async getAccountInfo(id) {
            if (id !== this.accountInfo?._id) {
                this.accountInfo = null;
                this.openTrades = [];
                this.tracking = {};
            }

            const {data} = await this.$httpClient.get(`/accounts/getAccountInfo/${id}`);
            this.accountInfo = data;
            return data;
        },
        async getAccountItem() {
            if (this.accountItem !== null) {
                return this.accountItem;
            }

            let item = null;

            if (this.accountInfo.order_id) {
                const order = await this.$httpClient.get(`/order/find/${this.accountInfo.order_id}`);
                item = (order.data?.items && order.data?.items[0]) || null;
            }

            if (!item || (item && !item.payout_type)) {
                const size = this.accountInfo.ref_balance.toString().replace(/000$/, 'K');
                const currency = this.accountInfo.currency;
                const profitSplit = this.accountInfo.type_account.substring(0, this.accountInfo.type_account.indexOf('%'));

                let payout_type = 'monthly';
                if (this.accountInfo.type_account.toLowerCase().includes('biweekly')) {
                    payout_type = 'biweekly';
                } else if (this.accountInfo.type_account.toLowerCase().includes('on-demand')) {
                    payout_type = 'on-demand';
                }

                item = {
                    itemId: `${size}${currency}${profitSplit}`.toUpperCase(),
                    payout_type,
                    type: this.accountInfo.type_account.toLowerCase().includes('supercharged') ? 'supercharged' : 'classic',
                    max_daily_drawdown: 5
                };
            }

            this.accountItem = await useItemsStore().getItemById(item.itemId, item.payout_type, item.type, item.max_daily_drawdown);
            return this.accountItem;
        },
        async getOpenTrades() {
            const { data } = await this.$httpClient.post(`/user/getOpenTrades?user_id=${this.accountInfo.userID}`);
            this.openTrades = data.map(trade => {
                trade.openTime = moment(trade.openTime).format('YYYY-MM-DD HH:mm:ss');
                trade.type = trade.type.split('_')[2];

                switch (trade.success) {
                    case 'lost':
                        trade.status = 'loosing';
                        break;
                    case 'won':
                        trade.status = 'winning';
                        break;
                    default:
                        break;
                }

                return trade;
            });
            return this.openTrades;
        },
        async getUserTrackingForChart() {
            if (Object.keys(this.tracking).length > 0) {
                return this.tracking;
            }

            const startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 1);
            startDate.setDate(startDate.getDate() + 1);

            const endDate = new Date();
            endDate.setDate(endDate.getDate() + 1);
            const start = startDate.toISOString().split('T')[0];
            const end = endDate.toISOString().split('T')[0];

            const { data } = await this.$httpClient.get(`/getUserTrackingForChart?user_id=${this.accountInfo.userID}&first_date=${start}&last_date=${end}`);
            this.tracking = data;
            return data;
        }
    }
});
