<template>

    <table :class="tableClass">
        <thead>
            <tr>
                <th>Account type</th>
                <th nowrap v-for="(item, index) in items" :key="index" class="clickable"
                    @click="emit('update:modelValue', index)">{{ title(index) }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td nowrap>{{ $t('buyaccount-account-size') }}</td>
                <td v-for="(item, index) in items" :key="index">{{
                    $formatAmountRounded(item.version.size, item.version.currency_account) }}</td>
            </tr>
            <tr>
                 <td v-for="(item, index) in dividers" :key="index" class="divider">
                    <div class="divider"/>
                </td>
            </tr>        

            <tr>
                <td nowrap>{{ $t('leverage') }}</td>
                <td v-for="(item, index) in items" :key="index">{{ formatLeverage(item.props.leverage) }}</td>
            </tr>
            <tr>
                 <td v-for="(item, index) in dividers" :key="index" class="divider">
                    <div class="divider"/>
                </td>
            </tr>  
            <tr>
                <td nowrap> {{ $t('max_daily_drawdown') }} </td>
                <td v-for="(item, index) in items" :key="index">{{ formatPercents(item.props.max_daily_drawdown) }}</td>
            </tr>
            <tr>
                 <td v-for="(item, index) in dividers" :key="index" class="divider">
                    <div class="divider"/>
                </td>
            </tr>  
            <tr>
                <td nowrap>{{ $t('max_drawdown') }}</td>
                <td v-for="(item, index) in items" :key="index">{{ formatPercents(item.props.max_drawdown) }}</td>
            </tr>
            <tr>
                 <td v-for="(item, index) in dividers" :key="index" class="divider">
                    <div class="divider"/>
                </td>
            </tr>  
            <tr>
                <td nowrap>{{ $t('max_trader_value_score') }}</td>
                <td v-for="(item, index) in items" :key="index">{{ formatPercents(item.props.max_trader_value_score) ||
                    '-'}}</td>
            </tr>
            <tr>
                 <td v-for="(item, index) in dividers" :key="index" class="divider">
                    <div class="divider"/>
                </td>
            </tr>  
            <tr>
                <td nowrap>{{ $t('Payouts') }}</td>
                <td v-for="(item, index) in items" :key="index">{{ formatPayoutFrequency(item.payout_type) }}</td>
            </tr>
            <tr>
                 <td v-for="(item, index) in dividers" :key="index" class="divider">
                    <div class="divider"/>
                </td>
            </tr>  
            <tr>
                <td nowrap>{{ $t('profit_split') }}</td>
                <td v-for="(item, index) in items" :key="index">{{ formatPercents(item.props.profit_split) }}</td>
            </tr>
            
        </tbody>
        <tfoot>
            <tr>
                <td rowspan="2">{{ $t('price') }}</td>
                <td rowspan="2" v-for="(item, index) in items" :key="index">

                    <div>
                        <div class="price">{{ formatPrice(item) }}</div>
                        <div class="pt-4">
                            <button class="btn-buy" @click="buyAccount(item)" :disabled="processing || !item">
                                <span v-if="!processing">{{ $t('buyaccount-buy_account') }}</span>
                                <i class="fa fa-spin fa-spinner" v-else></i>
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>
</template>
<script setup>
import { ref, toRaw, computed } from 'vue';

import { formatPayoutFrequency, formatLeverage, formatPercents } from "./utils/format";

const props = defineProps({
    modelValue: {
        required: true,
        type: Number,
    },
    items: {
        required: true,
        type: Array
    },
    processing: false
})

const emit = defineEmits([
    'update:modelValue',
    'buy-account',
])

const title = (index) => {
    return index === 0 ? 'Custom Plan' : `Custom Plan #${index + 1}`
}

const dividers = computed(() => [0,...props.items.map((e,index) => index)])
const tableClass = computed(() => `i${props.items.length} s${props.modelValue+1}`)

const formatPrice = (item) => {
    return item && item.version ? `$${item.version.price.toFixed(2)}` : '-'
}

const buyAccount = (item) => {
    emit('buy-account', toRaw(item));
}

</script>
<style scoped lang="scss">
.price {
    font-size: 26px;
    line-height: 26px;
    font-weight: 700;
}

table {
    margin-top: 40px;
    border: 1px solid rgba(255, 255, 255, 0.2);
 
}

table tfoot {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}
table th,
td {
    padding: 24px;
    text-align: center;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    max-width: 300px;
    color: white;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
}

table th {
    font-weight: 600;
    font-size: 24px;
    background: rgba(255, 255, 255, 0.1);
}

table th.clickable {
    cursor: pointer;
}

table.i1 {
    max-width: 794px;
    width: 100%;
}

table.i2 {
    max-width: 1102px;
    width: 100%;
}

table.i3 {
    max-width: 1218px;
    width: 100%;
}

table.s1 tr td:first-child+td {
    background-color:  #723232;
}

table.s1 tr th:first-child+th {
    background-color: #723232;
}

table.i1.s1 tr td:first-child+td {
    background-color: transparent;
}

table.i1.s1 tr th:first-child+th {
    background-color:  rgba(255, 255, 255, 0.1);;
}

table.s2 tr td:first-child+td+td {
    background-color:  #723232;
}

table.s2 tr th:first-child+th+th {
    background-color: #723232;
}

table.s3 tr td:first-child+td+td+td {
    background-color:  #723232;
}

table.s3 tr th:first-child+th+th+th {
    background-color: #723232;
}

td.divider {
    height: 1px; 
    padding-left: 60px;
    padding-right: 60px; 
    padding-top: 0px; 
    padding-bottom: 0px;
}
div.divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-buy {
    height: 41px;
    padding: 10px 8px 10px 8px;
    border-radius: 8px;
    font-family: Heebo, "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: rgb(255, 104, 68, 1);
    margin: auto;
    width: 218px;
    min-width: 150px;
    max-width: 218px;
    border: none;
}

.btn-buy:disabled {
    color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.05);
}
</style>