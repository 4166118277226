<template>

    <div id="main-wrapper" >
        <Spinner v-if="loading" />
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar" :class="{ 'opacity-25': loading }" >
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar" :class="{ 'opacity-25': loading }" >
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar" >
                <!-- Sidebar navigation-->
                <Sidebar @rank="watchRank" />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->


        <div class="page-wrapper" :class="showRank?'opacity-25':''" >
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid" style="margin-bottom: 100px;">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->

                <!--- Start Content --->
                <div class="row my-5 "  v-if="client">
                    <div class="col-12">
                        <div class="">
                            <nav class="navbar ">
                                    <div class="container-fluid d-block d-lg-none">
                                        <div class="navbar-nav justify-content-center gap-1" style="display: flex; flex-wrap: wrap; flex-direction: row;">
                                                <a class="p-1" :class="general? 'on-focus': ''"  @click="showGeneral()">{{ $t('Profile') }}</a>
                                                <a class="p-1" :class="security? 'on-focus': ''"   @click="showSecurity()">{{ $t('Security') }}</a>
                                                <a class="p-1" :class="verification? 'on-focus': ''"    @click="showVerification()">{{ $t('Verification') }}</a>
                                                <a class="p-1" :class="payout? 'on-focus': ''"  href="#"  @click="showPayout()">{{ $t('Payout') }}</a>
                                        </div>

                                    </div>
                                </nav>
                            <div style="display:flex!important;justify-content: center;">
                                <div class="me-3  col-2 d-none d-lg-block"  >
                                    <ul class="list-group list-group-flush">
                                        <li :class="general? 'on-focus': ''" class="list-group-item border-0" @click="showGeneral()" style="background-color: transparent;">{{ $t('Profile') }}</li>
                                        <li :class="security? 'on-focus': ''" class="list-group-item border-0"  @click="showSecurity()" style="background-color: transparent;">{{ $t('Security') }}</li>
                                        <li :class="verification? 'on-focus': ''" class="list-group-item border-0"   @click="showVerification()" style="background-color: transparent;">{{ $t('Verification') }}</li>
                                        <li :class="payout? 'on-focus': ''" class="list-group-item border-0"   @click="showPayout()" style="background-color: transparent;">{{ $t('Payout') }}</li>
                                    </ul>
                                </div>
                                <div class="border border-end mx-2 d-none d-lg-block "></div>
                                <!--General-->
                                <div v-if="general" class=" col-12 col-lg-9 ms-0 ms-lg-3 mb-lg-5" >
                                    <h5 v-if="textInfoError!=''" class="text-center text-danger mt-2">{{ textInfoError }}</h5>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h1 class="text-center py-4 text-white m-0 p-0 ">{{ $t('Profile') }}</h1>
                                    </div>
                                    <hr>
                                    <div class="d-flex align-items-center justify-content-between gap-3" style="display: flex!important">
                                    <div>
                                        <h5 class="text-white">Avatar</h5>
                                    </div>
                                    <div  class="d-flex gap-3 align-items-center col-12 col-lg-4" style="display: flex!important;">
                                        <div v-if="imgProfile== undefined || imgProfile == ''" class="d-flex align-items-center justify-content-center user-img bg-success text-white fs-3 text-uppercase fw-bold lettershadow" style="display: flex!important; "> {{ profileLetter }}</div>
                                         <img v-else  :src="imgProfile" alt="user-img"  style="display: flex!important; " class="d-flex align-items-center justify-content-center img-circle user-img">
                                         <div class="file-input " style="cursor: pointer;" >
                                            <input type="file" id="myFile" class="d-none " accept="image/*" size="600000" ref="fileInput" @input="uploadFile()">
                                            <label class="btn btn-outline-dark text-primary" for="myFile">{{ $t('Img-up') }}</label>
                                         </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="d-flex align-items-start justify-content-between gap-3" >
                                    <div>
                                        <h5 class="text-white d-none d-lg-block">{{ $t('TagLine') }}</h5>
                                    </div>
                                    <div  class="d-flex gap-3 align-items-center col-12 col-lg-4">
                                        <textarea name="bio" id="" class="p-2 rounded" cols="60" rows="10" v-model="biography" ></textarea>
                                    </div>
                                </div>
                                <hr>
                                <div class="d-flex align-items-start justify-content-between gap-3" >
                                    <div>
                                        <h5 class="text-white d-none d-lg-block">{{ $t('Username') }}</h5>
                                    </div>
                                    <div  class="d-flex gap-3 align-items-center col-12 col-lg-4">
                                        <input class="form-control rounded" type="text"  id="username" v-model="username">
                                    </div>
                                </div>
                                <hr>
                                  <div>
                                    <div class="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h5 class="text-white mb-3">Personal Info</h5>
                                    </div>
                                        <div class="form-horizontal form-material flex-column col-12 col-lg-4" style="display:flex!important;" id="loginform" >
                                            <div class="form-group d-flex flex-column gap-5 "  style="display:flex!important;">
                                                <div class="col-12 ">
                                                    <label for="firstName" class=" mb-1">{{ $t('checkout-name') }}</label>
                                                    <input class="form-control rounded" type="text"  id="firstName" v-model="firstName">
                                                </div>
                                                <div class="col-12 ">
                                                    <label for="lastName" class=" mb-1">{{ $t('checkout-lastName') }}</label>
                                                    <input class="form-control rounded" type="text"  id="lastName" v-model="lastName">
                                                </div>
                                            </div>
                                            <div class="form-group d-flex flex-column gap-5"  style="display:flex!important;">
                                                <div class="col-12">
                                                    <label for="phone" class=" mb-1">{{ $t('Phone') }}</label>
                                                    <input class="form-control rounded" type="phone"  id="phone" v-model="phone">
                                                </div>
                                                <div class="col-12">
                                                    <label for="email" class=" mb-1">Email</label>
                                                    <input class="form-control rounded" type="email" disabled  id="email" :value="client.email">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="d-flex justify-content-between align-items-start">
                                            <div>
                                                <h5 class="text-white mb-3">{{ $t('Address') }}</h5>
                                            </div>
                                            <div class="form-horizontal form-material flex-column col-12 col-lg-4" style="display:flex!important;" id="loginform" >
                                                <div class="form-group d-flex flex-column gap-5"  style="display:flex!important;">
                                                <div class="col-12 ">
                                                    <label for="address" class=" mb-1">{{ $t('Address') }}</label>
                                                    <input class="form-control rounded" type="text"  id="address" v-model="address">
                                                </div>
                                                <div class="col-12 ">
                                                    <label for="city" class=" mb-1">{{ $t('checkout-city') }}</label>
                                                    <input class="form-control rounded" type="text"  id="city" v-model="city">
                                                </div>
                                            </div>
                                                <div class="form-group d-flex flex-column  gap-5"  style="display:flex!important;">
                                                <div class="col-12 ">
                                                    <label for="zip" class=" mb-1">{{ $t('checkout-zip') }}</label>
                                                    <input class="form-control rounded" type="text"  id="zip" v-model="zip">
                                                </div>
                                                <div class="col-12 ">
                                                    <label for="country" class=" mb-1">{{ $t('checkout-country') }}</label>
                                                    <select  name="country" class="form-control rounded text" id="country" v-model="country">
                                                            <option value=""></option>
                                                            <option v-for="state in options" :key="state.label"  :selected="country==state.value"  :value="state.value">{{state.label}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                  </div>
                                  <hr>
                                       <div class="col-12 d-flex justify-content-end gap-3 mt-5" style="display: flex!important;">
                                        <button class="text-primary border-0" style="background-color: transparent;">{{ $t('Cancel') }}</button>
                                            <button @click="infoClient()" class="btn btn-primary border rounded-2 ">{{ $t('SaveChange') }}</button>
                                       </div>
                                </div>
                                <!--Security-->
                                <div v-if="security" class="col-12 col-lg-9 ms-3 " >
                                    <h4 class="text-danger text-uppercase text-center">{{ texterror }}</h4>
                                   <h4 class="text-success text-uppercase text-center">{{ textsuccess }}</h4>
                                   <div class="d-flex justify-content-between align-items-center">
                                        <h1 class="text-center py-4 text-white m-0 p-0 ">{{ $t('Security') }}</h1>

                                   </div>
                                  <hr >
                                   <div class="d-flex align-items-start justify-content-between gap-3 mt-5" >
                                    <div>
                                        <h5 class="text-white">{{ $t('CPass') }}</h5>
                                        <p>{{ $t('CPass-Text') }}</p>
                                    </div>
                                   <div class="form-horizontal form-material d-flex flex-column mb-3 col-12 col-lg-4" style="row-gap: 30px;" id="loginform">
                                    <div class="col-12 mb-2">
                                        <label for="password" class="text-white mb-1">{{ $t('old-psw') }}</label>
                                        <div class="d-flex align-items-center gap-2" style="display:flex!important;">
                                                    <input id="oldpassword" :type="OldPsw?'password':'text'" class="form-control rounded" v-model="oldpassword"
                                                required autocomplete="off" placeholder="Enter old password">
                                                <i class="icons-Eye-Visible" @click="changeTipeOld()" ></i>

                                        </div>
                                    </div>
                                        <div class="col-12  mb-2">
                                            <label for="password" class="text-white mb-1">{{ $t('new-psw') }}</label>
                                            <div class="d-flex align-items-center gap-2" style="display:flex!important;">
                                                        <input id="password" :type="Newpassword?'password':'text'" class="form-control rounded" v-model="password"
                                                    required autocomplete="off" placeholder="Enter your password">
                                                    <i class="icons-Eye-Visible" @click="changeTipeNew()" ></i>
                                            </div>
                                        </div>
                                        <div class="col-12  mb-2 ">
                                            <label for="confirmPassword" class="text-white mb-1">{{ $t('conf-psw') }}</label>
                                            <div class="d-flex align-items-center gap-2" style="display:flex!important;">
                                                <input id="confirmPassword" :type="confirmPsw?'password':'text'" class="form-control  rounded" v-model="confirmPassword"
                                                    required autocomplete="off" placeholder="Confirm your password" >
                                                    <i class="icons-Eye-Visible"  @click="changeTipeValidate()"></i>
                                            </div>
                                            <span v-if="pswError" class="error text-danger">{{ pswError }}</span>
                                        </div>

                                        </div>
                                    </div>
                                    <hr class="mt-5">
                                    <div class="col-12 d-flex justify-content-end gap-3 mt-5">
                                        <button class="text-primary border-0" style="background-color: transparent;">{{ $t('Cancel') }}</button>
                                        <button  class=" p-2 btn btn-primary text-white align-self-end border rounded-2" @click="changePSW()">{{ $t('SaveChange') }}</button>
                                    </div>
                                </div>
                                 <!--Verification-->
                                <div v-if="verification" class=" col-12 col-lg-9" >
                                    <div class="d-flex w-100 justify-content-center align-items-center " style="display: flex!important;" >
                                        <img src="assets/images/Verificationicon.png" class="img-fluid" >
                                    </div>
                                   <h3 class="text-center text-primary fw-bold py-4">{{ $t('get-Ver') }}</h3>
                                    <p class="text-center">{{ $t('get-VerText') }}</p>
                                    <div v-if="client.kyc != 'approved'" class="d-flex justify-content-center col-12 " style="display: flex!important;">
                                        <button class="btn btn-primary mt-5"  style=" margin: 0 auto; border-radius: 10px;" @click="startVeriff()">{{ $t('start-Ver') }}</button>
                                    </div>
                                    <h2 v-else class="text-success text-center">{{ $t('pers-Ver') }}</h2>
                                    <div id='veriff-root' style="width:400px;" class="mt-5 text-center"></div>
                                </div>

                                <div v-if="payout"  class="col-12 col-lg-9 ms-3 ">
                                    <!--bank-->
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h1 class="text-center py-4 text-white m-0 p-0 ">{{ $t('payout-set') }}</h1>

                                   </div>
                                    <ul class="list-group list-group-flush d-flex flex-row gap-3" style="display: flex!important;">
                                        <li :class="!crypto && !paypall? 'on-focus': ''" class=" p-2 list-group-item border-0 " @click="showBannk()" style="background-color: transparent;">{{ $t('Bank') }}</li>
                                        <li :class="crypto? 'on-focus': ''" class="p-2 list-group-item border-0 "  @click="showCrypto()" style="background-color: transparent;">{{ $t('Crypto') }}</li>
                                        <!-- <li :class="paypall? 'on-focus': ''" class="list-group-item border-0 px-0"   @click="showPaypall()" style="background-color: transparent;">PayPal</li> -->
                                    </ul>
                                   <hr>
                                  <div v-if="payout && !crypto && !paypall">
                                    <h4 v-if="textBankError!=''"  class="text-danger text-uppercase text-center">{{ textBankError }}</h4>
                                   <h4 v-if="bankCreation" class="text-success text-uppercase text-center">{{ $t('Bank-succ') }}</h4>
                                  <div class="d-flex align-items-start justify-content-between gap-3">
                                    <div>
                                        <h5 class="text-white">{{ $t('Bank') }}</h5>
                                    </div>
                                    <div class="form-horizontal form-material flex-column mb-3 col-12 col-lg-4" style="row-gap: 20px;" id="loginform"  >
                                            <div class="form-group d-flex flex-column gap-5"  style="display:flex!important;">
                                                <div class="col-12 ">
                                                    <label for="Bankname" class=" mb-1">{{ $t('checkout-name') }}</label>
                                                    <input class="form-control rounded" type="text" required id="Bankname"  placeholder="Name"  v-model="bankName" >
                                                </div>
                                                <div class="col-12 ">
                                                    <label for="BankLastname" class=" mb-1">{{ $t('checkout-lastName') }}</label>
                                                    <input class="form-control rounded" type="text" required id="BankLastName" placeholder="LastName"  v-model="bankLastname">
                                                </div>
                                            </div>
                                            <div class="form-group d-flex flex-column gap-5"  style="display:flex!important;">
                                                <div class="col-12 ">
                                                    <label for="IBAN" class=" mb-1">{{ $t('Bank-N') }}</label>
                                                    <input class="form-control rounded" type="text" required id="IBAN" placeholder="IBAN" v-model="iban">
                                                </div>
                                                <div class="col-12 ">
                                                    <label for="SWIFT" class=" mb-1" >{{ $t('Bank-Swift') }}</label>
                                                    <input class="form-control rounded" type="text" required id="SWIFT" placeholder="SWIFT" v-model="swift">
                                                </div>
                                            </div>
                                            <div class="form-group d-flex flex-column gap-5"  style="display:flex!important;">
                                                <div class="col-12 ">
                                                    <label for="Sort" class=" mb-1">{{ $t('Bank-Sort') }}</label>
                                                    <input class="form-control rounded" type="text"  id="Sort" placeholder="Sort Code" v-model="sortCode">
                                                </div>
                                                <div class="col-12 ">
                                                    <label for="BankAddress" class=" mb-1">{{ $t('Address') }}</label>
                                                    <input class="form-control rounded" type="address" required id="BankAddress" placeholder="Enter your address"  v-model="bankAddress">
                                                </div>
                                            </div>
                                            <div class="form-group d-flex flex-column gap-5"  style="display:flex!important;">
                                                <div class="col-12 ">
                                                    <label for="Sort" class=" mb-1">{{ $t('Bank-Routing') }}</label>
                                                    <input class="form-control rounded" type="text"  id="Sort" placeholder="Sort Code" v-model="routing">
                                                </div>
                                            </div>
                                        </div>
                                  </div>
                                  <hr>
                                  <div class="col-12 d-flex gap-3 justify-content-end mt-5">
                                        <button class="text-primary border-0" style="background-color: transparent;">{{ $t('Cancel') }}</button>
                                        <button class="btn btn-primary border rounded-2 " @click="bankPayout()">{{ $t('Bank-save') }}</button>
                                    </div>
                                </div>
                                <!--crypto-->
                                 <div v-if="crypto" >
                                    <h5 v-if="textCryptoError!=''" class="text-danger text-uppercase text-center">{{ textCryptoError }}</h5>
                                    <h5 v-if="cryptoCreation" class="text-success text-uppercase text-center">{{ $t('Crypto-succ') }}</h5>
                                    <div class="d-flex align-items-start justify-content-between gap-3" >
                                        <div>
                                            <h5 class="text-white">{{ $t('Crypto') }}</h5>
                                            <p class="">{{ $t('Crypto-text') }}</p>
                                        </div>
                                        <div class="form-horizontal form-material flex-column mb-3 col-lg-4 col-12" style=" row-gap: 20px;" id="loginform">
                                    <div class="form-group d-flex flex-column gap-5"  >
                                                <div class="col-12 ">
                                                    <label for="Bankname" class=" mb-1">{{ $t('Crypto-LTC') }}</label>
                                                    <input class="form-control rounded" v-model="wallet" type="text" required   placeholder="Enter your Wallet"   >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                        <hr>
                                        <div class="col-12 d-flex justify-content-end gap-3 mt-5">
                                            <button class="text-primary border-0" style="background-color: transparent;">{{ $t('Cancel') }}</button>
                                            <button style="border-radius: 10px;" @click="criptoPayout()"  class="btn btn-primary align-self-end">{{ $t('Wallet-s') }}</button>
                                        </div>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!--- End Content --->

            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <RankModal :showRank="showRank" @close="closeRank"/>
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer/>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script>
import {Veriff} from '@veriff/js-sdk';
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
import Spinner from './Spinner';
import Orders from './Orders'
import { useCookies } from "vue3-cookies";
import bannerKyc from "./statics/bannerKyc.vue";
import RankModal from "./RankModal";
import {mapGetters} from "pinia";
import {useUserStore} from "../store/user.store";
export default {
    setup() {
    const cookies = useCookies()
    return {cookies}
  },
    name: "user",
    components: {Footer, Navbar, Sidebar,Spinner,Orders,bannerKyc,RankModal},
    computed: {
        ...mapGetters(useUserStore, ['user'])
    },
    data(){
        return{
            showRank:false,
            client:{},
            options: [
         { value: 'AF', label: 'Afghanistan'},
         { value: 'AX', label: 'Aland Islands'},
         { value: 'AL', label: 'Albania'},
         { value: 'DZ', label: 'Algeria'},
         { value: 'AS', label: 'American Samoa'},
         { value: 'AD', label: 'Andorra'},
         { value: 'AO', label: 'Angola'},
         { value: 'AI', label: 'Anguilla'},
         { value: 'AQ', label: 'Antarctica'},
         { value: 'AG', label: 'Antigua And Barbuda'},
         { value: 'AR', label: 'Argentina'},
         { value: 'AM', label: 'Armenia'},
         { value: 'AW', label: 'Aruba'},
         { value: 'AU', label: 'Australia'},
         { value: 'AT', label: 'Austria'},
         { value: 'AZ', label: 'Azerbaijan'},
         { value: 'BS', label: 'Bahamas'},
         { value: 'BH', label: 'Bahrain'},
         { value: 'BD', label: 'Bangladesh'},
         { value: 'BB', label: 'Barbados'},
         { value: 'BY', label: 'Belarus'},
         { value: 'BE', label: 'Belgium'},
         { value: 'BZ', label: 'Belize'},
         { value: 'BJ', label: 'Benin'},
         { value: 'BM', label: 'Bermuda'},
         { value: 'BT', label: 'Bhutan'},
         { value: 'BO', label: 'Bolivia'},
         { value: 'BA', label: 'Bosnia And Herzegovina'},
         { value: 'BW', label: 'Botswana'},
         { value: 'BV', label: 'Bouvet Island'},
         { value: 'BR', label: 'Brazil'},
         { value: 'IO', label: 'British Indian Ocean Territory'},
         { value: 'BN', label: 'Brunei Darussalam'},
         { value: 'BG', label: 'Bulgaria'},
         { value: 'BF', label: 'Burkina Faso'},
         { value: 'BI', label: 'Burundi'},
         { value: 'KH', label: 'Cambodia'},
         { value: 'CM', label: 'Cameroon'},
         { value: 'CA', label: 'Canada'},
         { value: 'CV', label: 'Cape Verde'},
         { value: 'KY', label: 'Cayman Islands'},
         { value: 'CF', label: 'Central African Republic'},
         { value: 'TD', label: 'Chad'},
         { value: 'CL', label: 'Chile'},
         { value: 'CN', label: 'China'},
         { value: 'CX', label: 'Christmas Island'},
         { value: 'CC', label: 'Cocos (Keeling) Islands'},
         { value: 'CO', label: 'Colombia'},
         { value: 'KM', label: 'Comoros'},
         { value: 'CG', label: 'Congo'},
         { value: 'CD', label: 'Congo}, Democratic Republic'},
         { value: 'CK', label: 'Cook Islands'},
         { value: 'CR', label: 'Costa Rica'},
         { value: 'CI', label: 'Cote D\'Ivoire'},
         { value: 'HR', label: 'Croatia'},
         { value: 'CU', label: 'Cuba'},
         { value: 'CY', label: 'Cyprus'},
         { value: 'CZ', label: 'Czech Republic'},
         { value: 'DK', label: 'Denmark'},
         { value: 'DJ', label: 'Djibouti'},
         { value: 'DM', label: 'Dominica'},
         { value: 'DO', label: 'Dominican Republic'},
         { value: 'EC', label: 'Ecuador'},
         { value: 'EG', label: 'Egypt'},
         { value: 'SV', label: 'El Salvador'},
         { value: 'GQ', label: 'Equatorial Guinea'},
         { value: 'ER', label: 'Eritrea'},
         { value: 'EE', label: 'Estonia'},
         { value: 'ET', label: 'Ethiopia'},
         { value: 'FK', label: 'Falkland Islands (Malvinas)'},
         { value: 'FO', label: 'Faroe Islands'},
         { value: 'FJ', label: 'Fiji'},
         { value: 'FI', label: 'Finland'},
         { value: 'FR', label: 'France'},
         { value: 'GF', label: 'French Guiana'},
         { value: 'PF', label: 'French Polynesia'},
         { value: 'TF', label: 'French Southern Territories'},
         { value: 'GA', label: 'Gabon'},
         { value: 'GM', label: 'Gambia'},
         { value: 'GE', label: 'Georgia'},
         { value: 'DE', label: 'Germany'},
         { value: 'GH', label: 'Ghana'},
         { value: 'GI', label: 'Gibraltar'},
         { value: 'GR', label: 'Greece'},
         { value: 'GL', label: 'Greenland'},
         { value: 'GD', label: 'Grenada'},
         { value: 'GP', label: 'Guadeloupe'},
         { value: 'GU', label: 'Guam'},
         { value: 'GT', label: 'Guatemala'},
         { value: 'GG', label: 'Guernsey'},
         { value: 'GN', label: 'Guinea'},
         { value: 'GW', label: 'Guinea-Bissau'},
         { value: 'GY', label: 'Guyana'},
         { value: 'HT', label: 'Haiti'},
         { value: 'HM', label: 'Heard Island & Mcdonald Islands'},
         { value: 'VA', label: 'Holy See (Vatican City State)'},
         { value: 'HN', label: 'Honduras'},
         { value: 'HK', label: 'Hong Kong'},
         { value: 'HU', label: 'Hungary'},
         { value: 'IS', label: 'Iceland'},
         { value: 'IN', label: 'India'},
         { value: 'ID', label: 'Indonesia'},
         { value: 'IR', label: 'Iran'},
         { value: 'IQ', label: 'Iraq'},
         { value: 'IE', label: 'Ireland'},
         { value: 'IM', label: 'Isle Of Man'},
         { value: 'IL', label: 'Israel'},
         { value: 'IT', label: 'Italia'},
         { value: 'JM', label: 'Jamaica'},
         { value: 'JP', label: 'Japan'},
         { value: 'JE', label: 'Jersey'},
         { value: 'JO', label: 'Jordan'},
         { value: 'KZ', label: 'Kazakhstan'},
         { value: 'KE', label: 'Kenya'},
         { value: 'KI', label: 'Kiribati'},
         { value: 'KR', label: 'Korea'},
         { value: 'XK', label: 'Kosovo'},
         { value: 'KW', label: 'Kuwait'},
         { value: 'KG', label: 'Kyrgyzstan'},
         { value: 'LA', label: 'Lao People\'s Democratic Republic'},
         { value: 'LV', label: 'Latvia'},
         { value: 'LB', label: 'Lebanon'},
         { value: 'LS', label: 'Lesotho'},
         { value: 'LR', label: 'Liberia'},
         { value: 'LY', label: 'Libyan Arab Jamahiriya'},
         { value: 'LI', label: 'Liechtenstein'},
         { value: 'LT', label: 'Lithuania'},
         { value: 'LU', label: 'Luxembourg'},
         { value: 'MO', label: 'Macao'},
         { value: 'MK', label: 'Macedonia'},
         { value: 'MG', label: 'Madagascar'},
         { value: 'MW', label: 'Malawi'},
         { value: 'MY', label: 'Malaysia'},
         { value: 'MV', label: 'Maldives'},
         { value: 'ML', label: 'Mali'},
         { value: 'MT', label: 'Malta'},
         { value: 'MH', label: 'Marshall Islands'},
         { value: 'MQ', label: 'Martinique'},
         { value: 'MR', label: 'Mauritania'},
         { value: 'MU', label: 'Mauritius'},
         { value: 'YT', label: 'Mayotte'},
         { value: 'MX', label: 'Mexico'},
         { value: 'FM', label: 'Micronesia}, Federated States Of'},
         { value: 'MD', label: 'Moldova'},
         { value: 'MC', label: 'Monaco'},
         { value: 'MN', label: 'Mongolia'},
         { value: 'ME', label: 'Montenegro'},
         { value: 'MS', label: 'Montserrat'},
         { value: 'MA', label: 'Morocco'},
         { value: 'MZ', label: 'Mozambique'},
         { value: 'MM', label: 'Myanmar'},
         { value: 'NA', label: 'Namibia'},
         { value: 'NR', label: 'Nauru'},
         { value: 'NP', label: 'Nepal'},
         { value: 'NL', label: 'Netherlands'},
         { value: 'AN', label: 'Netherlands Antilles'},
         { value: 'NC', label: 'New Caledonia'},
         { value: 'NZ', label: 'New Zealand'},
         { value: 'NI', label: 'Nicaragua'},
         { value: 'NE', label: 'Niger'},
         { value: 'NG', label: 'Nigeria'},
         { value: 'NU', label: 'Niue'},
         { value: 'NF', label: 'Norfolk Island'},
         { value: 'MP', label: 'Northern Mariana Islands'},
         { value: 'NO', label: 'Norway'},
         { value: 'OM', label: 'Oman'},
         { value: 'PK', label: 'Pakistan'},
         { value: 'PW', label: 'Palau'},
         { value: 'PS', label: 'Palestinian Territory}, Occupied'},
         { value: 'PA', label: 'Panama'},
         { value: 'PG', label: 'Papua New Guinea'},
         { value: 'PY', label: 'Paraguay'},
         { value: 'PE', label: 'Peru'},
         { value: 'PH', label: 'Philippines'},
         { value: 'PN', label: 'Pitcairn'},
         { value: 'PL', label: 'Poland'},
         { value: 'PT', label: 'Portugal'},
         { value: 'PR', label: 'Puerto Rico'},
         { value: 'QA', label: 'Qatar'},
         { value: 'RE', label: 'Reunion'},
         { value: 'RO', label: 'Romania'},
         { value: 'RU', label: 'Russian Federation'},
         { value: 'RW', label: 'Rwanda'},
         { value: 'BL', label: 'Saint Barthelemy'},
         { value: 'SH', label: 'Saint Helena'},
         { value: 'KN', label: 'Saint Kitts And Nevis'},
         { value: 'LC', label: 'Saint Lucia'},
         { value: 'MF', label: 'Saint Martin'},
         { value: 'PM', label: 'Saint Pierre And Miquelon'},
         { value: 'VC', label: 'Saint Vincent And Grenadines'},
         { value: 'WS', label: 'Samoa'},
         { value: 'SM', label: 'San Marino'},
         { value: 'ST', label: 'Sao Tome And Principe'},
         { value: 'SA', label: 'Saudi Arabia'},
         { value: 'SN', label: 'Senegal'},
         { value: 'RS', label: 'Serbia'},
         { value: 'SC', label: 'Seychelles'},
         { value: 'SL', label: 'Sierra Leone'},
         { value: 'SG', label: 'Singapore'},
         { value: 'SK', label: 'Slovakia'},
         { value: 'SI', label: 'Slovenia'},
         { value: 'SB', label: 'Solomon Islands'},
         { value: 'SO', label: 'Somalia'},
         { value: 'ZA', label: 'South Africa'},
         { value: 'GS', label: 'South Georgia And Sandwich Isl.'},
         { value: 'ES', label: 'Spain'},
         { value: 'LK', label: 'Sri Lanka'},
         { value: 'SD', label: 'Sudan'},
         { value: 'SR', label: 'Suriname'},
         { value: 'SJ', label: 'Svalbard And Jan Mayen'},
         { value: 'SZ', label: 'Swaziland'},
         { value: 'SE', label: 'Sweden'},
         { value: 'CH', label: 'Switzerland'},
         { value: 'SY', label: 'Syrian Arab Republic'},
         { value: 'TW', label: 'Taiwan'},
         { value: 'TJ', label: 'Tajikistan'},
         { value: 'TZ', label: 'Tanzania'},
         { value: 'TH', label: 'Thailand'},
         { value: 'TL', label: 'Timor-Leste'},
         { value: 'TG', label: 'Togo'},
         { value: 'TK', label: 'Tokelau'},
         { value: 'TO', label: 'Tonga'},
         { value: 'TT', label: 'Trinidad And Tobago'},
         { value: 'TN', label: 'Tunisia'},
         { value: 'TR', label: 'Turkey'},
         { value: 'TM', label: 'Turkmenistan'},
         { value: 'TC', label: 'Turks And Caicos Islands'},
         { value: 'TV', label: 'Tuvalu'},
         { value: 'UG', label: 'Uganda'},
         { value: 'UA', label: 'Ukraine'},
         { value: 'AE', label: 'United Arab Emirates'},
         { value: 'GB', label: 'United Kingdom'},
         { value: 'US', label: 'United States'},
         { value: 'UM', label: 'United States Outlying Islands'},
         { value: 'UY', label: 'Uruguay'},
         { value: 'UZ', label: 'Uzbekistan'},
         { value: 'VU', label: 'Vanuatu'},
         { value: 'VE', label: 'Venezuela'},
         { value: 'VN', label: 'Viet Nam'},
         { value: 'VG', label: 'Virgin Islands}, British'},
         { value: 'VI', label: 'Virgin Islands}, U.S.'},
         { value: 'WF', label: 'Wallis And Futuna'},
         { value: 'EH', label: 'Western Sahara'},
         { value: 'YE', label: 'Yemen'},
         { value: 'ZM', label: 'Zambia'},
         { value: 'ZW', label: 'Zimbabwe'}
        ],
            veriff:null,
            password:'',
            oldpassword:'',
            confirmPassword:'',
            general:true,
            security:false,
            pswError:'',
            verification:false,
            imgProfile:'',
            orders:false,
            payout:false,
            bank:false,
            paypall:false,
            crypto:false,
            confirmPsw:true,
            Newpassword:true,
            OldPsw:true,
            loading:true,
            changeVisibility:false,
            texterror:'',
            firstName:'',
            lastName:'',
            address:'',
            city:'',
            phone:'',
            zip:'',
            country:'',
            bankName:'',
            bankLastname:'',
            bankAddress:'',
            biography:'',
            username: '',
            bioEdit:false,
            iban:'',
            swift:'',
            sortCode:'',
            routing:'',
            emailpaypal:'',
            wallet:'',
            bankCreation:false,
            cryptoCreation:false,
            paypallCreation:false,
            textInfoError:'',
            textBankError:'',
            textCryptoError:'',
            textPayError:'',
            textsuccess:''
        }
    },
    mounted(){
        const timeout = setTimeout(() => {
       this.loading=false
       }, 2000);
       //this.setClient()
    },
    methods:{
        closeRank(){
            this.showRank=false
        },
        watchRank(value){
            this.showRank=value
        },
        showBannk(){
            this.bank=true
            this.crypto=false
            this.paypall=false
        },
        showCrypto(){
            this.bank=false
            this.crypto=true
            this.paypall=false
        },
        showPaypall(){
            this.bank=false
            this.crypto=false
            this.paypall=true
        },
        uploadFile() {
      const data = new FormData();
      data.append('image', this.$refs.fileInput.files[0]);
      const maxSize = 600000 // Dimensione massima consentita in byte (600 KB)

      if (data.get('image').size > maxSize) {
        alert('La dimensione dell\'immagine non può superare i 600KB!')
        return
      }

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${this.$baseUrl}/customers/profilePicture/${this.client._id}`,
        data: data
      };

      axios.request(config)
        .then((response) => {
            this.imgProfile=response.data.image
        })
        .catch((error) => {
          console.log(error);
        });
    },
        changePSW(){
                axios.post(`${this.$baseUrl}/customers/change/password`,{
                    "email":this.client.email,
                    "Old_password" :this.oldpassword,
                    "New_password1" :this.password,
                    "New_password2" :this.confirmPassword,
                }).then((response) => {
                    if(response.data.status==200){
                        this.textsuccess='Password successfully changed'
                        this.texterror=''
                        this.oldpassword=''
                        this.password=''
                        this.confirmPassword=''
                        this.$cookies.keys().forEach(key =>{
                            if(key!='SetPopUp')
                            this.$cookies.remove(key)
                        });
                        window.location.replace('/')
                    }else{
                        this.textsuccess=''
                        this.texterror=`${response.data.message}, password error `
                    }
                })
            .catch((error) => {
            console.error(error);
            });
            },
        bankPayout(){
            axios.post(`${this.$baseUrl}/customers/setPayoutDetail?customer_id=${this.client._id}`,{
                'type':'Bank',
                'firstName':this.bankName,
                'lastName': this.bankLastname,
                'address': this.bankAddress,
                'iban':this.iban,
                'swift':this.swift,
                'sort':this.sortCode,
                'routing':this.routing,
            }).then((response)=>{
                if(response.status==200){
                    this.textBankError=''
                    let timeout= setTimeout(()=>{
                        this.bankCreation=true
                    },1000)
                    timeout=setTimeout(()=>{
                        this.bankCreation=false
                    },8000)
                }else{
                    this.textBankError='Please Retry'
                }
            })
        },
        criptoPayout(){
            axios.post(`${this.$baseUrl}/customers/setPayoutDetail?customer_id=${this.client._id}`,{
                'type':'Cryptocurrency',
                'wallet':this.wallet
            }).then((response)=>{
                if(response.status==200){
                    this.textCryptoError=''
                    let timeout= setTimeout(()=>{
                        this.cryptoCreation=true
                    },1000)
                    timeout=setTimeout(()=>{
                        this.cryptoCreation=false
                    },8000)
                }else{
                    this.textCryptoError='Please Retry'
                }
            })
        },
        // paypalPayout(){
        //     axios.post(`${this.$baseUrl}/customers/setPayoutDetail?customer_id=${this.client._id}`,{
        //         'type':'Paypal',
        //         'email':this.emailpaypal
        //     }).then((response)=>{
        //         if(response.status==200){
        //             this.textCryptoError=''
        //             let timeout= setTimeout(()=>{
        //                 this.paypallCreation=true
        //             },1000)
        //             timeout=setTimeout(()=>{
        //                 this.paypallCreation=false
        //             },8000)
        //         }else{
        //             this.textPayError='Please Retry'
        //         }
        //     })
        // },
        infoClient(){
            this.loading = true;
            axios.patch(`${this.$baseUrl}/customers/update/details?customer_id=${this.client._id}`,{
                'firstName':this.firstName,
                'lastName': this.lastName,
                'address': this.address,
                'country':this.country,
                'city':this.city,
                'zip':this.zip,
                'phone':this.phone,
                'bio_description':this.biography,
                'username': this.username
            }).then((response)=>{
                this.loading = false;
                if(response.status==200){
                    this.textInfoError=''

                }else{
                    this.textInfoError='Please Retry'
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        setClient(){
            this.client= this.user;
            this.firstName=this.client.firstName
            this.lastName=this.client.lastName
            this.address=this.client.address
            this.zip=this.client.zip
            this.city=this.client.city
            this.imgProfile=this.client.image
            this.country=this.client.country
            this.phone=this.client.phone
            this.biography=this.client.bio_description
            this.username=this.client.username
            axios.get(`${this.$baseUrl}/customers/getPayoutsDetails/${this.client._id}`).then((response)=>{
                response.data.details.forEach(element => {
                    if(element.type=='Bank'){
                        this.iban=element.iban
                        this.sort=element.sort
                        this.swift=element.swift
                        this.routing=element.routing
                        this.bankName=element.firstName
                        this.bankLastname=element.lastName
                        this.bankAddress=element.address
                    }else if(element.type=='Paypal'){
                        this.emailpaypal=element.email
                    }else if(element.type=='Cryptocurrency'){
                        this.wallet=element.wallet
                    }
                });
            })
        },
        showGeneral(){
            this.orders=false;
            this.general=true;
            this.security=false;
            this.verification=false;
            this.payout=false;

        },
        showPayout(){
            this.orders=false;
            this.payout=true;
            this.security=false;
            this.verification=false;
            this.general=false;

        },
        // showOrder(){
        //     this.orders=true;
        //     this.payout=false;
        //     this.security=false;
        //     this.verification=false;
        //     this.general=false;
        // },
        showSecurity(){
            this.security=true;
            this.general=false;
            this.orders=false;
            this.verification=false;
            this.payout=false;
        },
         showVerification(){
            this.orders=false;
            this.security=false;
            this.general=false;
            this.verification=true;
            this.payout=false;
        },
        changeTipeValidate(){
            this.confirmPsw=!this.confirmPsw
        },
        changeTipeNew(){
            this.Newpassword=!this.Newpassword
        },
        changeTipeOld(){
            this.OldPsw=!this.OldPsw
        },
        validatePsw() {
                if (this.password !== this.confirmPassword) {
                    this.pswError = 'Password do not match';
                } else {
                    this.pswError = '';
        }},
        startVeriff() {
        this.veriff = new Veriff({
        apiKey: '3f684eb9-2d9e-4ff2-8aed-993ee59d4b85',
        parentId: 'veriff-root',
        onSession: function(err, response) {
          if (err) {
            console.error(err);
            return;
          }
          // Redirect to the verification session url
          window.open(response.verification.url, '_blank');
        }

      });
      this.veriff.setParams({
            vendorData: this.user._id,
            person: {
                givenName: this.user.firstName,
                lastName: this.user.lastName
            }
        });
        this.veriff.mount();
    },

},
watch: {
        password(){
            this.validatePsw()
        },
        confirmPassword(){
            this.validatePsw()
        },
        user: {
            handler: function (user) {
                if (user) {
                    this.setClient()
                }
            },
            deep: true
        },
    },
    beforeMount(){
        if(!this.$cookies.get('emailAccount')){
            this.$router.push({ path: '/',})
        }
    }
        }
</script>

<style scoped>
select>option{
    background-color: #454d5d;
}
.form-control{
    background-color: transparent!important;
    color: white;
    border: 1px solid #868789;
    padding: 5px;
}

li,a{
    cursor:pointer;
    color: white!important;
}
.on-focus{
    background-color: rgb(207, 97, 70)!important;
    border-radius: 10px;
}
i{
    cursor: pointer;
    font-size: 15px;
}
h3{
    color: rgb(255, 255, 255)!important;
}
.btn-veriff{
    margin-bottom: 20px;
    align-self: end;
    margin-right: 50px;
    padding: 10px ;
}
.text{
    color: #ffffff;
}
.card{
 border-radius: 10px;
 padding-left: 50px;
 padding-right: 50px;
}

.stepper .line {
  width: 2px;
  background-color: lightgrey !important;
}
.stepper .lead {
  font-size: 1.1rem;
}
.cerchio{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#veriff-root{
   margin: 10px auto;
   width: 50%!important;
}

label{
    color:rgb(255, 255, 255)
}
.user-img{
    border-radius: 100%;
    width: 50px;
    height: 50px;
}
textarea{
    background-color: transparent;
    color: white;
}
input{
    color: white;
}
</style>
