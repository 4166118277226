import {defineStore} from "pinia";

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
        fidelityPoints: 0
    }),
    getters: {
        getUser: state => {
            return state.user;
        },
    },
    actions: {
        async me(email) {
            const {data} = await this.$httpClient.get(`/customers/getCustomerInfo?email=${email}`);
            this.user = data;
            return data;
        },
        async update(data) {
            await this.$httpClient.patch(`/customers/update/details?customer_id=${this.user._id}`, data);
            this.me(this.user.email);
        },
        async getFidelityPoints() {
            const response = await this.$httpClient.get(`/ecommerce/getCustomerReward?id=${this.user._id}`);
            const data = response.data || {};
            data.total = parseInt(data.total ?? 0);
            this.fidelityPoints = data;
            return data;
        }
    }
});
