export const formatCurrency = (value) => value.toUpperCase();
export const formatLeverage = (value) => value ? `1:${value}` : '-'
export const formatPercents = (value) => value ? `${value}%` : '-'

export const formatPayoutFrequency = (value) => {  
    switch ( value.toLowerCase()) {
        case 'monthly':
            return 'Monthly'
        case 'biweekly':
            return 'Biweekly'
        case 'on-demand':
            return 'On demand' 
        case 'on demand':
            return 'On demand'  
        default:
            return value;    
    }
}
export const formatPlatform = (value) => {
    switch ( value.toLowerCase()) {
        case 'ctrader':
            return 'cTrader'
        case 'match-trader':
        case 'match-trade':
            return 'Match Trade' 
        default:
            return value;    
    }
}

export const platformImage = (value) => {
    switch ( value.toLowerCase()) {
        case 'ctrader':
            return 'assets/images/checkout/platforms/ctrader.svg'
        case 'match-trade':
        case 'match-trader':
            return 'assets/images/checkout/platforms/match-trader.svg'
        case 'tradelocker':
            return 'assets/images/checkout/platforms/tradelocker.svg'
        default:
            return `assets/images/${value}.png`;    
    }
}
