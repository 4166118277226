import {defineStore} from "pinia";
import axios from "axios";

export const useItemsStore = defineStore('items', {
    state: () => ({
        items: [],
        cachedItem: {},
    }),
    getters: {
        getItemPropByProfitAmount: state => (profitAmount, prop) => {
            return state.items.find(item => item.name.startsWith(profitAmount))?.props[prop] || null;
        },
        getVersion: state => (profitAmount, size, currency_account) => {
            const version = state.items.find(item => item.name.startsWith(profitAmount))?.versions
                .find(version => +version.size === +size && version.currency_account.toLowerCase() === currency_account.toLowerCase());
            if (!version) {
                return null;
            }

            return version;
        },
        getVersionPrice: state => (profitAmount, size, currency_account) => {
            const version = state.getVersion(profitAmount, size, currency_account);
            if (!version) {
                return null;
            }

            const { original_price, price, discount_value, discount_type, currency } = version;
            return { original_price, price, discount_value, discount_type, currency };
        },

        getVersionById: state => id => {
            const size = +id.slice(0, id.indexOf('K')) * 1000;
            const currency_account = id.slice(id.indexOf('K') + 1, id.indexOf('K') + 4);
            const profitAmount = +id.slice(id.indexOf('K') + 4);
            return state.getVersion(profitAmount, size, currency_account);
        },

        queryVersion: state => (id, query = {max_daily_drawdown:null})  => {
            if ( !query || !query.max_daily_drawdown) {
                return getVersionById(id)
            } else {
                const size = +id.slice(0, id.indexOf('K')) * 1000;
                const currency_account = id.slice(id.indexOf('K') + 1, id.indexOf('K') + 4);
                const profitAmount = +id.slice(id.indexOf('K') + 4);
                const item = state.items.find(
                    item => item.name.startsWith(profitAmount) &&
                    item.props && item.props.max_daily_drawdown === query.max_daily_drawdown)
                return item && item.versions ? item.versions.find(
                    version => +version.size === +size &&
                    version.currency_account.toLowerCase() === currency_account.toLowerCase()) : null
              }
        },
        getItemByVersionId: state => (versionId, payout_type, type) => {
            return state.items.find(item => {
                return item.versions.find(version => version.id === versionId)
                    && item.payout_type === payout_type
                    && item.type === type;
            });
        },
    },
    actions: {
        async fetchItems({ payout_type, type }) {
            let url = `/ecommerce/items`;
            if (payout_type) {
                url += `?payout_type=${payout_type}`;
            }
            if (type) {
                url += url.includes('?') ? `&type=${type}` : `?type=${type}`;
            }
            url += '&promo-rules=true';
            const {data} = await this.$httpClient.get(url);
            this.items = data;
            return data;
        },
        async getItemById(itemId, payout_type, type, max_daily_drawdown = 5) {
            const key = `${payout_type}-${type}-${itemId}-${max_daily_drawdown}`;
            if (this.cachedItem[key]) {
                return this.cachedItem[key];
            }

            const { data } = await this.$httpClient.get(`/ecommerce/items/${itemId}?type=${type}&payout_type=${payout_type}&max_daily_drawdown=${max_daily_drawdown}`);
            this.cachedItem[key] = data;
            return data;
        }
    }
})
