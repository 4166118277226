<template>
<body class="skin-default card-no-border">
        <section id="wrapper ">
            <div class="login-box box-position card">
                <div class="card-body" >
                    <div v-if="error" class="card text-center py-4 m-0 ">
                        <h3 class="text-danger fw-bold">{{ textError }}</h3>
                        <p>Contact customer support for information on email verification</p>
                        <p>You can still log in here <a class="text-primary" href="/">Login</a></p>
                    </div>
                    <div v-if="send" class="card  text-center py-4 login-box">
                        <h2 class="text-success fw-bold">EMAIL VERIFIED</h2>
                        <p>Going to <a class="text-primary" href="/">Login</a></p>
                    </div>
                </div>
            </div>
    </section>
        <!-- ============================================================== -->
        <!-- End Wrapper -->
        <!-- ============================================================== -->

    </body>
</template>

<script>

export default {
    data(){
        return{
            send:false,
            error:false,
            textError:''
        }
    },
    methods:{
        verify(){
            axios.get(`${this.$baseUrl}/customers/verify-email/${this.$route.params.token}`).then((response)=>{
                console.log(response)
                if(response.data.status==200){
                    this.send=true
                    this.error=false
                }else{
                    this.send=false
                    this.error=true
                    this.textError=response.data.message
                }
            })
        }
    },
mounted(){
    this.verify()
}
}
</script>

<style scoped>
.box-position{
    position: absolute;
    width: 50%;
     top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}
.login-box{
    border-radius: 10px;
}
@media only screen and (max-width: 600px) {
    .box-position{
        width:100%;
    }
    .box-position>.login-box{
        margin: 0 auto;

    }
}
</style>
